import React from 'react'

const ThumbIcon = () => {
    return (
        <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_7275)">
                <path d="M2.02539 33.2774H8.30313V14.4442H2.02539V33.2774ZM36.553 16.0136C36.553 14.2872 35.1405 12.8747 33.4141 12.8747H23.511L25.0019 5.7024L25.049 5.20018C25.049 4.55671 24.7822 3.96033 24.3585 3.53658L22.6949 1.88867L12.368 12.2313C11.7873 12.7962 11.442 13.581 11.442 14.4442V30.1385C11.442 31.8649 12.8545 33.2774 14.5809 33.2774H28.7058C30.0084 33.2774 31.1227 32.4927 31.5936 31.3627L36.3332 20.2981C36.4745 19.9372 36.553 19.5605 36.553 19.1525V16.0136Z" 
                fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_1_7275">
                    <rect width="37.6665" height="37.6665" fill="white" transform="translate(0.455078 0.318359)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default ThumbIcon