export const websiteData = [
    {
        date: 'Feb 28, 2024',
        slug: `merida-hr`,
        website: `Merida Hr`,
        heading: `Simplifying HR Management`,
        clr: 'meridahrbg',
        theme: 'theme3',
        tags: ['HRMS', 'Payroll'],
        others: [
            {
                website: "Merida Tech Minds",
                slug: '/merida-tech-minds',
                img: "../assesst/Websites/meridatechmindsfootercard.png"
            },
            {
                website: "Lernen Hub",
                slug: '/learn-hub',
                img: "../assesst/Websites/lernenhubcard.png"
            },
        ],
        content: `Merida HR's website design makes it easy for HR teams to manage tasks, track progress, and support employees, 
        ensuring smooth workflows and better overall productivity. `,
        img1: '../assesst/Websites/meridahrhome-min.png',
        img2: '../assesst/Websites/MeridahrWeb1-min.png',
        img3: '../assesst/Websites/Meridahrweb2-min.png',
        img4: '../assesst/Websites/Meridahrweb3-min.png',
        img5: '../assesst/Websites/meridahrweb4-min.png',
        img6: '../assesst/Websites/meridahrweb5-min.png',
        content2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?`

    },
    {
        date: 'Aug 10, 2024',
        slug: `learn-hub`,
        website: `Lernen Hub`,
        heading: `A Better Way to Learn`,
        clr: 'learnhubbg',
        theme: 'theme3',
        tags: ['Course', 'Student'],
        others: [
            {
                website: "Merida Hr ",
                slug: '/merida-tech-minds',
                img: "../assesst/Websites/meridahrfootercard.png"
            },
            {
                website: "Pithe-ta",
                slug: '/pi-theta',
                img: "../assesst/Websites/pithetafootercard.png"
            },
        ],
        content: `Lernen Hub's website design ensures learners can easily access courses, track progress, and learn at their own pace, making education simple, flexible, and accessible to 
        all.`,
        img1: '../assesst/websiteScroller1.webp',
        img2: '../assesst/Websites/lernenhubWeb1.png',
        img3: '../assesst/Websites/lernenhubweb3.webp',
        img4: '../assesst/Websites/lernenhubweb4.webp',
        content2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?`

    },
    {
        date: 'Jan 26, 2024',
        slug: `merida-tech-minds`,
        website: `Tech Minds`,
        heading: `Solutions at Your Fingertips`,
        clr: 'techmindsbg',
        theme: 'theme3',
        others: [
            {
                website: "Merida Hr ",
                slug: '/merida-tech-minds',
                img: "../assesst/Websites/meridahrfootercard.png"
            },
            {
                website: "Pithe-ta",
                slug: '/pi-theta',
                img: "../assesst/Websites/pithetafootercard.png"
            },
        ],
        tags: ['Software', 'Design'],
        content: `Merida Tech Minds now has a website that showcases all our services and makes it easy for clients to connect with us in just a few simple 
        clicks.`,
        img1: '../assesst/Websites/meridatechWebhome.png',
        img2: '../assesst/Websites/meridatechweb1.png',
        img3: '../assesst/Websites/meridatechweb2.webp',
        img4: '../assesst/Websites/meridatechweb3.webp',
        img5: '../assesst/Websites/meridatechweb4.webp',
        img6: '../assesst/Merida_Logo_page_Billboard.webp',
        content2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?`
    },

    {
        date: 'May 6, 2024',
        slug: `pi-theta`,
        website: `Pi-theta prep`,
        heading: `Prep The Right Way`,
        clr: 'pithetabg',
        theme: 'theme2',
        tags: ['Course', 'Math'],
        others: [
            {
                website: "Bloom Indoor",
                slug: '/bloomindoor',
                img: "../assesst/Websites/bloomindoorfootercard.png"
            },
            {
                website: "Lernen Hub",
                slug: '/learn-hub',
                img: "../assesst/Websites/lernenhubcard.png"
            },
        ],
        content: `Pi-Theta Prep’s website is designed to help students quickly access lessons and tutors easily manage their sessions, providing a personalized experience for both learners and 
        educators.`,
        img1: '../assesst/Websites/pithetawebhome.png',
        img2: '../assesst/Websites/pithetaweb1.webp',
        img3: '../assesst/Websites/pithetaweb2.webp',
        img4: '../assesst/Websites/pitheta3.webp',
        img5: '../assesst/Websites/pithetaweb4.png',
        img6: '../assesst/Websites/pithetaweb5.webp',
        img7: '../assesst/Websites/pithetaweb6-min.png',

        content2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?`
    },
    {
        date: 'Sep 16, 2024',
        slug: `bloomindoor`,
        website: `Bloom Indoor`,
        heading: `Serenity In Every Space`,
        clr: 'bloomindoorbg',
        theme: 'theme1',
        tags: ['Plant', 'Herbs'],
        others: [
            {
                website: "Merida Tech Minds",
                slug: '/merida-tech-minds',
                img: "../assesst/Websites/meridatechmindsfootercard.png"
            },
            {
                website: "Pithe-ta",
                slug: '/pi-theta',
                img: "../assesst/Websites/pithetafootercard.png"
            },
        ],
        content: `The website is designed to reflect BloomIndoor’s mission to bring nature indoors, offering a seamless experience for plant lovers to easily explore, shop, and care for their 
        plants.`,
        img1: '../assesst/websiteScroller4.webp',
        img2: '../assesst/Websites/bloomindoor1.webp',
        img3: '../assesst/Websites/bloomindoor2.png',
        img4: '../assesst/Websites/bloomindoor3.png',
        img5: '../assesst/Websites/bloomindoor4.png',
        img6: '../assesst/Websites/bloomindoor5.png',
        content2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?`

    },

]
export const logoData = [
    {
        slug: 'meridaLogo',
        title: 'Merida',
        mainBanner: '../assesst/Merida_White2.png',
        bannerbg: 'bg-slate-950',
        alignImg: `../assesst/meridaLogoAlign.png`,
        belowAlign: '../assesst/Logo_Blue2.webp',
        card1css: 'bg-slate-100',
        card2css: '-order-1 order-sm-0 ',

        tags: [
            'Agency',
            'Development'
        ],
        content: `Crafting a unique logo for Merida Tech Minds involved capturing their agency and development services. Through careful selection of colors and fonts, the logo was designed to perfectly match their brand 
        identity.`,
        logo_variation: `The logo for Merida Tech Minds was resized for multiple platforms, ensuring it maintains clarity and impact. We optimized the design to work seamlessly across web, print, and social media, ensuring high visibility on 
        any screen.`,
        heading: 'Bold Design',
        logoBanner: '../assesst/logobannermerida-min.png',

        logoIcons: [
            {
                img: '../assesst/Merida_logoPage_Icon4.webp',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/Merida_logoPage_Icon4.webp',
                css: ' bg-white m-auto h-[8rem] w-[8rem] flex p-3 rounded-full'
            },
            {
                img: '../assesst/Merida_LogoPageIcon2.webp',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/Logo_Blue2.webp',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/Merida_White2.png',
                css: 'meridabannerbg  m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl '
            },
        ],
        clrptrn: [
            {
                clr: 'rgb(19, 19,60)',
                hsb: '240,68,24',
                hex: '13133C',
                rgb: '19, 19,60'
            },
            {
                clr: 'rgb(180 39 72)',
                hsb: '220,168,24',
                hex: 'B42748',
                rgb: '180 39 72'
            },
            {
                clr: 'rgb(103 167 83)',
                hsb: '140,60,64',
                hex: '67A753',
                rgb: '103 167 83'
            },
            {
                clr: 'rgb(215 180 67)',
                hsb: '100,38,24',
                hex: 'D7B443',
                rgb: '215 180 67'
            },

        ],
        fontstyle: 'poppins',
        alignimg2: '../assesst/meridaLogoPlans.png',
        blogCard: [
            '../assesst/meridaLogoCard1.webp',
            '../assesst/imagecompressor5/meridalogoblogCard.png',
            '../assesst/meridaLogoCard1.webp'
        ],
        clipimg: [
            {
                img: '../assesst/Merida_Logo_page_Billboard.webp',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/MeridaLogopageDisccusionRoom.webp',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/MeridaLogoWhiteBoard.webp',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/centerManagerMeridaLogo.webp',
                css: ' col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/CEOCardMeridaLogo.webp',
                css: 'col-md-6 p-0 m-0 w-full mx-0 px-0'
            },

            {
                img: '../assesst/MeridaLoggoPageBook.webp',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/meridaLogoPageFooter.webp',
                css: ' rounded w-full mx-0 px-0'
            },
        ]
    },
    {
        slug: 'aditiskills',
        title: 'Aditi Skills',
        mainBanner: '../assesst/imagecompressor5/adithiskillsLogoBanner-min.png',
        bannerbg: 'aditiskillsbannerbg',
        alignImg: '../assesst/imagecompressor5/adithiSkilsAlign-min.png',
        belowAlign: '../assesst/imagecompressor5/adithiskillsLogo-min.png',

        card1css: 'order-2 sm:bg-blue-600 bg-slate-100 sm:text-slate-50 ',
        card2css: ' -order-1 ',
        tags: [
            'Saree Couture',
            'Textile'
        ],
        content: `Aditi Silk’s logo was designed to reflect the rich tradition of silk sarees. From choosing the colors to the font, every detail was carefully considered to represent the brand’s heritage and commitment to 
        quality.`,
        logo_variation: `Aditi Silk’s logo was resized to maintain its quality and elegance, no matter where it's displayed. The logo’s resized version ensures it looks perfect on all the marketing materials from business cards to large billboards, keeping its charm 
        intact.`,
        heading: 'Timeless Design',
        logoBanner: '../assesst/adithisilklogobanner-min.png',


        logoIcons: [
            {
                img: '../assesst/imagecompressor5/adithiskillsLogo-min.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor5/adithiskillsLogo-min.png',
                css: ' bg-white m-auto h-[8rem] w-[8rem] flex p-3 rounded-full'
            },
            {
                img: '../assesst/imagecompressor5/adithiskillsDarkLogo-min.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor5/adithiSkillsCrown-min.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor5/adithiskillswhiteCrown-min.png',
                css: 'meridabannerbg  m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl '
            },
        ],
        clrptrn: [
            {
                clr: 'rgb(198, 143, 53)',
                hsb: '38,73,78',
                hex: 'C69135',
                rgb: '198, 143, 53'
            }
        ],
        fontstyle: 'poppins',
        alignimg2: '../assesst/aaditilogoalignment.png',
        blogCard: [
            '../assesst/imagecompressor5/adithiskillscard1-min.png',
            '../assesst/imagecompressor5/adithiskillscard2-min.png',
            '../assesst/imagecompressor5/adithiskillscard3-min.png'
        ],
        clipimg: [
            {
                img: '../assesst/imagecompressor5/adithiskillsSareeBox-min.png',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor4/adithiSkilllsLetterPad-min.png',
                css: ' col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor5/AdithiSkillsLetterPad2-min.png',
                css: 'col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor5/AdithiskillSareeBox2-min.png',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor5/AdithiSkillsFooter-min.png',
                css: ' rounded w-full mx-0 px-0'
            },
        ]
    },
    {
        slug: 'lernenhub',
        title: 'Lernen Hub',
        mainBanner: '../assesst/imagecompressor5/learnhublogo.png',
        bannerbg: 'learnhubbannerbg',
        alignImg: '../assesst/imagecompressor6/alignlearnhub.png',
        belowAlign: '../assesst/imagecompressor6/learnghublogoblack.png',

        card1css: 'bg-slate-100',
        card2css: '-order-1 order-sm-0 ',

        tags: [
            'E-Learning',
            'Education'
        ],
        content: `For Lernen Hub, we crafted a logo that reflects its mission to provide accessible, self-paced learning. The color choices reflect trust and growth, while the font style is modern and easy to read. Thought went into every aspect, ensuring the logo resonates with those eager to learn and
         grow.`,
        logo_variation: `Lernen Hub’s logo was resized to work on various devices. This ensures that no matter the platform, the design remains sharp and clear, making it easy for learners to connect with the brand across all 
        channels.`,
        heading: 'Purposeful Design',
        logoBanner: '../assesst/lernenhublogobanne-min.png',

        logoIcons: [
            {
                img: '../assesst/imagecompressor6/learnhubcirclelogo.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor6/learnhubcirclelogo.png',
                css: ' bg-white m-auto h-[8rem] w-[8rem] flex p-3 rounded-full'
            },
            {
                img: '../assesst/imagecompressor6/learnhubcirclelogo.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor6/learnhubcirclelogo.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor6/learnhubcirclelogo.png',
                css: 'meridabannerbg  m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl '
            },
        ],
        clrptrn: [
            {
                clr: 'rgb(244,205,0)',
                hsb: '50,100,96',
                hex: 'F4CD00',
                rgb: '244,205,0'
            },
            {
                clr: 'rgb(225, 132, 93)',
                hsb: '14,64,100',
                hex: 'FF845D',
                rgb: '225, 132, 93'
            },
            {
                clr: 'rgb(0, 204, 190)',
                hsb: '176,100,80',
                hex: '00CCBE',
                rgb: '0, 204, 190'
            }
        ],
        fontstyle: 'poppins',
        alignimg2: '../assesst/imagecompressor6/learnhubalign.png',
        blogCard: [
            '../assesst/imagecompressor4/learnhubcard1.png',
            '../assesst/imagecompressor4/learnhubcard3.png',
            '../assesst/imagecompressor4/learnhubcard2.png'
        ],
        clipimg: [
            {
                img: '../assesst/imagecompressor4/learnhubbanner2.png',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor5/learnhubcard4.png',
                css: ' col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor5/learnhubcard5.png',
                css: ' col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor6/learnhubfooter.png',
                css: ' rounded w-full mx-0 px-0'
            },
        ]
    },
    {
        slug: 'swathisweets',
        navbar: 'dark',
        title: 'Swathi Sweets',
        mainBanner: '../assesst/imagecompressor6/swathisweetslogo.png',
        bannerbg: 'swathisweetsbg',
        alignImg: '../assesst/imagecompressor6/swathisweetsalign.png',
        belowAlign: '../assesst/imagecompressor6/swathisweetslogo.png',
        card1css: 'order-2 sm:bg-blue-600 bg-slate-100 sm:text-slate-50 ',
        card2css: ' -order-1 ',

        tags: [
            'Business',
            'Food & Beverage'
        ],
        content: `A thoughtful logo was crafted to capture the warmth and tradition of Swathi Sweets, reflecting the homemade goodness of their products. Every color and shape was carefully chosen to appeal to their customers, making the brand feel personal and 
        inviting.`,
        logo_variation: `Swathi Sweets' logo was adjusted to ensure its beauty shines through on all types of platforms, from packaging to social media. Resizing the logo helps it remain visually appealing and clear wherever it is
         displayed.`,
        heading: 'Thoughtful Design',
        logoBanner: '../assesst/swathisweetslogobanner-min.png',


        logoIcons: [
            {
                img: '../assesst/imagecompressor4/swathisweetsheart.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor4/swathisweetsheart.png',
                css: ' bg-white m-auto h-[8rem] w-[8rem] flex p-3 rounded-full'
            },
            {
                img: '../assesst/imagecompressor4/swathisweetsheart.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor4/swathisweetsheart.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor4/swathisweetsheart.png',
                css: 'meridabannerbg  m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl '
            },
        ],
        clrptrn: [
            {
                clr: 'rgb(229,56,75)',
                hsb: '353, 76, 90',
                hex: 'E5384B',
                rgb: '229,56,75'
            },
            {
                clr: 'rgb(81,13, 11)',
                hsb: '2, 86 32',
                hex: '510D0B',
                rgb: '81,13, 11'
            },
            {
                clr: 'rgb(240, 198, 202)',
                hsb: '354, 17, 94',
                hex: 'F0C6CA',
                rgb: '240, 198, 202'
            },
            {
                clr: 'rgb(233, 126, 56)',
                hsb: '24,76,91',
                hex: 'E97E38',
                rgb: '233, 126, 56'
            }
        ],
        fontstyle: 'poppins',
        alignimg2: '../assesst/swathisweetsalign2.png',
        blogCard: [
            '../assesst/imagecompressor4/swathisweetcard1.png',
            '../assesst/imagecompressor4/swathisweetcard3.png',
            '../assesst/imagecompressor4/swathisweetcard2.png'
        ],
        clipimg: [
            {
                img: '../assesst/imagecompressor4/swathisweetbanner2.png',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor6/swathisweetscol1.png',
                css: ' col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor6/swathisweetscol2.png',
                css: ' col-md-6 p-0 m-0 w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor4/swathisweetfooter.png',
                css: ' rounded w-full mx-0 px-0'
            },
        ]
    },
    {
        slug: 'bts360',
        navbar: 'dark',
        title: 'BTS 360',
        mainBanner: '../assesst/imagecompressor6/btslogonormal.png',
        bannerbg: 'btsbannerbg',
        alignImg: '../assesst/imagecompressor6/btsalign.png',
        belowAlign: '../assesst/imagecompressor6/btslogonormal.png',
        card1css: 'bg-slate-100',
        card2css: '-order-1 order-sm-0 ',

        tags: [
            'Consulting',
            'Business Support'
        ],
        content: `Crafting a logo for BTS360 required a deep understanding of their role in offering custom business solutions to small businesses. Each color, shape, and font choice was thoughtfully made to reflect their commitment to business growth and 
        innovation.`,
        logo_variation: `The BTS360 logo was resized for use across different platforms, ensuring its elements look clean and professional on anything from websites to flyers. This makes sure the logo works perfectly across a wide range of 
        media.`,
        heading: 'Creative Design',
        logoBanner: '../assesst/bts360logobanner-min.png',

        logoIcons: [
            {
                img: '../assesst/imagecompressor6/btslogonormal.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor6/btslogonormal.png',
                css: ' bg-white m-auto h-[8rem] w-[8rem] flex p-3 rounded-full'
            },
            {
                img: '../assesst/imagecompressor6/btsdarklogo.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor6/btsbluelogo.png',
                css: 'bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/imagecompressor6/btswhitelogo.png',
                css: 'meridabannerbg  m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl '
            },
        ],
        clrptrn: [
            {
                clr: 'rgb(46,118,128)',
                hsb: '208,75,71',
                hex: '2E76B6',
                rgb: '46,118,128'
            },
            {
                clr: 'rgb(91, 91, 91)',
                hsb: '0, 0, 75',
                hex: 'BFBFBF',
                rgb: '91, 91, 91'
            },

        ],
        fontstyle: 'poppins',
        alignimg2: '../assesst/imagecompressor6/btsalign2.png',
        blogCard: [
            '../assesst/imagecompressor4/btscard1.png',
            '../assesst/imagecompressor4/btscard2.png',
            '../assesst/imagecompressor5/btscard3.png'
        ],
        clipimg: [
            {
                img: '../assesst/imagecompressor4/bts360banner2.png',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor5/btsbanner3.png',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/imagecompressor6/btsfooter.png',
                css: ' rounded w-full mx-0 px-0'
            },
        ]
    },
    // Gloden Bridge
    {
        slug: 'goldenbridge',
        navbar: '',
        title: 'Golden Bridge',
        mainBanner: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
        bannerbg: ' bg-[#060C2A] ',
        alignImg: '../assesst/goldenBridgeLogo/GoldenBridgeLayout.png',
        belowAlign: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
        card1css: ' order-2 bg-[#060C2A] text-slate-200  ',
        card2css: '-order-1 ',

        tags: [
            'Real Estate',
            'Infrastructure'
        ],
        content: `The logo for Golden Bridge was designed to embody the brand’s commitment to bridging dreams with reality in the real estate sector. The golden hues symbolize trust, luxury, and excellence, while the geometric structure reflects stability and growth. 
        Every detail was chosen to resonate with the company’s ethos of creating lasting value in properties.`,
        logo_variation: `The Golden Bridge logo was resized for use across different platforms, ensuring its elements look clean and professional on anything from websites to flyers. This makes sure the logo works perfectly across a wide range of 
        media.`,
        heading: 'Visionary Design',
        logoBanner: '../assesst/goldenBridgeLogo/goldenBridgeCardImg.jpg',
        logoIcons: [
            {
                img: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
                css: 'bg-[#060C2A] m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
                css: ' bg-[#060C2A] m-auto h-[8rem] w-[8rem] flex p-3 rounded-full'
            },
            {
                img: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
                css: 'bg-[#060C2A] m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
                css: 'bg-[#060C2A] m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl'
            },
            {
                img: '../assesst/goldenBridgeLogo/goldenBridgeLogo.png',
                css: 'meridabannerbg  m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl '
            },
        ],
        clrptrn: [
            {
                clr: 'rgb(242,219,164)',
                hsb: '42,32,95',
                hex: 'F2DBA4',
                rgb: '242,219,164'
            },

        ],
        fontstyle: 'poppins',
        alignimg2: '../assesst/goldenBridgeLogo/goldenBridgeAlign2.png',
        blogCard: [
            '../assesst/goldenBridgeLogo/GoldenBridge2.jpg',
            '../assesst/goldenBridgeLogo/GoldenBridge3.jpg',
            '../assesst/goldenBridgeLogo/GoldenBridge4.jpg'
        ],
        clipimg: [
            {
                img: '../assesst/goldenBridgeLogo/GoldenBridge2.jpg',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/goldenBridgeLogo/goldenclip.jpg',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/goldenBridgeLogo/GoldenBridge3.jpg',
                css: ' rounded w-full mx-0 px-0'
            },
            {
                img: '../assesst/goldenBridgeLogo/GoldenBridge4.jpg',
                css: ' rounded w-full mx-0 px-0'
            },
        ]
    },
]

export const webappData = [
    {
        cardimg: "../assesst/WEBapp/gebawebappcard.png",
        cardbg: 'gebagrdbg',
        cardcontent: `An order traceability system that makes tracking orders easy and clear.  With real-time updates, users and admins can easily follow the progress of their orders. The system allows for quick changes, detailed monitoring, and smooth operations. It’s designed to reduce delays, offer clear insights, and improve decision-making. Our system ensures everything is running smoothly,
         keeping you informed at every step.`,
        appName: 'Order Traceability',
        slug: 'order-traceability',
        theme: 'theme1',
        imgcss: 'h-full',

        bannerHeading: ' Keeping Track of Every Order',
        bannercontent: 'Order tracking made simple with clear, transparent, and efficient order tracking system',
        bannerimg: '../assesst/WEBapp/gebabannerwebapp.png',
        bannerbg: 'gebabannerbg',
        whyData: {
            heading: 'Exceptional Control Over Your Orders',
            content: 'Our printing system is built to fit your needs with easy-to-use features for everyone',
            leftContent: [
                {
                    heading: 'End-to-End Order Monitoring',
                    content: 'Track every stage with ease'
                },
                {
                    heading: 'Real-Time Updates for All',
                    content: 'Stay informed with instant status changes'
                },
                {
                    heading: 'Admin-Level Control and Flexibility',
                    content: 'Admins can adjust orders as needed'
                },
            ],
        },
        benifitObj: {
            benifitHeading: `Better tracking & order management every time`,
            benifitContent: `Track orders with precision, receive timely updates, and enjoy full visibility of your order’s progress throughout its lifecycle.`,
            benifitpoints: [
                'Full visibility on all orders',
                'Instant tracking and status updates',
                'Reduce operational delays',
                'Easy reporting and auditing'
            ],


            benifittitle: 'Benefits of Our Order Traceability System',
            img: '../assesst/WEBapp/gebacableswebappimg1-min.png'
        },

        howitworks: {
            benifitHeading: `Track, Monitor, and Manage Orders in Just a Few Steps`,
            benifitContent: `From order creation to delivery, every step is documented, ensuring no details are missed. Our system ensures smooth transitions, accurate updates, and transparent communication, making it easy for both users and 
            admins.`,
            hcss: 'text-slate-200',
            pcss: 'text-slate-300',
            scss: 'text-slate-300',
            benifitpoints: [
                'Create orders with item selection',
                `Real-time status updates at each stage`,
                `Admins resolve issues`,
                `Transparent tracking for customers at every point`
            ],
            benifittitle: 'How It Works',
            show: true
        },
        features: {
            heading: 'Powerful Tools for Order Traceability and Control',
            content: 'Our system offers advanced tracking features designed to keep you informed at every stage',
            card3: {
                heading: 'Customizable Tracking Dashboard',
                content: 'Fully adjustable to your specific business tracking needs',
                img: '../assesst/WEBapp/gebafeature1-min.png',
                bg: 'shadowbg2'
            },
            footer: {
                heading: 'Enhance Your Order Tracking Experience Merida’s Traceability Solutions',
                content: 'Get in touch with us for more details!',
                img: '../assesst/WEBapp/gebawebappfooter.webp',
                bg: 'bg-black'
            }
        }
    },
    {
        cardimg: "../assesst/WEBapp/onlinetestwebappcard-min.png",
        cardbg: 'onlinetestgrdbg',
        appName: 'Online Assessment App',
        slug: 'online-assessment-app',
        theme: 'theme2',
        cardcontent: `Our online testing platform offers an easy and secure way to assess candidates of all skill levels. With fast setup, real-time results, and various test formats like coding, MCQs, and practical exams, it's designed to meet different needs. Admins can manage, track, and analyze performance effortlessly, while candidates get instant feedback. Whether you're creating tests or reviewing results, the platform makes it simple and quick. 
        This platform is designed to make online testing smooth and efficient for both admins and candidates.`,
        bheadcss: ' sm:w-[64%] ',
        bannerHeading: 'Modern Online Testing Platform for Effective Candidate Assessments',
        bannercontent: `Fast, accessible, and secure online assessments for all skill levels`,
        bannerimg: '../assesst/WEBapp/onlinetestwebappcard-min.png',
        bannerbg: 'onlinetestwebappbg',
        whyData: {
            heading: 'Reliable Online Testing for All Skill Levels',
            content: 'Our system adapts to various exam formats, offering real-time results and secure management tools',
            leftContent: [
                {
                    heading: 'Quick Test Setup',
                    content: 'Set up exams without hassle in just a few steps'
                },
                {
                    heading: 'Varied Assessment Options ',
                    content: 'Multiple formats for coding, MCQs, and practical tests'
                },
                {
                    heading: 'Flexible Admin Controls',
                    content: 'Easily manage, track, and review all test data'
                },
            ],
            card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            benifitHeading: `Tests that Work for Both Admins and Candidates`,
            benifitContent: `Our system improves test-taking and management, providing clear results for candidates and easy performance tracking for 
            admins`,
            benifitpoints: [
                'Fast, accurate results',
                'Easy to configure tests',
                'Secure testing environment',
                'Multiple test formats available'
            ],
            benifittitle: 'Online Testing Benefits',
            img: '../assesst/WEBapp/onlinetestwebappcontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Start Testing in No Time with Our System`,
        //     benifitContent: `Our process ensures seamless exams for candidates and quick, efficient management for admins with instant
        //      feedback`,
        //     benifitpoints: [
        //         'Register and log in',
        //         `Configure your exam`,
        //         `Candidates begin test`,
        //         `Admins review results`
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            heading: 'Testing Made Easy with Flexible Online Examination Tools',
            content: 'A platform that balances usability with robust features for varied test requirements',
            card3: {
                heading: 'Automated Grading',
                content: 'Get instant scores for all test types, allowing for quick, efficient evaluations and better decision-making.',
                img: '../assesst/WEBapp/onlinetestfeature3.png',
                bg: 'shadowbg2',
                imgcss: '',

            },
            card2: {
                heading: 'User-Friendly Dashboard',
                content: 'Simplified navigation for both candidates and admins.',
                img: '../assesst/WEBapp/onlinetestwebappfeature2.webp',
                bg: 'shadowbg2'
            },
            card1: {
                heading: 'Performance Insights',
                content: 'Easily track and analyze candidate performance to gain insights and monitor progress over time.',
                img: '../assesst/WEBapp/onlinetestwebapppfeature1.webp',
                bg: 'shadowbg2'
            },
            footer: {
                heading: 'Create, manage, and grade tests with a click!',
                content: 'Get in touch with us for more details!',
                img: '../assesst/WEBapp/gebawebappfooter.webp',
                bg: 'bg-black'
            }
        }
    },
    {
        cardimg: "../assesst/WEBapp/hrmwebappcard-min.png",
        cardbg: 'hrmgrdbg',
        appName: 'HRM Software',
        slug: 'geba-cables',
        theme: 'theme1',
        navbar: 'dark',
        cardcontent: `Merida’s HRMS system is a smart solution for managing your workforce. It helps you handle recruitment, payroll, attendance, and more with ease. No more manual work or mistakes. Everything is automated, saving you time. Whether you're a small team or a large company, our HRMS adapts to your needs and keeps things running smoothly. 
        It’s easy to use, secure, and helps your business stay on track with minimal effort.`,
        bheadcss: ' sm:w-[64%] text-slate-800 ',
        bannerHeading: 'Manage Your Workforce with Our Intuitive HRMS System',
        bannercontent: `Wave goodbye to manual tracking and errors with our HRMS saving you time and keeping everything on track`,
        bannerimg: '../assesst/WEBapp/hrmwebappbanner.png',
        bannerbg: 'bg-white ',
        whyData: {
            heading: 'A Flexible HR Solution for Every Need ',
            content: `Merida’s HRMS adapts to your business’s unique requirements, ensuring smooth and effective workforce management`,
            leftContent: [
                {
                    heading: 'Simplify Recruitment with Smart Tracking and Reporting',
                    content: 'Easily organize and manage job postings and interviews'
                },
                {
                    heading: 'Automate Payroll for Timely and Accurate Payments ',
                    content: 'Calculate salaries, deductions, and expenses with zero errors '
                },
                {
                    heading: 'Efficiently Manage Attendance and Work Hours',
                    content: 'Track daily attendance, shifts, and leave requests'
                },
            ],
            // card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            benifitHeading: `Customized HR Solutions for Teams of All Sizes`,
            benifitContent: `Our HRMS helps you save time and resources, handling all essential HR tasks seamlessly and securely`,
            benifitpoints: [
                'Quick payroll processing',
                'Accurate attendance tracking',
                'Improved reporting and analytics',
                'Customizable features to fit your organization'
            ],
            benifittitle: 'Benefits of Our HRM',
            img: '../assesst/WEBapp/hrmwebappcontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Start Testing in No Time with Our System`,
        //     benifitContent: `Our process ensures seamless exams for candidates and quick, efficient management for admins with instant
        //      feedback`,
        //     benifitpoints: [
        //         'Register and log in',
        //         `Configure your exam`,
        //         `Candidates begin test`,
        //         `Admins review results`
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            heading: 'Customizable, Accessible, & Reliable Tools for Effective Workforce Management',
            content: 'From reducing paperwork to enhancing productivity, our HRMS is built to solve HR challenges and give your team the best tools',
            card3: {
                heading: 'Automated Payroll Management',
                content: 'Ensure accurate payroll processing with automatic tax calculations and timely salary disbursement',
                img: '../assesst/WEBapp/hrmfeature3.png',
                bg: 'bg-white',
                swap: true,
                hcss: 'text-slate-800',
                pcss: 'text-slate-500 ',
                imgcss: '',

            },
            card2: {
                heading: 'Employee Self-Service Portal',
                content: 'Employees can access personal data, manage leaves, and view payroll without administrative assistance.',
                img: '../assesst/WEBapp/hrmfeature2.png',
                bg: 'bg-slate-950 bg-opacity-90'
            },
            card1: {
                heading: 'Recruitment Management',
                content: 'Streamline your hiring process, from job posts to candidate selection',
                img: '../assesst/WEBapp/hrmwebappFeature1.png',
                bg: 'bg-slate-950 bg-opacity-90 ',
                imgcss: ' w-[80%] sm:w-[50%] mx-auto '
            },
            footer: {
                heading: 'Create, manage, and grade tests with a click!',
                content: 'Get in touch with us for more details!',
                img: '../assesst/WEBapp/hrmwebfooter.png',
                bg: 'bg-slate-950 rounded-3xl bg-opacity-90 ',
                imgcss: ' w-[90%] mx-auto my-auto '
            }
        }
    },
    {
        cardimg: "../assesst/WEBapp/daswebappcard-min.png",
        cardbg: 'hrmgrdbg',
        appName: 'Task Manager',
        slug: 'task-manager',
        theme: 'theme2',
        navbar: 'dark',
        cardcontent: `DAS is an effective tool to help you stay organized and productive. It helps you plan, track, and complete tasks every day, making it easy to focus on what matters most. With a clear and user-friendly design, you can quickly see your tasks, progress, and team updates. Whether you’re working alone or with a team, our DAS keeps everything in check, helping you stay on track and achieve your goals every 
        day.`,
        bheadcss: ' sm:w-[97%] text-slate-800 ',
        bannertheme: 'theme2',
        bannerHeading: 'Stay Organized Every Day with Our DAS Solutions',
        bannercontent: `A simple way to track your tasks, goals, and progress`,
        bannerimg: '../assesst/WEBapp/daswebappcard-min.png',
        bannerbg: 'bg-white ',
        whyData: {
            heading: 'Reliable & Practical Daily Tracking Solutions ',
            content: `Stay focused on what matters with a daily sheet designed for task tracking, organization, and growth with a simple, user-friendly 
            interface.`,
            leftContent: [
                {
                    heading: 'Quick View of Daily and Weekly Tasks',
                    content: 'Simplifies your goals and to-dos in one glance'
                },
                {
                    heading: 'Plan, Track, and Reflect Every Day ',
                    content: 'Organize daily goals and review progress easily '
                },
                {
                    heading: 'Team Collaboration and Task Sharing',
                    content: 'Keep everyone informed and stay on the same page'
                },
            ],
            // card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            benifitHeading: `Clear & Simple Tracking for Productive Daily Workflow`,
            benifitContent: `Our DAS helps you focus, avoid distractions, and work toward goals without missing important tasks, offering a straightforward way to track progress and meet 
            daily needs`,
            benifitpoints: [
                'Focused daily planning',
                'Supports team alignment',
                'Visible, measurable results',
                'Organized work approach'
            ],
            benifittitle: 'Benefits of Our DAS',
            img: '../assesst/WEBapp/daswebappcontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Start Testing in No Time with Our System`,
        //     benifitContent: `Our process ensures seamless exams for candidates and quick, efficient management for admins with instant
        //      feedback`,
        //     benifitpoints: [
        //         'Register and log in',
        //         `Configure your exam`,
        //         `Candidates begin test`,
        //         `Admins review results`
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            heading: 'Practical Tools for Tracking and Managing Daily Activities',
            content: 'Built for ease of use, helping organize daily tasks efficiently and clearly',
            card3: {
                heading: 'Daily Task Manager',
                content: 'See tasks lined up for a more organized day',
                img: '../assesst/WEBapp/daswebappfeature2.png',
                bg: 'shadowbg2',
                imgcss: '',

            },
            card2: {
                heading: 'Clear Progress Indicators',
                content: `Track how far you've come on each task with easy-to-read indicators for faster, simpler planning and completion`,
                img: '../assesst/WEBapp/hrmfeature2.png',
                bg: 'shadowbg'
            },
            card1: {
                heading: 'Team Sync and Share',
                content: 'Keep everyone on the same page by sharing updates and seeing team progress easily',
                img: '../assesst/WEBapp/daswebappfeature3.png',
                bg: 'shadowbg2 ',
                imgcss: ' w-[80%] sm:w-[50%] mx-auto '
            },
            footer: {
                heading: 'Make Every Day Count with our DAS',
                content: 'Track, organize, and manage daily tasks with clear and easy steps',
                // img: '../assesst/WEBapp/hrmwebfooter.png',
                bg: 'bg-black ',
                imgcss: ' w-[90%] mx-auto my-auto '
            }
        }
    },
    {
        cardimg: "../assesst/Websites/lernenhubcard.png",
        cardbg: 'lernenhubgrdbg',
        appName: 'Lernen Hub Software',
        slug: 'lernen-hub',
        theme: 'theme1',
        cardcontent: `A modern education system designed to make learning easier and more enjoyable for both students and teachers. It offers simple tools to manage student data, create tests, and improve communication. With personalized learning journeys, it helps students grow at their own pace. Teachers can monitor progress and provide feedback. Accessible on any device, Learner Hub supports flexible learning anytime, anywhere. It’s built to adapt to your needs, making education more organized, 
        interactive, and effective for everyone.`,
        bheadcss: ' sm:w-[64%] ',
        bannerHeading: 'A Modern Way to Learn & Grow',
        bannercontent: `A fresh approach to modern education for both students and teachers with a system built for today’s needs`,
        bannerimg: '../assesst/Websites/lernenhubcard.png',
        bannerbg: 'lernenhubwebappbg',
        bodyclr: 'lernenhubwebappbg',
        whyData: {
            hcss: 'text-slate-200 ',
            pcss: 'text-slate-400 ',
            heading: 'Making Education Accessible for Everyone',
            content: 'Designed for educators and learners to support interactive learning journeys',
            leftContent: [
                {
                    heading: 'Centralized Student Data Management',
                    content: 'Organize student records with ease and accuracy'
                },
                {
                    heading: 'Dynamic Test Creation and Grading ',
                    content: 'Design, assign, and grade tests in one centralized system'
                },
                {
                    heading: 'Smart Collaboration Tools',
                    content: 'Connect with peers and instructors with built-in channels'
                },
                {
                    heading: 'Adaptable to Your Needs',
                    content: 'Our software scales up as your healthcare practice expands'
                },
            ],
            card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            hcss: 'text-slate-200 ',
            pcss: 'text-slate-300 ',
            scss: 'text-slate-300',
            benifitHeading: `One Platform for All Your Learning Needs`,
            benifitContent: `Our system improves accessibility, organization, and collaboration, making learning enjoyable and manageable for both 
            educators and students`,
            benifitpoints: [
                "Increased engagement with interactive learning tools",
                "Better learning outcomes through personalized assessments",
                "Flexible scheduling to fit any learning pace",
                "Simple access to all educational materials and resources"
            ],
            benifittitle: 'Benefits You Can Count On',
            img: '../assesst/WEBapp/lernenhubwebappcontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Start Testing in No Time with Our System`,
        //     benifitContent: `Our process ensures seamless exams for candidates and quick, efficient management for admins with instant
        //      feedback`,
        //     benifitpoints: [
        //         'Register and log in',
        //         `Configure your exam`,
        //         `Candidates begin test`,
        //         `Admins review results`
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            hcss: ' text-slate-200 ',
            pcss: 'text-slate-300',
            heading: 'Modern Tools for Modern Education Management',
            content: 'Our system incorporates innovative features that helps in effective, organized, and user-friendly management',
            card3: {
                heading: 'Test Creation & Grading',
                content: `Create custom tests, assign automatically generated grades, and review progress. A complete assessment solution that simplifies evaluation and boosts 
                accuracy`,
                img: '../assesst/WEBapp/lernenhubfeature1.png',
                bg: 'shadowbg2',
                imgcss: '',

            },
            card4: {
                heading: 'Communication Tools',
                content: 'Send announcements, direct messages, and notifications, supporting seamless collaboration.',
                img: '../assesst/WEBapp/lernenhubfeature2.png',
                bg: 'shadowbg2',
                swap: true
            },

            footer: {
                heading: 'Create Meaningful Learning Experiences With a System Built for Today’s Learning Needs',
                content: 'Get in touch with us for more details!',
                img: '../assesst/WEBapp/lernenhubfooter.png',
                bg: ' shadow rounded-xl '
            }
        }
    },
    {
        cardimg: "../assesst/WEBapp/crmwebappcard-min.png",
        cardbg: 'crmgrdbg',
        appName: 'CRM Software',
        slug: 'crm-software',
        theme: 'theme2',
        imgcss: 'h-full',
        cardcontent: `This CRM system helps businesses stay organized by easily tracking leads, managing client interactions, and making follow-ups simple. It connects all your customer information in one place, so you never miss important details. With real-time data, you can make smarter decisions and improve team workflow. Whether you're in the office or on the go, our CRM keeps you connected to your clients helping you build stronger 
            relationships and grow your business. `,
        bodyclr: 'bg-black',
        bheadcss: ' sm:w-[90%] ',
        bannerHeading: 'CRM Built to Organize, Track, and Engage with Clients',
        bannercontent: `A CRM system for enhanced client interactions and relationships`,
        bannerimg: '../assesst/WEBapp/crmwebappcard-min.png',
        bannerbg: 'bg-black ',
        whyData: {
            heading: 'More Than Just a CRM',
            hcss: 'text-slate-100',
            pcss: 'text-slate-300',
            content: `Our CRM integrates customer data, schedules, and communication to keep every interaction productive and connected.`,
            leftContent: [
                {
                    heading: 'Lead Tracking Made Simple',
                    content: 'Capture and track each lead seamlessly'
                },
                {
                    heading: 'Never Miss a Follow-Up ',
                    content: 'Set reminders to keep in touch with clients'
                },
                {
                    heading: 'Real-Time Data at Your Fingertips',
                    content: 'Access useful insights to make informed decisions'
                },
            ],
            // card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            hcss: 'text-slate-200',
            pcss: 'text-slate-300',
            scss: 'text-slate-300',
            benifitHeading: `Improve Customer Relationships & Team Workflow`,
            benifitContent: `Our CRM solution brings everything to one platform, making it easy to track, manage, and follow up on all customer 
            relationships`,
            benifitpoints: [
                "Schedule a demo",
                "Choose your features",
                "Set up in minutes",
                "Begin managing leads"
            ],
            benifittitle: 'Benefits of Our CRM',
            img: '../assesst/WEBapp/crmwebappcontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Improve Customer Relationships & Team Workflow`,
        //     benifitContent: `Our CRM solution brings everything to one platform, making it easy to track, manage, and follow up on all customer relationships`,
        //     benifitpoints: [
        //         "Schedule a demo",
        // "Choose your features",
        // "Set up in minutes",
        // "Begin managing leads"
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            hcss: 'text-slate-200',
            pcss: 'text-slate-300',
            heading: 'Strengthen Customer Connections & Improve Efficiency Across Teams',
            content: 'Lead tracking, insights, and more powerful support features to empower your team',
            card3: {
                heading: 'Lead Management',
                content: 'Easily capture, organize, and track leads for seamless follow-ups and conversions',
                img: '../assesst/WEBapp/crmwebappfeature1.png',
                bg: 'shadowbg2',
                imgcss: '',

            },
            card2: {
                heading: 'Client Engagement',
                content: `Engage with clients through automated reminders, personalized messages, and timely follow-ups to build stronger relationships and
                 improve retention`,
                img: '../assesst/WEBapp/crmwebappfeature2.png',
                bg: 'shadowbg pb-0 ',
                imgcss: 'mt-auto',
                cardcss: 'pb-0'
            },
            card1: {
                heading: 'Data-Driven Insights',
                content: 'Gain valuable insights from real-time data, helping you make informed decisions and refine strategies to improve team performance',
                img: '../assesst/WEBapp/crmwebappfeature3.png',
                bg: 'shadowbg2 ',
                imgcss: ' w-[80%] sm:w-[50%] mx-auto '
            },
            footer: {
                heading: 'Get Started with Our CRM for Better Customer Connections',
                content: 'All your leads, clients, and sales in one place!',
                // img: '../assesst/WEBapp/hrmwebfooter.png',
                bg: 'bg-black ',
                imgcss: ' w-[90%] mx-auto my-auto '
            }
        }

    },
    {
        cardimg: "../assesst/WEBapp/ticketmanagementwebappcard-min.png",
        cardbg: 'ticketgrdbg',
        appName: 'Ticket Management Software',
        slug: 'ticket-management',
        theme: 'theme1',
        contentcss: 'min-h-[60vh] flex ',
        cardcontent: `Ticket Management System helps businesses handle customer questions and problems easily and quickly. It automatically assigns tasks to the right team, so issues are solved faster. The system keeps everything organized with a simple dashboard to track progress and updates. It also allows for clear communication within the team, improving teamwork and customer satisfaction. With custom solutions and easy integration, our system makes customer support smoother and more efficient, providing a better experience for both the team 
        and the customer.`,
        bodyclr: 'ticketmanagementbg ',
        bheadcss: ' sm:w-[90%] ',
        bannerHeading: 'Redefining Customer Support with Our Smart Ticket Management System',
        bannercontent: `Organize, track, and resolve customer queries effortlessly with our system`,
        // bannerimg: '../assesst/WEBapp/crmwebappcard-min.png',
        bannerbg: 'ticketmanagementbannerbg min-h-[90vh]  ',
        whyData: {
            heading: 'Custom Solutions for Your Unique Ticketing Needs',
            hcss: 'text-slate-100',
            pcss: 'text-slate-300',
            content: `Our Ticket Management System adapts to your workflow, delivering better service quality and happier customers`,
            leftContent: [
                {
                    heading: 'Faster Resolutions for Every Query',
                    content: 'Get alerts for ticket update and resolve customer issues quickly'
                },
                {
                    heading: 'Collaborate Like Never Before ',
                    content: 'Enhance team performance through clear communication and task organization'
                },
                {
                    heading: 'Advanced Reporting Features',
                    content: 'Gain valuable insights with detailed ticket reports and analytics'
                },
            ],
            // card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            hcss: 'text-slate-200',
            pcss: 'text-slate-300',
            scss: 'text-slate-300',
            benifitHeading: `Optimizing Support with Custom Ticketing Solutions`,
            benifitContent: `Our ticket management system automates workflows, helping teams manage inquiries more effectively while improving response time and customer satisfaction across all
             touchpoints`,
            benifitpoints: [
                "Faster response times for customer issues",
                "Automated ticket assignment to reduce manual work",
                "Improved customer engagement and satisfaction",
                "Easy integration with existing tools and systems"
            ],
            benifittitle: 'Ticket Management System Benefits',
            img: '../assesst/WEBapp/crmwebappcontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Get Your Ticketing System Running in No Time`,
        //     benifitContent: `From setup to ongoing support, our Ticket Management System is designed for easy implementation, ensuring your team stays productive and organized while providing high-quality customer 
        //     service`,
        //     benifitpoints: [
        //         "Create a support ticket",
        //         "Assign ticket to team",
        //         "Monitor ticket status",
        //         "Resolve and close ticket"
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            hcss: 'text-slate-200',
            pcss: 'text-slate-300',
            heading: 'Packed with Tools that Improve Your Customer Support',
            content: 'Every feature is designed to make your customer support smoother, faster, and more reliable.',
            card3: {
                heading: 'Smart Ticket Routing',
                content: 'Automatically direct tickets to the right team, ensuring faster resolutions',
                img: '../assesst/WEBapp/ticketmanagementcontent1.png',
                bg: 'shadowbg2',
                swap: true,
                imgcss: '',

            },
            card5: {
                heading: 'Centralized Dashboard',
                content: `Track ticket statuses, prioritize urgent requests, and view progress all from a unified dashboard`,
                img: '../assesst/WEBapp/crmwebappfeature2.png',
                bg: ' ',
                imgcss: 'mt-auto',
                cardcss: 'pb-0  '
            },
            card6: {
                heading: 'Customizable Ticket Categories',
                content: 'Organize tickets according to your business requirements with custom categories and labels for better management',
                img: '../assesst/WEBapp/crmwebappfeature3.png',
                bg: '  ',
                imgcss: ' w-[80%] sm:w-[50%] mx-auto '
            },
            card5css: 'tciketmanagementcardbg min-h-[80vh] ',
            footer: {
                heading: 'Upgrade Your Ticketing System for Faster, More Effective Customer Support',
                content: 'All your leads, clients, and sales in one place!',
                // img: '../assesst/WEBapp/hrmwebfooter.png',
                bg: 'bg-black ',
                imgcss: ' w-[90%] mx-auto my-auto '
            }
        }

    },
    {
        cardimg: "../assesst/WEBapp/e-commercewebappcard-min.png",
        cardbg: 'ecommercegrdbg',
        appName: 'E-Commerce App',
        slug: 'e-commerce',
        theme: 'theme2',
        cardcontent: `Mobile case customization system makes it easy to personalize and print phone cases. With our software, you can manage printing tasks, track jobs in real time, and control access from anywhere. It supports various file types and ensures fast, reliable prints. Our system is designed to be user-friendly with flexible settings, so anyone can use it, whether at home or work. You’ll have full control over your print jobs, 
        saving time and resources while creating the perfect phone case with ease.`,
        bheadcss: ' sm:w-[64%] ',
        bannerHeading: 'A Better Way to Print All Your Documents, Files, and More',
        bannercontent: `Order with ease and design case from anywhere, all with a few clicks`,
        bannerimg: '../assesst/WEBapp/e-commercewebappcard-min.png',
        bannerbg: 'onlinetestwebappbg mx-auto ',
        bannerimgcss: 'w-[80%] mx-auto',
        whyData: {
            heading: 'Printing Solutions That You Can Rely On',
            content: 'Our printing system is built to fit your needs with easy-to-use features for everyone',
            leftContent: [
                {
                    heading: 'Complete Control Over Printing',
                    content: 'Manage printers, tasks, and users from anywhere'
                },
                {
                    heading: 'Multiple File Support ',
                    content: 'Print various file formats without worrying about compatibility'
                },
                {
                    heading: 'Secure Access Control',
                    content: 'Control user access based on roles and permissions'
                },
            ],
            card: '../assesst/WEBapp/bluecardwebapp.png'
        },
        benifitObj: {
            benifitHeading: `Here’s Why Printlele is the Best Choice`,
            benifitContent: `From remote access to personalized settings, Printlele offers benefits that make every print job smoother`,
            benifitpoints: [
                "Print from anywhere",
                "Cut down on paper waste",
                "Real-time job tracking",
                "User-friendly permissions"
            ],
            benifittitle: 'Printlele Benefits',
            img: '../assesst/WEBapp/printelelecontent1.png'
        },

        // howitworks: {
        //     benifitHeading: `Start Testing in No Time with Our System`,
        //     benifitContent: `Our process ensures seamless exams for candidates and quick, efficient management for admins with instant
        //      feedback`,
        //     benifitpoints: [
        //         'Register and log in',
        //         `Configure your exam`,
        //         `Candidates begin test`,
        //         `Admins review results`
        //     ],
        //     benifittitle: 'How It Works'
        // },
        features: {
            heading: 'Innovative Tools to Enhance Your Printing Experience',
            content: 'Our Printlele system comes with features that offer a simple and secure way to manage printing tasks, allowing users to easily track and control all activities.',
            card3: {
                heading: 'User Permissions Management',
                content: `Admin can assign roles to employee, controlling their access levels and ensuring a secure and organized printing 
                environment.`,
                img: '../assesst/WEBapp/printlelewebappfeature1.png',
                bg: 'shadowbg2',
                imgcss: '',

            },
            footer: {
                heading: 'Start Printing Smarter with Printlele',
                content: 'Get in touch with us for more details!',
                img: '../assesst/WEBapp/e-commercewebappcard-min.png',
                bg: 'bg-black'
            }
        }
    },
    {
        cardimg: "../assesst/WEBapp/billingwebappcard-min.png",
        cardbg: 'billinggrdbg',
        appName: 'Billing Software',
        slug: 'billing-software',
        theme: 'theme1',

        cardcontent: `Get started quickly by contacting us for a consultation. Our team will guide you through installation, integration, and provide continuous support to ensure a smooth transition for 
            your team.`,
        bannerHeading: ' Advanced Medical Software for Modern Healthcare',
        bannercontent: 'A powerful SaaS platform designed to optimize healthcare operations with ease',
        // bannerimg: '../assesst/WEBapp/gebabannerwebapp.png',
        bannerbg: 'billingwebappbg min-h-[100vh] ',
        bheadcss: 'w-[80%] sm:w-[60%] ',
        whyData: {
            heading: 'Leading the Way in Healthcare Software Innovation',
            content: 'Our SaaS model support healthcare providers with reliable software tools, while we handle maintenance, security, and updates',
            leftContent: [
                {
                    heading: 'Customizable to Your Needs',
                    content: 'Features that match your specific healthcare requirements'
                },
                {
                    heading: 'Real-Time Updates for All',
                    content: 'Access up-to-date patient data anytime, anywhere, securely'
                },
                {
                    heading: 'Adaptable to Your Needs',
                    content: 'Our software scales up as your healthcare practice expands'
                },
            ],
        },
        benifitObj: {
            benifitHeading: `Your Healthcare Operations in Safe Hands`,
            benifitContent: `Our solution makes patient care better, cuts down on extra costs, and enhances workflow with advanced technology and easy access`,
            benifitpoints: [
                'Minimize errors in records',
                'Access your data on the go',
                'Smooth collaboration with your team',
                'Strong protection for sensitive data  this',
            ],


            benifittitle: 'Benefits You Can Count On',
            img: '../assesst/WEBapp/billingwebappfeature1.png'
        },

        howitworks: {
            benifitHeading: `Smooth Onboarding for Reliable Results`,
            benifitContent: `Get started quickly by contacting us for a consultation. Our team will guide you through installation, integration, and provide continuous support to ensure a smooth transition for 
            your team.`,
            hcss: 'text-slate-200 w-4/5 ',
            pcss: 'text-slate-300',
            scss: 'text-slate-300',
            img: '../assesst/WEBapp/billingwebappcontent1.png',
            benifitpoints: [
                'Get in touch for a demo',
                'Let us know your needs',
                'Receive a customized solution',
                'Start using the software and monitor your progress'
            ],
            benifittitle: 'How It Works',
            show: true
        },
        features: {
            heading: 'Secure and Efficient Tools for Modern Medical Practices',
            content: 'Our SaaS model offers robust features that meet the diverse needs of healthcare providers and ultimately improve operations',
            card3: {
                heading: 'Client-Employee Management',
                content: 'Manage client data and assign roles to ensure smooth communication and task delegation within the system',
                img: '../assesst/WEBapp/billingwebappfeature2.png',
                bg: 'shadowbg2'
            },
            footer: {
                heading: 'Medical Software Built for Your Healthcare Needs',
                content: 'Get in touch with us for more details!',
                img: '../assesst/WEBapp/billingfooter.png',
                bg: 'bg-black'
            }
        }
    },
]