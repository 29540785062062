import React from 'react'

const OcircleIcon = ({ w, h }) => {
    return (
        <svg width={w ? w : "85"} height={h ? h : "82"} viewBox="0 0 85 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_751_1130" fill="white">
                <path d="M84 41C84.5523 41 85.0015 41.4479 84.986 42C84.7439 50.6673 81.36 59.0246 75.3529 65.7413C69.1152 72.7161 60.435 77.4904 50.7913 79.2506C41.1477 81.0109 31.1372 79.6483 22.4656 75.3949C13.7941 71.1416 6.99789 64.2607 3.23512 55.9247C-0.527652 47.5886 -1.02422 38.3132 1.83003 29.6789C4.68429 21.0446 10.7128 13.5855 18.8883 8.57269C27.0638 3.55986 36.8805 1.3034 46.6657 2.1878C56.0743 3.03814 64.8941 6.74159 71.7394 12.6968C72.1816 13.0815 72.181 13.7633 71.7491 14.1596L44.3927 39.2632C43.7209 39.8797 44.157 41 45.0688 41H84Z" />
            </mask>
            <path d="M84 41C84.5523 41 85.0015 41.4479 84.986 42C84.7439 50.6673 81.36 59.0246 75.3529 65.7413C69.1152 72.7161 60.435 77.4904 50.7913 79.2506C41.1477 81.0109 31.1372 79.6483 22.4656 75.3949C13.7941 71.1416 6.99789 64.2607 3.23512 55.9247C-0.527652 47.5886 -1.02422 38.3132 1.83003 29.6789C4.68429 21.0446 10.7128 13.5855 18.8883 8.57269C27.0638 3.55986 36.8805 1.3034 46.6657 2.1878C56.0743 3.03814 64.8941 6.74159 71.7394 12.6968C72.1816 13.0815 72.181 13.7633 71.7491 14.1596L44.3927 39.2632C43.7209 39.8797 44.157 41 45.0688 41H84Z" stroke="#EE9611" stroke-width="2" mask="url(#path-1-inside-1_751_1130)" />
            <path d="M76 41C76.5523 41 77.0012 41.4479 76.9886 42C76.7795 51.1301 73.7128 59.9357 68.2609 67.0101C62.6102 74.3426 54.747 79.3617 46.011 81.2122C37.2749 83.0627 28.2067 81.6302 20.3512 77.1588C12.4958 72.6873 6.33927 65.4535 2.93064 56.69C-0.477991 47.9265 -0.92782 38.1755 1.6578 29.0983C4.24341 20.0212 9.7045 12.1796 17.1105 6.90975C24.5166 1.63985 33.4094 -0.732321 42.2737 0.197426C50.8358 1.09548 58.8597 5.02054 65.0727 11.3316C65.4431 11.7078 65.4422 12.3083 65.0808 12.6932L40.0818 39.3155C39.4823 39.9539 39.935 41 40.8108 41H76Z" fill="#EE9611" />
        </svg>
    )
}

export default OcircleIcon