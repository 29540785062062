import React from 'react'

const LogoDesignNavIcon = ({ size }) => {
    return (
        <svg width={size ? size : "30"} height={size ? size : "30"} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1667 9.78325H12.1417L10.5944 14.9868H13.7639L12.1667 9.78325ZM12.1667 9.78325H12.1417L10.5944 14.9868H13.7639L12.1667 9.78325ZM24.6453 0H5.30342C2.37094 0 0 2.37094 0 5.30342V23.8966C0 26.8291 2.37094 29.2 5.30342 29.2H24.6453C27.5778 29.2 29.9487 26.8291 29.9487 23.8966V5.30342C29.9487 2.37094 27.5778 0 24.6453 0ZM18.3436 20.6272H15.7356C15.6482 20.6397 15.5609 20.5773 15.5359 20.4899L14.5126 17.52H9.8706L8.92222 20.4525C8.89726 20.5648 8.79743 20.6397 8.68513 20.6272H6.33914C6.20188 20.6272 6.16444 20.5523 6.20188 20.4026L10.22 8.83487C10.2574 8.71008 10.2949 8.57282 10.3448 8.42308C10.3947 8.16103 10.4197 7.8865 10.4197 7.61197C10.4072 7.54957 10.4571 7.48718 10.5195 7.4747H13.7515C13.8513 7.4747 13.9012 7.51214 13.9137 7.57453L18.4684 20.4275C18.5058 20.5648 18.4684 20.6272 18.3436 20.6272ZM22.5863 20.44C22.5863 20.5773 22.5364 20.6397 22.4241 20.6397H19.9783C19.8535 20.6397 19.7911 20.5648 19.7911 20.44V10.8315C19.7911 10.7067 19.841 10.6568 19.9533 10.6568H22.4241C22.5364 10.6568 22.5863 10.7191 22.5863 10.8315V20.44ZM22.3243 9.17179C22.0373 9.47128 21.613 9.6335 21.1887 9.60855C20.7769 9.62103 20.3776 9.4588 20.0781 9.17179C19.7911 8.85983 19.6414 8.44803 19.6538 8.02376C19.6414 7.59949 19.8036 7.20017 20.1031 6.91316C20.4026 6.62615 20.8019 6.47641 21.2137 6.47641C21.7003 6.47641 22.0747 6.62615 22.3492 6.91316C22.6238 7.21265 22.7735 7.61197 22.761 8.02376C22.7735 8.44803 22.6238 8.85983 22.3243 9.17179ZM12.1417 9.78325L10.5944 14.9868H13.7639L12.1667 9.78325H12.1417Z"
                fill="currentColor" />
        </svg>
    )
}

export default LogoDesignNavIcon