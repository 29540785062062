import React, { useContext, useEffect } from 'react'
import Banner from '../component/WebsiteCom/Banner'
import NavBarCom from '../component/NavBarCom'
import CardWiseProject from '../component/WebsiteCom/CardWiseProject'
import ScrollingComponent from '../component/WebsiteCom/ScrollingComponent'
import GalaxyRotation from '../component/WebsiteCom/GalaxyRotation'
import { PorfolioStore } from '../context/PortFolioContext'

const WebsitePage = () => {
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('web')
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className='sticky top-0 z-20 ' >
                <NavBarCom theme="bg-white py-0 my-0 " />
            </div>

            <div className='col-lg-10 ms-auto ' >
                <Banner />
            </div>
            <CardWiseProject />
            <div className='col-lg-10 ms-auto ' >
                <ScrollingComponent />
            </div>
            <div className='col-lg-10 ms-auto ' >
                {/* <GalaxyRotation /> */}
            </div>


            <main className='container-fluid p-0 m-0 ' >
                <img src={require('../assesst/websiteFooter.webp')} alt="WebsitePageFooter" loading='lazy'
                    className='' />
            </main>
            {/* Scroll  */}
            <section className=' py-3' style={{ backgroundColor: 'rgb(90,196,255)' }} >
                <marquee behavior="alternate" direction="">
                    <div className='flex sans items-center gap-5 text-slate-800' >
                        <span className='text-xl sm:text-7xl fw-semibold ' >Let's Talk </span>
                        <span className='uppercase fw-semibold ' >Get in touch </span>
                        <span className='text-xl sm:text-7xl fw-semibold ' >Let's Talk </span>
                        <span className='uppercase fw-semibold ' >Get in touch </span>
                        <span className='text-xl sm:text-7xl fw-semibold ' >Let's Talk </span>
                        <span className='uppercase fw-semibold ' >Get in touch </span>
                        <span className='text-xl sm:text-7xl fw-semibold ' >Let's Talk </span>
                        <span className='uppercase fw-semibold ' >Get in touch </span>
                        <span className='text-xl sm:text-7xl fw-semibold ' >Let's Talk </span>
                        <span className='uppercase fw-semibold ' >Get in touch </span>
                        <span className='text-xl sm:text-7xl fw-semibold ' >Let's Talk </span>
                        <span className='uppercase fw-semibold ' >Get in touch </span>
                    </div>
                </marquee>
            </section>
        </div>
    )
}

export default WebsitePage