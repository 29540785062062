import React from 'react'

const SettingIcon = ({ size }) => {
    return (
        <svg width={size ? size : "33"} height={size ? size : "33"} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.414 17.4933C26.4674 17.0933 26.494 16.6799 26.494 16.2399C26.494 15.8133 26.4674 15.3866 26.4007 14.9866L29.1074 12.8799C29.3474 12.6933 29.414 12.3333 29.2674 12.0666L26.7074 7.63994C26.5474 7.34661 26.214 7.25327 25.9207 7.34661L22.734 8.62661C22.0674 8.11994 21.3607 7.69327 20.574 7.37327L20.094 3.98661C20.0407 3.66661 19.774 3.43994 19.454 3.43994H14.334C14.014 3.43994 13.7607 3.66661 13.7074 3.98661L13.2274 7.37327C12.4407 7.69327 11.7207 8.13327 11.0674 8.62661L7.88069 7.34661C7.58736 7.23994 7.25403 7.34661 7.09403 7.63994L4.54736 12.0666C4.38736 12.3466 4.44069 12.6933 4.70736 12.8799L7.41403 14.9866C7.34736 15.3866 7.29403 15.8266 7.29403 16.2399C7.29403 16.6533 7.32069 17.0933 7.38736 17.4933L4.68069 19.5999C4.44069 19.7866 4.37403 20.1466 4.52069 20.4133L7.08069 24.8399C7.24069 25.1333 7.57403 25.2266 7.86736 25.1333L11.054 23.8533C11.7207 24.3599 12.4274 24.7866 13.214 25.1066L13.694 28.4933C13.7607 28.8133 14.014 29.0399 14.334 29.0399H19.454C19.774 29.0399 20.0407 28.8133 20.0807 28.4933L20.5607 25.1066C21.3474 24.7866 22.0674 24.3599 22.7207 23.8533L25.9074 25.1333C26.2007 25.2399 26.534 25.1333 26.694 24.8399L29.254 20.4133C29.414 20.1199 29.3474 19.7866 29.094 19.5999L26.414 17.4933ZM16.894 21.0399C14.254 21.0399 12.094 18.8799 12.094 16.2399C12.094 13.5999 14.254 11.4399 16.894 11.4399C19.534 11.4399 21.694 13.5999 21.694 16.2399C21.694 18.8799 19.534 21.0399 16.894 21.0399Z"
                fill="currentColor" />
        </svg>

    )
}

export default SettingIcon