import React from 'react'
import Ecpilise from './LogoCom/Ecpilise'
import RightArrow from '../Icons/RightArrow'
import { useNavigate } from 'react-router'

const ReadyProject = ({ color, ecpclr, fntclr }) => {
    let navigate = useNavigate()
    return (
        <div className={` relative `} >
            <div
                className={`${color && color} mx-auto relative -z-10 container rounded-xl my-5 min-h-[80vh] flex  `} >
                {/* <div className={` ${ecpclr ? ecpclr : ''}
                 absolute d-sm-none z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 `}>
                    <Ecpilise w={300} />
                </div> */}
                <div className={` ${ecpclr ? ecpclr : ''} absolute -z-1 d-none d-sm-flex top-1/2 left-1/2 
                -translate-x-1/2 -translate-y-1/2 `} >
                    <span className='relative  ' >
                        <Ecpilise />
                    </span>
                </div>
            </div>
            <article className='m-auto w-fit inter absolute left-1/2 -translate-y-1/2 -translate-x-1/2 top-1/2 z-10 text-center ' >
                <h4 className={`  ${fntclr ? fntclr : "text-slate-50"} sm:text-6xl fw-bold uppercase
                     text-5xl my-2 `} >
                    Ready, Set, Design
                </h4>
                <p className={`  ${fntclr ? fntclr : "text-slate-50"} my-3 sm:text-xl `} >
                    With years of experience and many successful projects, <br />
                    we’re excited to help your business next with expert designs that fit your needs.
                </p>
                <button onClick={() => window.open('https://www.meridatechminds.com/contact-us', '_blank')}
                    className='flex items-center relative z-10 gap-3 p-3 px-4
                      bg-slate-50 text-slate-700 fw-semibold rounded-full mx-auto my-4 ' >
                    Contact Us
                    <span className='rounded-full  border-[1.7px] border-slate-700 p-1 text-slate-700 ' >
                        <RightArrow />
                    </span>
                </button>
            </article>
        </div>
    )
}

export default ReadyProject