import React from 'react'
import RightArrow from '../../Icons/RightArrow'

const Banner = () => {
  return (
    <div className='' >
      <main className='container mx-auto p-3 uppercase poppins' >
        <h5 className=' uppercase fw-medium text-2xl my-4' >
          Crafting Experiences That Matter
        </h5>
        <h2 className=' text-slate-800 text-4xl sm:text-6xl lg:text-7xl sm:text-[5.2rem] fw-semibold my-4 relative ' >
          Custom Web
          <br />
          Design Solutions for <br />
          Your Business Needs
          {/* Right top  */}
          <div className='d-none d-sm-flex absolute top-0 right-0 items-center flex gap-3 text-sm  ' >
            <p className='text-slate-600 d-none d-lg-block text-sm fw-normal mb-0 ' >
              We’re excited to help <br /> your business next with
              <br /> expert designs that <br />
              fit your needs.</p>
            <div className='rounded-full h-fit uppercase text-slate-50 p-2 
             activebg gap-2 flex ps-3 items-center' >
              Let's Talk
              <button onClick={() => {
                window.open('https://www.meridatechminds.com/contact-us', '_blank')
              }} className=' bg-slate-50 text-blue-600 p-2 -rotate-45 rounded-full ' >
                <RightArrow />
              </button>
            </div>
          </div>
          {/* End right top */}
        </h2>
        {/* Creative design blue thing */}
        <section className='flex gap-2  ' >
          <article className=' p-2 p-sm-3 activebg text-slate-50 fw-medium px-5 uppercase text-md sm:text-2xl ' >
            A Creative design
            <br /> agency from Banglore
          </article>
          <article className=' activebg p-4  ' ></article>
          <article className=' activebg p-2  ' ></article>
          <article className=' activebg p-1  ' ></article>
          <article className=' activebg p-[2px]  ' ></article>
        </section>
        {/* <h2 className=' text-slate-800 text-6xl sm:text-8xl fw-semibold my-4 relative ' >
        Your Business Needs
        </h2> */}
      </main>

    </div>
  )
}

export default Banner