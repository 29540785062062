import React, { useContext, useEffect } from 'react'
import NavBarCom from '../component/NavBarCom'
import TouchIcon from '../Icons/TouchIcon'
import ThumbIcon from '../Icons/ThumbIcon'
import TabIcon from '../Icons/TabIcon'
import LaunchIcon from '../Icons/LaunchIcon'
import SettingIcon from '../Icons/SettingIcon'
import RightArrow from '../Icons/RightArrow'
import { useNavigate } from 'react-router'
import { PorfolioStore } from '../context/PortFolioContext'

const CRMAppPage = () => {
    let navigate = useNavigate()
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('mobile')
        window.scrollTo(0,0)

    }, [])
    return (
        <div className='overflow-hidden ' >
            {/* Banner */}
            <main className=' min-h-[50vh] xl:min-h-[100vh] relative crmbannerbgclr ' >
                <NavBarCom theme='dark' />
                <section className='min-h-[30vh] xl:min-h-[70vh] flex  ' >
                    <article className='m-auto ' >
                        <h4 className='text-center my-3 fw-bold text-4xl sm:text-7xl poppins ' >
                            Strengthen Every Customer <br /> Relationship with Our CRM
                        </h4>
                        <p className='text-center text-slate-700 ' >
                            Simple and intuitive CRM to keep track of leads, clients, and sales in one place.
                        </p>
                    </article>
                </section>
                {/* Ring */}
                <div className='w-10 h-10 sm:w-20 sm:h-20 border-[10px] sm:border-[20px] border-slate-600 absolute bottom-14 sm:bottom-20 left-10 rounded-full ' >
                </div>

            </main>
            {/* Image mobile sliders */}
            <img src={require('../assesst/crmapp/crmappbanner-min.png')} alt="crm_sliders"
                className=' relative  -translate-y-1/3 ' />
            {/* How it works */}
            <main className='container mx-auto ' >
                <h4 className='text-center fw-bold text-2xl sm:text-4xl ' >
                    How Our CRM Works</h4>
                <p className='text-center text-slate-700 my-3 ' >
                    App works very smoothly with your requirements and its easy to install and <br />
                    register to you and your clients.
                </p>
                <section className='row crmbannerbgclr rounded-2xl p-4' >

                    <article className='col-lg-4 justify-around flex flex-col my-3' >
                        <div className='flex flex-col my-3 items-end w-[20rem] ' >
                            <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                                <TouchIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' > Capture and Manage Leads </p>
                            <p className='text-slate-700 w-4/5 text-end ' >
                                Easily collect leads from multiple channels and input them directly into the CRM system. </p>
                        </div>
                        <div className='flex my-3 flex-col items-end w-[20rem] ' >

                            <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                                <ThumbIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' >Segregate leads by needs</p>
                            <p className='text-slate-700 w-4/5 text-end ' >
                                Organize leads into categories like clients seeking services or customers looking for courses. </p>
                        </div>
                    </article>
                    {/* Mobile Image */}
                    <article className='col-lg-4 my-3 mb-0 flex' >
                        <img src={require('../assesst/crmapp/crmappcenterpiece-min.png')} alt="Mobile"
                            className='mt-auto w-full ' />
                    </article>
                    {/* Card 2 col */}
                    <article className='col-lg-4 justify-around flex flex-col my-3' >
                        <div className='flex flex-col my-3 ms-auto w-[20rem] ' >

                            <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                                <TabIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' > Follow-up Consistently </p>
                            <p className='text-slate-700 w-4/5 ' >
                                Set up reminders to ensure timely follow-ups and move them closer to making a purchase. </p>
                        </div>
                        <div className='flex my-3 flex-col ms-auto w-[20rem] ' >

                            <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                                <LaunchIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' > Convert Leads to Prospects

                            </p>
                            <p className='text-slate-700 w-4/5 ' >
                                Transform leads into paying customers with smooth payment integration and a simple closing process.
                            </p>
                        </div>
                    </article>
                </section>
                {/* <h6 className='text-center my-3 text-2xl fw-semibold ' > Enjoy with Onir! </h6>
                <p className='text-center text-slate-600  pb-3 ' >Nullam tincidunt, purus sit amet viverra auctor, quam neque dapibus dui, </p> */}
                {/* <hr className='my-3' /> */}
            </main>
            {/* Features */}
            <main className='py-5 container mx-auto roboto ' >
                <h5 className='text-3xl  text-center fw-semibold ' > Features of Our CRM App</h5>
                <p className='text-center text-slate-600 ' >
                    Our CRM app provides the essential tools to manage leads and clients,
                    making your workflow easier and more organized.
                </p>
                <section className='row py-4 ' >
                    <article className='col-lg-6 my-4 ' >

                        <img src={require('../assesst/crmapp/crmappfeature1-min.png')} alt="Crm Feature"
                            className='w-4/5 mx-auto ' />
                    </article>
                    <article className='col-lg-6 flex my-4 '>
                        <div className='my-auto  ' >
                            <p className='text-slate-500 fw-semibold ' >
                                Powerful Lead Management
                            </p>
                            <h5 className='text-3xl sm:text-5xl text-slate-800 my-2 fw-bolder ' >
                                Track Leads from First Contact to Final Sale
                            </h5>
                            <p className='my-3 text-slate-600 ' >
                                Keep tabs on all sales activities, track customer interactions,
                                and measure progress with simple reports.
                                Make smart choices with clear insights to grow your business.
                            </p>
                            <ul className='list-inside my-4' >
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Quick Lead Capture
                                </li>
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Client Interaction Tracking
                                </li>
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Follow-up Automation
                                </li>
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Sales Monitoring
                                </li>
                            </ul>
                        </div>
                    </article>
                    <article className='col-lg-6 flex my-4 '>
                        <div className='my-auto  ' >

                            <p className='text-slate-500 fw-semibold ' >
                                Reporting & Insights
                            </p>
                            <h5 className='text-3xl sm:text-5xl text-slate-800 my-2 fw-bolder ' >
                                Get Clear Reports for Better Decisions
                            </h5>
                            <p className='my-3 text-slate-600 ' >
                                Access detailed reports on lead behavior, sales progress, and more.
                            </p>
                            <ul className='list-inside my-4' >
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Easy-to-read dashboards
                                </li>
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Track sales performance
                                </li>
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Gain insights into customer behavior
                                </li>
                                <li className='flex items-center gap-2 fw-semibold my-2 ' >
                                    <span className=' w-2 h-2 border-2 border-violet-700 rounded-full  ' >
                                    </span> Make data-driven decisions
                                </li>
                            </ul>
                        </div>
                    </article>
                    <article className='col-lg-6 my-4 ' >
                        <img src={require('../assesst/crmapp/crmappfeature2-min.png')} alt="Feature 2" className='w-4/5 mx-auto ' />

                    </article>
                </section>
            </main>
            {/* Why choose merida  */}
            <main className='container mx-auto rounded-xl min-h-[50vh] flex flex-col justify-around 
            roboto text-center p-4 crmbannerbgclr ' >
                <section className=' ' >
                    {/* <h6 className=' text-slate-500 fw-semibold  ' >DOLOR SIT AMET </h6> */}
                    <h4 className=' fw-bold text-3xl sm:text-5xl my-3 ' >
                        Why Choose Merida <br /> for Your CRM? </h4>
                    <p className=' text-xl text-slate-500 my-3 ' >
                        Stay organized and keep every connection close <br /> with a CRM that adapts to your business.
                    </p>
                </section>
                <section className=' flex flex-wrap justify-between container ' >
                    <div className='flex my-3 flex-col items-center w-[20rem] ' >
                        <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                            <SettingIcon />   </span>
                        <p className='fw-bold text-xl my-3 ' >
                            Instant Setup
                        </p>
                        <p className='text-slate-700 w-4/5 ' >
                            Start managing leads right away without any wait.
                        </p>
                    </div>
                    <div className='flex my-3 flex-col items-center w-[20rem] ' >
                        <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                            <LaunchIcon />   </span>
                        <p className='fw-bold text-xl my-3 ' >
                            Organized Dashboard
                        </p>
                        <p className='text-slate-700 w-4/5 ' >
                            All details in one view for smooth access, always.
                        </p>
                    </div>
                    <div className='flex my-3 flex-col items-center w-[20rem] ' >
                        <span style={{ backgroundColor: 'rgb(255,255,255)' }} className='text-blue-500 rounded-full bg-slate-100 w-fit p-2 ' >
                            <LaunchIcon />   </span>
                        <p className='fw-bold text-xl my-3 ' >
                            Smart Follow-ups
                        </p>
                        <p className='text-slate-700 w-4/5 ' >
                            Automatic reminders to keep every lead moving forward.
                        </p>
                    </div>

                </section>

            </main>
            <section className='px-3 flex py-3 ' >
                {/* Yellow Ring */}
                <div className='border-[18px] ms-auto border-yellow-200 rounded-full  w-[5rem] h-[5rem]  ' >

                </div>
            </section>
            {/* Footer */}
            <main className=' my-4 row container crmbannerbgclr min-h-[70vh] justify-around rounded-xl mx-auto p-4' >
                <section className=' col-lg-5 h-[60vh] my-auto flex flex-col justify-center  ' >
                    <div className=' ' >
                        <h6 className='text-3xl fw-semibold sm:text-6xl ' >
                            Get Merida CRM Today
                        </h6>
                        <p className='text-slate-500 my-3 text-xl ' >
                            All Your Leads, Clients & Sales <br /> manage in One Place
                        </p>
                    </div>
                    <button onClick={() => window.open('https://www.meridatechminds.com/contact-us', '_blank')} className='flex w-fit gap-3 my-4 bg-white items-center p-2 px-3 fw-semibold rounded-full ' >
                        Contact us <span className='border-2 rounded-full p-1 border-slate-800 ' >
                            <RightArrow />
                        </span>
                    </button>
                </section>
                <section className='col-lg-7 flex ' >
                    <img src={require('../assesst/crmapp/crmappfooter-min.png')} alt="Crm_Footer"
                        className='my-auto  ' />
                </section>

            </main>

        </div>
    )
}

export default CRMAppPage