import React from 'react'

const VisitingCardLayout = ({ data }) => {
    return (
        <div>
            <main className='row container-fluid p-0 ' >
                <article className='col-lg-6 ' >
                    {data && data.img1 && <section className="my-[10px]  ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img1 ? data.img1 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>}
                    {/* {data && data.img3 && <section className="my-[10px] mt-4 ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img3 ? data.img3 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>} */}
                </article>
                <article className='col-lg-6 mt-5 ' >
                    {data && data.img2 && <section className="my-[10px] ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img2 ? data.img2 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>}
                    {/* {data && data.img4 && <section className="my-[20px] ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img4 ? data.img4 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>} */}
                </article>

            </main>
        </div>
    )
}

export default VisitingCardLayout