import React from 'react'
import ShakeyLines from '../../Icons/ShakeyLines'
import Ecpilise from '../LogoCom/Ecpilise'
import RightArrow from '../../Icons/RightArrow'
import ReadyProject from '../ReadyProject'

const BrandingSection = () => {
    return (
        <>

            <div className='brandsectionbg min-h-[10vh] py-5' >
                <main className='container mx-auto inter text-slate-50 row' >
                    <h3 className='uppercase text-4xl sm:text-6xl fw-bold text-center my-5' >
                        Strong Branding Starts with Purposeful Design
                    </h3>
                    <section className='col-lg-6 my-5 ' >
                        <img src={require('../../assesst/onlinetestMA/onlinetestbranding-min.png')} alt="MockUpImage"
                            className='' loading='lazy' />
                    </section>
                    <section className='col-lg-6 my-5 flex p-3' >
                        <article className='m-auto w-fit ' >
                            <h3 className='uppercase text-4xl sm:text-7xl fw-bold ' >APP Icon </h3>
                            <p className='sm:text-lg w-[22rem] ' >
                                The app icon was designed to be simple and easy to recognize.
                                It reflects the purpose of the app, offering a clean and
                                approachable look for all users.
                            </p>
                        </article>
                    </section>
                    {/* Content */}
                    <section className='col-lg-6 my-5 flex p-3 ' >
                        <article className='m-auto w-fit ' >
                            <h3 className='uppercase text-4xl sm:text-7xl fw-bold ' >Typography </h3>
                            <p className='sm:text-lg my-3 w-[22rem] ' >
                                The typography was carefully chosen to be clear and easy to read,
                                ensuring a smooth experience for both test-takers and admins.
                            </p>
                        </article>
                    </section>
                    <section className='col-lg-6 my-5 flex' >
                        <h2 className='m-auto text-4xl sm:text-9xl fw-bold ' >Aa Aa</h2>
                    </section>
                </main>
            </div>
            {/* Some Awesome features  */}
            <div className='relative  ' >
                <div className='absolute top-0 right-0 z-0 ' >
                    <ShakeyLines />
                </div>
                <main className='container relative mx-auto py-5 ' >
                    <h4 className='text-4xl sm:text-6xl fw-bold text-slate-800 text-center ' >
                        Top Features of Our Online <br /> Test Application                    </h4>
                    <section className='flex flex-wrap gap-4 justify-around my-4 py-5 ' >
                        <div className='w-[20rem] sm:w-[22rem] text-center ' >
                            <img src={require('../../assesst/onlinetestMA/onlinetestfeature1-min.png')} alt="MobileROundedBg"
                                loading='lazy' className=' ' />
                            <p className='text-slate-800  my-3 text-xl fw-semibold ' > User-Friendly Access </p>
                        </div>
                        <div className='w-[20rem] sm:w-[22rem] text-center ' >
                            <img src={require('../../assesst/onlinetestMA/onlinetestfeature2-min.png')} alt="MobileROundedBg"
                                loading='lazy' className=' ' />
                            <p className='text-slate-800  my-3 text-xl fw-semibold ' > Best MCQ Experience </p>
                        </div>
                        <div className='w-[20rem] sm:w-[22rem] text-center ' >
                            <img src={require('../../assesst/onlinetestMA/onlinetestfeature3-min.png')} alt="MobileROundedBg"
                                loading='lazy' className=' ' />
                            <p className='text-slate-800  my-3 text-xl fw-semibold ' >Admin Dashboard </p>
                        </div>

                    </section>
                </main>
            </div>
            {/* Contact */}
            <div>
                <ReadyProject fntclr='text-slate-700' />
            </div>
        </>
    )
}

export default BrandingSection