import React from 'react'
import RightArrow from '../../Icons/RightArrow'
import { useNavigate } from 'react-router'

const OurOtherWorks = () => {
    let navigate = useNavigate()
    let content = [
        {
            heading: 'Graphics Desiging ',
            subHeading: `Creative designs that reflect your brand's identity `,
            slug: 'graphic-designing-company',
        },
        {
            heading: 'Logo Designing ',
            subHeading: 'Create a logo that speaks your brand',
            slug: 'logo-designing-company',

        },
        {
            heading: 'Website Designing',
            subHeading: 'Build websites that deliver great user experiences ',
            slug: 'website-designing-company',

        },
        {
            heading: 'Mobile App Development ',
            subHeading: 'Develop apps that are intuitive and reliable ',
            slug: 'mobile-application-designing-company',
        },
    ]
    return (
        <div className='py-4 bg-slate-100 mt-4 ' >
            <main className='container poppins bg-slate-100 min-h-[80vh] ' >
                <section className=' ms-auto col-lg-10 py-4 ' >
                    <div className='mx-auto w-fit relative ' >
                        <h5 className='text-4xl sm:text-7xl text-slate-700 fw-bold ' >Our Other Works </h5>
                        <h5 className='text-4xl sm:text-7xl absolute top-0 left-1 text-nowrap strktxt  ' >Our Other Works </h5>
                    </div>
                    <p className='text-center w-10/12 mx-auto my-3 '>
                        Creating a logo involves a thoughtful and strategic process to ensure the
                        final design aligns with the brand's identity,
                        values, and goals. Here's a step-by-step guide for the logo design process </p>
                    <section className='row ' >
                        {/* Card 1 */}
                        <article className='rounded col-md-5 p-3 ' >
                            <div className='relative ' >
                                <img src={require('../../assesst/imagecompressor4/logopagegraphicdesign-min.png')} alt="Graphic Design"
                                    className=' rounded-xl h-[30vh] object-cover w-full ' />
                                <div className='absolute bottom-4 text-white flex min-h-[8vh] justify-between
                                px-3 items-center bgblur w-full bg-transparent ' >
                                    <div className=' ' >
                                        <h5>Graphic Design </h5>
                                        <p className='text-xs text-slate-50 ' > Creative Designs That Reflect Your Brand’s Identity</p>
                                    </div>
                                    <button onClick={() => navigate(`/graphic-designing-company`)} className='rounded-full p-2 px-3 bg-white text-slate-800 ' >
                                        <RightArrow />
                                    </button>
                                </div>
                            </div>
                        </article>
                        {/* Card 2 */}
                        <article className='rounded col-md-7 p-3 ' >
                            <div className='relative ' >
                                <img src={require('../../assesst/imagecompressor4/logopagewebapp-min.png')} alt="Graphic Design"
                                    className=' rounded-xl  h-[30vh] object-cover w-full' />
                                <div className='absolute bottom-4 text-white flex min-h-[8vh] justify-between
                                px-3 items-center bgblur w-full bg-transparent ' >
                                    <div className=' ' >
                                        <h5>Web App Development </h5>
                                        <p className='text-xs text-slate-50 ' > Creative Designs That Reflect Your Brand’s Identity</p>
                                    </div>
                                    <button onClick={() => navigate(`/website-designing-company`)} className='rounded-full p-2 px-3 bg-white text-slate-800 ' >
                                        <RightArrow />
                                    </button>
                                </div>
                            </div>
                        </article>
                        {/* Card 3 */}
                        <article className='rounded col-md-7 p-3 ' >
                            <div className='relative ' >
                                <img src={require('../../assesst/imagecompressor4/logopageappdev-min.png')} alt="App Mobile"
                                    className=' rounded-xl  h-[30vh] object-cover w-full' />
                                <div className='absolute bottom-4 text-white flex min-h-[8vh] justify-between
                                px-3 items-center bgblur w-full bg-transparent ' >
                                    <div className=' ' >
                                        <h5>Mobile App Development </h5>
                                        <p className='text-xs text-slate-50 ' > Creative Designs That Reflect Your Brand’s Identity</p>
                                    </div>
                                    <button onClick={() => navigate(`/mobile-application-designing-company`)} className='rounded-full p-2 px-3 bg-white text-slate-800 ' >
                                        <RightArrow />
                                    </button>
                                </div>
                            </div>
                        </article>
                        {/* Card 4 */}
                        <article className='rounded col-md-5 p-3 ' >
                            <div className='relative ' >
                                <img src={require('../../assesst/imagecompressor4/logopagewebsite-min.png')} alt="Website Design"
                                    className=' rounded-xl  h-[30vh] object-cover w-full' />
                                <div className='absolute bottom-4 text-white flex min-h-[8vh] justify-between
                                px-3 items-center bgblur w-full bg-transparent ' >
                                    <div className=' ' >
                                        <h5>Website Development </h5>
                                        <p className='text-xs text-slate-50 ' > Creative Designs That Reflect Your Brand’s Identity</p>
                                    </div>
                                    <button onClick={() => navigate(`/website-designing-company`)} className='rounded-full p-2 px-3 bg-white text-slate-800 ' >
                                        <RightArrow />
                                    </button>
                                </div>
                            </div>
                        </article>
                    </section>
                </section>
            </main>
            {/* New Contact */}
            <main className='min-h-[40vh] bg-white ' >

            </main>

            <main className='w-full min-h-[40vh] bg-slate-100 ' >
                <section className='relative -translate-y-1/2 min-h-[40vh] p-4 col-lg-10 ms-auto ' >
                    <article className='h-full w-full shadow bg-white mx-auto overflow-hidden  rounded-xl row ' >
                        <div className='col-5 col-md-6 col-lg-4 flex flex-col justify-between col-md-4 p-4 pe-0 ' >
                            <h5 className='text-2xl sm:text-5xl poppins fw-bold greytxt' >
                                Let’s Work Together For Your Next Project!
                            </h5>
                            <button onClick={()=>{
                                window.open('https://www.meridatechminds.com/contact-us','_blank')
                            }} className='poppins w-fit my-2 bluegrdbtn flex gap-4 rounded-full px-3 p-sm-2 text-nowrap text-sm p-1 text-slate-50 items-center ' >
                                Let's Talk <span className='rounded-full text-blue-600 bg-white p-2 ' >
                                    <RightArrow />
                                </span>
                            </button>
                        </div>
                        <div className='col-7 col-md-6 col-lg-8 pe-0' >
                            <img src={require('../../assesst/imagecompressor6/logopagecontact-min.png')} alt="LogoContact"
                                className='w-full h-full object-cover object-left d-none d-lg-block ' />
                                 <img src={require('../../assesst/imagecompressor6/mobilelogofooter-min.png')} alt="LogoContact"
                                className='w-full h-full object-cover object-left d-lg-none ' />
                        </div>

                    </article>
                </section>

            </main>
            {/* Contact */}


        </div>
    )
}

export default OurOtherWorks