import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import NavBarCom from '../component/NavBarCom'
import Ecpilise from '../component/LogoCom/Ecpilise'
import GraphicGalary from '../component/GraphicGalary'
import ReadyToColbrate from '../component/ReadyToColbrate'
import axios from 'axios'
import { port } from '../App'
import { Spinner } from 'react-bootstrap'
import { PorfolioStore } from '../context/PortFolioContext'
import GalaryLayoutTwo1 from '../component/GalaryLayouts/GalaryLayoutTwo1'
import BrowcherLayout from '../component/GalaryLayouts/BrowcherLayout'
import PosterLayout from '../component/GalaryLayouts/PosterLayout'
import BannerLayout from '../component/GalaryLayouts/BannerLayout'
import VisitingCardLayout from '../component/GalaryLayouts/VisitingCardLayout'
import MockupsLayouts from '../component/GalaryLayouts/MockupsLayouts'

const GraphicDesigingPage = () => {
    let LazyGalery = lazy(() => import('../component/GraphicGalary'))
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        window.scrollTo(0, 0)
        setActivePage('graphic')
    }, [])
    return (
        <div className=' ' >
            <NavBarCom theme='dark' />
            <main className='ms-auto col-lg-10 ' >
                <div className='relative my-5' >
                    <div className=' h-fit w-fit absolute top-1/2 translate-x-1/2 -translate-y-1/2 ' >
                        <Ecpilise w='200' h='150' />
                    </div>
                    <h4 className='uppercase text-4xl relative sm:text-6xl fw-bold inter ' > Works </h4>
                </div>
                <p className='text-slate-400 my-2 ' >Tabs </p>
                {/* <GalaryLayoutTwo1/> */}
                {/* <VisitingCardLayout/> */}
                {/* <MockupsLayouts/> */}
                <hr className='opacity-50 ' />
                <Suspense fallback={
                    <div className='min-h-[60vh] flex ' >
                        <Spinner animation='border' className='m-auto ' />
                    </div>
                } >
                    <LazyGalery />
                </Suspense>
            </main>
            <ReadyToColbrate />
        </div>
    )
}

export default GraphicDesigingPage