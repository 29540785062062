import React from 'react'

const TabIcon = () => {
    return (
        <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_7282)">
                <path d="M33.8985 4.99609H5.64864C3.92226 4.99609 2.50977 6.40859 2.50977 8.13496V30.1071C2.50977 31.8334 3.92226 33.2459 5.64864 33.2459H33.8985C35.6248 33.2459 37.0373 31.8334 37.0373 30.1071V8.13496C37.0373 6.40859 35.6248 4.99609 33.8985 4.99609ZM33.8985 30.1071H5.64864V8.13496H21.343V14.4127H33.8985V30.1071Z"
                    fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_1_7282">
                    <rect width="37.6665" height="37.6665" fill="white" transform="translate(0.939453 0.289062)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TabIcon