import React from 'react'

const MockupsLayouts = ({ data }) => {
    return (
        <div>
            <main className='row container-fluid p-0 ' >
                <section className="my-[20px] col-lg-6 h-[40vh] ">
                    <div className='rounded h-full w-full bg-slate-200 ' >
                        <img src={data && data.img1 ? data.img1 : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full shadow h-[40vh] object-cover'
                            loading='lazy' />
                    </div>
                </section>
                <section className="my-[20px] col-lg-3 h-[40vh] ">
                    <div className='rounded h-full w-full bg-slate-200 ' >
                        <img src={data && data.img2 ? data.img2 : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full shadow h-[40vh] object-cover'
                            loading='lazy' />
                    </div>
                </section>
                <section className="my-[20px] col-lg-3 h-[40vh]">
                    <div className='rounded h-full w-full bg-slate-200 ' >
                        <img src={data && data.img3 ? data.img3 : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full shadow h-[40vh] object-cover'
                            loading='lazy' />
                    </div>
                </section>
                <section className="my-[20px] col-lg-3 ">
                    <div className='rounded h-full w-full bg-slate-200 ' >
                        <img src={data && data.img4 ? data.img4 : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full shadow h-[40vh] object-cover'
                            loading='lazy' />
                    </div>
                </section>
                <section className="my-[20px] col-lg-3 ">
                    <div className='rounded h-full w-full bg-slate-200 ' >
                        <img src={data && data.img5 ? data.img5 : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full shadow h-[40vh] object-cover'
                            loading='lazy' />
                    </div>
                </section>
                <section className="my-[20px] col-lg-6 h-[40vh] ">
                    <div className='rounded h-full w-full bg-slate-200 ' >
                        <img src={data && data.image ? data.image : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full shadow h-[40vh] object-cover'
                            loading='lazy' />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default MockupsLayouts