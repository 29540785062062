import React from 'react'
import MixedBoxIcon from '../../Icons/MixedBoxIcon'
import ShakeyLines from '../../Icons/ShakeyLines'

const StudyFlow = () => {
    return (
        <div className='relative overflow-hidden ' >
            <div className=' -translate-x-1/2 
                    w-[12rem] h-[12rem]  sm:w-[16rem] sm:h-[16rem] border-[2rem] 
                    sm:border-[4.5rem] text-slate-50 rounded-full top-4 border-blue-300 border-opacity-20 ' >

            </div>

            <div className='absolute top-5 right-2 ' >
                <MixedBoxIcon />
            </div>
            <div className='absolute top-0 right-2 -z-0 ' >
                <ShakeyLines />
            </div>
            <main className='container py-5 mx-auto inter row ' >
                <h2 className='text-5xl sm:text-6xl mb-4 text-center text-slate-800 fw-bold'>
                    How Our Application Works for <br /> Test-Takers and Admins
                </h2>
                <section className='col-lg-6 my-3 ' >
                    <img src={require('../../assesst/onlinetestMA/onlinecontent1-min.png')} alt="Mobile_App"
                        className='w-full ' />
                </section>
                <section className='col-lg-6 my-3 flex ' >
                    <article className='my-auto ' >

                        <h4 className=' text-4xl sm:text-5xl fw-semibold' > Start the Test With Just a Click </h4>
                        <p className='my-3  text-slate-700 text-lg ' >
                            Candidates can access and begin their exam quickly, with no complicated setup. Admins configure the tests in a simple manner,
                            making the entire process easy to manage for both sides.
                        </p>
                        <button className='bg-blue-600 rounded text-slate-50 p-2 ' >
                            Learn More
                        </button>
                    </article>
                </section>
                <section className='col-lg-6 my-3 flex' >
                    <article className='my-auto ' >

                        <h4 className=' text-4xl sm:text-5xl fw-semibold' >
                            Clear Panel for Admin to Manage Tests
                        </h4>
                        <p className='my-3  text-slate-700 text-lg ' >
                            Our app supports various test formats, from multiple-choice questions to practical exams. Candidates can take tests based on their skills,
                            while admins can quickly grade and assess the results.
                        </p>
                        <button className='text-slate-700 my-3 fw-semibold rounded border-[1.8px]  border-slate-600 p-3 px-4 ' >
                            Learn More
                        </button>
                    </article>
                </section>
                <section className='col-lg-6 my-3 flex ' >
                    <img src={require('../../assesst/onlinetestMA/onlinetestcontent2-min.png')} alt="Mobile_App"
                        className='w-full ' />

                </section>
                <section className='col-lg-6 my-3 ' >
                    <img src={require('../../assesst/onlinetestMA/onlinetestcontent3-min.png')} alt="Mobile_App"
                        className='w-full ' />
                </section>
                <section className='col-lg-6 my-3 flex ' >
                    <article className='my-auto ' >

                        <h4 className=' text-4xl sm:text-5xl fw-semibold' > Instant Results and Detailed Feedback for All </h4>
                        <p className='my-3  text-slate-700 text-lg ' >
                            As soon as the exam is finished, candidates get their results right away. Admins can instantly assess and review the performance,
                            providing candidates with the feedback they need to improve or prepare for the next step.

                        </p>
                        <button className='text-slate-700 my-3 fw-semibold rounded border-[1.8px]  border-slate-600 p-3 px-4 ' >
                            Learn More
                        </button>
                    </article>
                </section>
            </main>
            <div className=' translate-x-1/2  translate-y-1/2 
                    w-[12rem] h-[12rem]  sm:w-[22rem] sm:h-[22rem] border-[3rem] sm:border-[6rem] 
                     text-slate-50 absolute bottom-0 right-0 rounded-full border-blue-300 border-opacity-20 ' >

            </div>
        </div>
    )
}

export default StudyFlow