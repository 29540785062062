import React, { useState } from 'react'
import RightArrow from '../../Icons/RightArrow'
import { useNavigate } from 'react-router'
import { websiteData } from '../../context/Data'

const CardWiseProject = () => {
    let [selectedINdex, setSelectedIndex] = useState(0)
    let navigate = useNavigate()
    return (
        <div className=' bg-slate-100 px-0 mx-auto py-5 ' >
            {/* Mobile */}
            {websiteData.map((obj, index) => (
                <div className={` ${index % 2 == 0 ? ' bg-white ' : 'bg-white '} w-[90%] mx-auto rounded d-sm-none sticky top-20 `} >
                    <main className={` col-lg-10 flex flex-col  ms-auto py-4 p-3 my-3 `} >
                        <img onClick={() => navigate(`/website-designing-company/${obj.slug}`)}
                            src={obj.img1 ? obj.img1 : require('../../assesst/webProject.webp')} alt="WebSite Project"
                            className={` sm:h-[75vh] my-4 cursor-pointer hover:scale-[1.01] duration-500  w-full object-cover rounded-xl `} />
                        <div className=' ' >
                            <h5 className='fw-semibold text-blue-600 text-2xl ' >{obj.website ? obj.website : "Sportly"} </h5>
                            <p className='w-[90%] my-3 ' >
                                {obj.content ? obj.content : " Website Redesign: Improving user experience and modernizing design."}
                            </p>
                        </div>

                    </main>
                </div>
            ))}
            {/* Desktop */}
            {websiteData.map((obj, index) => (
                <div className={` ${index % 2 == 0 ? ' sm:bg-slate-100 ' : 'bg-white '} d-none d-sm-block sticky top-20 `} >
                    <main className={` col-lg-10 flex flex-col  ms-auto py-4 p-3 my-3 `} >
                        <div className=' ' >
                            <h5 className='fw-semibold text-3xl text-center' >{obj.website ? obj.website : "Sportly"} </h5>
                            <p className='w-[70%] my-3 text-center mx-auto ' >
                                {obj.content ? obj.content : " Website Redesign: Improving user experience and modernizing design."}
                            </p>
                        </div>
                        <img onClick={() => navigate(`/website-designing-company/${obj.slug}`)}
                            src={obj.img1 ? obj.img1 : require('../../assesst/webProject.webp')} alt="WebSite Project"
                            className={` sm:h-[75vh] my-4 cursor-pointer hover:scale-[1.01] duration-500  w-full object-cover rounded-xl `} />
                    </main>
                </div>
            ))}


        </div>
    )
}

export default CardWiseProject