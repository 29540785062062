import React from 'react'

const Share2Icon = ({ size }) => {
    return (
        <svg width={size ? size : "40"} height={size ? size : "40"} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.3333 26.6684L21.6667 20.0017V14.7017C23.9167 13.885 25.4333 11.5517 24.8833 8.93502C24.4667 6.96835 22.8333 5.40169 20.85 5.06835C17.7167 4.55169 15 6.95169 15 10.0017C15 12.1684 16.4 14.0017 18.3333 14.7017V20.0017L11.6667 26.6684H6.66667C5.75 26.6684 5 27.4184 5 28.335V33.335C5 34.2517 5.75 35.0017 6.66667 35.0017H11.6667C12.5833 35.0017 13.3333 34.2517 13.3333 33.335V29.9184L20 22.9184L26.6667 29.9184V33.335C26.6667 34.2517 27.4167 35.0017 28.3333 35.0017H33.3333C34.25 35.0017 35 34.2517 35 33.335V28.335C35 27.4184 34.25 26.6684 33.3333 26.6684H28.3333Z"
                fill="currentColor" />
        </svg>
    )
}

export default Share2Icon