import React from 'react'

const LaunchIcon = () => {
    return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_7268)">
                <path d="M30.7575 30.7867H8.78536V8.81465H19.7714V5.67578H8.78536C7.04328 5.67578 5.64648 7.08827 5.64648 8.81465V30.7867C5.64648 32.5131 7.04328 33.9256 8.78536 33.9256H30.7575C32.4838 33.9256 33.8963 32.5131 33.8963 30.7867V19.8007H30.7575V30.7867ZM22.9103 5.67578V8.81465H28.5445L13.117 24.2422L15.3299 26.4551L30.7575 11.0276V16.6618H33.8963V5.67578H22.9103Z"
                    fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_1_7268">
                    <rect width="37.6665" height="37.6665" fill="white" transform="translate(0.939453 0.966797)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default LaunchIcon