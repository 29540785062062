import React from 'react'

const MouseIcon = ({ size }) => {
    return (
        <svg width="33" height={size ? size : "50"} viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="29.967" height="47" rx="14.9835" stroke="black" stroke-width="3" />
            <path d="M16.1367 9.23145L16.1367 24.6161" stroke="#F07115" stroke-width="4" stroke-linecap="round" />
        </svg>
    )
}

export default MouseIcon