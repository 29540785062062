import React from 'react'

const TrochIcon = ({ size }) => {
    return (
        <svg width={size ? size : "32"} height={size ? size : "32"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0081 12.667H12.0081C11.2748 12.667 10.6748 13.267 10.6748 14.0003V30.0003C10.6748 30.7337 11.2748 31.3337 12.0081 31.3337H20.0081C20.7415 31.3337 21.3415 30.7337 21.3415 30.0003V14.0003C21.3415 13.267 20.7415 12.667 20.0081 12.667ZM16.0081 19.667C15.0881 19.667 14.3415 18.9203 14.3415 18.0003C14.3415 17.0803 15.0881 16.3337 16.0081 16.3337C16.9281 16.3337 17.6748 17.0803 17.6748 18.0003C17.6748 18.9203 16.9281 19.667 16.0081 19.667Z" fill="currentColor" />
            <path d="M10.4348 9.76033C10.9015 10.227 11.6348 10.267 12.1681 9.89366C13.2481 9.12033 14.5815 8.66699 16.0081 8.66699C17.4348 8.66699 18.7681 9.12033 19.8481 9.88033C20.3815 10.2537 21.1148 10.227 21.5815 9.76033C22.1548 9.18699 22.1015 8.22699 21.4615 7.76033C19.9281 6.65366 18.0481 6.00033 16.0081 6.00033C13.9681 6.00033 12.0881 6.65366 10.5548 7.76033C9.90147 8.22699 9.86147 9.18699 10.4348 9.76033Z" fill="currentColor" />
            <path d="M16.0081 0.666992C12.4881 0.666992 9.26147 1.90699 6.74147 3.96033C6.12814 4.46699 6.0748 5.38699 6.6348 5.94699C7.1148 6.42699 7.8748 6.46699 8.3948 6.04033C10.4615 4.36033 13.1148 3.33366 16.0081 3.33366C18.9015 3.33366 21.5548 4.36033 23.6081 6.05366C24.1281 6.48033 24.8881 6.44033 25.3681 5.96033C25.9281 5.40033 25.8748 4.48033 25.2615 3.97366C22.7548 1.90699 19.5281 0.666992 16.0081 0.666992Z" fill="currentColor" />
        </svg>
    )
}

export default TrochIcon