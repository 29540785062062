import React from 'react'

const MixedBoxIcon = ({ size }) => {
    return (
        <svg width={size ? size : "227"} height={size ? size : "163"} viewBox="0 0 227 163" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 60H130.5V1H26.5V94H180V49H40V162.5H145V76H249.5" stroke="#000F37" />
        </svg>

    )
}

export default MixedBoxIcon