import React from 'react'
import NavBarCom from '../component/NavBarCom'

const HomePage = () => {
    return (
        <div>
            <main className='min-h-[100vh] relative ' >
                <video autoPlay muted src={require('../assesst/portfolioMockUp.mp4')}
                    loop alt="MockUpVideo"
                    className='h-[100vh] d-none d-sm-block w-full object-cover ' />
                <video autoPlay muted src={require('../assesst/portfolioMockUpMobile.mp4')}
                    loop alt="MockUpVideo"
                    className='h-[100vh] d-sm-none w-full object-cover ' />
                <div className='absolute top-0 w-full ' >
                    <NavBarCom theme="dark" />
                </div>
            </main>
        </div>
    )
}

export default HomePage