import React, { useContext, useEffect } from 'react'
import NavBarCom from '../component/NavBarCom'
import PieCircleImg from '../Icons/PieCircleImg'
import WebAppCard from '../component/WebAppCard'
import { webappData } from '../context/Data'
import { PorfolioStore } from '../context/PortFolioContext'
import OcircleIcon from '../Icons/OcircleIcon'

const WebAppPage = () => {
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        window.scrollTo(0,0)
        setActivePage('webapp')
    }, [])
    return (
        <div className=' ' >
            <main className='bg-black min-h-[90vh]  relative prosto' >
                <div className=' z-10 sticky top-0 ' >

                    <NavBarCom logo='white' theme='bg-black w-full ' burger />
                </div>
                <div className='col-lg-10 ms-auto ' >

                    <main className='row container mx-auto min-h-[90vh] ' >
                        <section className='col-lg-6 order-2 order-lg-0 relative  flex ' >

                            <article className='m-auto relative poppins ' >

                                {/* <span className='absolute right-[28%] -top-10 z-0  ' >
                                    <PieCircleImg w={130} />
                                </span> */}
                                <h5 className=' mx-auto mx-lg-0 z-0 poppins fw-bold relative
                                 text-slate-50  text-4xl sm:text-7xl lg:text-[5rem] w-fit ' >
                                    Web App <br />

                                </h5>
                                <span className='flex d-none d-sm-flex items-center my-1 gap-[1px] text-slate-50  text-4xl sm:text-7xl lg:text-[5rem] fw-bold' >
                                    Devel <OcircleIcon w={75} h={65} />pment
                                </span>
                                <span className='flex d-sm-none mx-auto text-center w-fit  items-center my-1 gap-[1px] text-slate-50  text-4xl sm:text-7xl lg:text-[5rem] fw-bold' >
                                    Devel <OcircleIcon w={45} h={35} />pment
                                </span>
                                <p className='poppins text-center d-lg-none mx-auto text-slate-200 fw-normal my-4 w-[400px] ' >
                                    A new way to make payments easy, reliable and secure.
                                    You can manage all your transactions from your mobile phone.
                                </p>
                                <p className='poppins d-none d-lg-block text-slate-200 fw-normal my-4 w-[400px] ' >
                                    A new way to make payments easy, reliable and secure.
                                    You can manage all your transactions from your mobile phone.
                                </p>
                            </article>
                        </section>
                        <section className='col-lg-6 ' >
                            <img src={require('../assesst/webappPagebanner.png')} alt="WebappBanner"
                                className='lg:absolute w-[100%] sm:w-[70%] lg:w-[37%] ms-auto top-20 sm:top-0 z-10 right-0 ' />
                        </section>
                    </main>
                </div>
                {/* Cards */}
                {
                    webappData.map((obj, index) => (
                        <WebAppCard data={obj} />
                    ))
                }
                <p className=' h-[20vh] pb-0 m-0 ' ></p>

            </main>

        </div>
    )
}

export default WebAppPage