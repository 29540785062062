import React, { useContext, useEffect, useState } from 'react'
import NavBarCom from '../component/NavBarCom'
import ReadyProject from '../component/ReadyProject'
import GalaryLayoutTwo1 from '../component/GalaryLayouts/GalaryLayoutTwo1'
import { useNavigate, useParams } from 'react-router'
import { websiteData } from '../context/Data'
import OtherProjectsWeb from '../component/GalaryLayouts/OtherProjectsWeb'
import { PorfolioStore } from '../context/PortFolioContext'

const BloomIndoorPage = () => {
    let { slug } = useParams()
    let navigate = useNavigate()
    let [data, setData] = useState()
    let getData = () => {
        if (slug) {
            let getvalue = websiteData.find((obj, index) => obj.slug == slug)
            setData(getvalue)
            console.log(getvalue);
        }
    }
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('web')
    }, [])
    useEffect(() => {
        // window.scrollTo(0, 0)
        getData()
    }, [slug])
    return (
        <div>
            <div className='sticky top-0 ' >
                <NavBarCom />
            </div>
            <main
                className={` ${data && data.clr} text-slate-50 text-center pb-4 `} >

                <h4 className='caveat fw-normal  text-3xl sm:text-6xl' >
                    {data && data.heading ? data.heading : " Indoor Plants live easy"}
                </h4>
                <h3 className='orbitron fw-bold text-3xl sm:text-7xl ' >
                    {data && data.website ? data.website : " Bloom Indoor "}</h3>
            </main>
            <main className='container mx-auto ' >

                <p className='w-4/5 mx-auto text-xl text-center my-4 poppins ' >
                    {data && data.content ? data.content : ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nulla maxime impedit nostrum atque
                    odio! Natus, illum cupiditate commodi saepe a neque, molestiae nulla quo illo sequi et itaque aspernatur?`}
                </p>
            </main>
            {
                data ? data.theme == 'theme1' ? <>

                    {/* Layout 1 */}
                    <main className='row container mx-auto my-4 ' >
                        <section className='col-lg-5 ' >
                            <img src={data && data.img1 ? data.img1 : require('../assesst/Merida_Logo_page_Billboard.webp')} alt="LayoutImage1"
                                loading='lazy' className='rounded-xl h-[70vh] object-cover   ' />
                        </section>
                        <section className='col-lg-7 ' >
                            <img src={data && data.img2 ? data.img2 : require('../assesst/Merida_Logo_page_Billboard.webp')} alt="LayoutImage1"
                                loading='lazy' className='rounded-xl h-[80vh] object-cover   ' />
                        </section>
                    </main>
                    {/* Layout 2 */}
                    <main className='row container mx-auto my-4 ' >
                        <section className='col-lg-5 ' >
                            <img src={data && data.img3 ? data.img3 : require('../assesst/Merida_Logo_page_Billboard.webp')} alt="LayoutImage1"
                                loading='lazy' className='rounded-xl h-[70vh] object-cover   ' />
                        </section>
                        <section className='col-lg-7 flex' >
                            <img src={data && data.img4 ? data.img4 : require('../assesst/Merida_Logo_page_Billboard.webp')} alt="LayoutImage1"
                                loading='lazy' className='rounded-xl h-[50vh] w-full my-auto object-cover   ' />
                        </section>
                    </main>
                    {/* Layout 3 */}
                    <main className='row container mx-auto my-4 ' >
                        <section className='col-lg-5 flex ' >
                            <img src={data && data.img5 ? data.img5 : require('../assesst/Merida_Logo_page_Billboard.webp')} alt="LayoutImage1"
                                loading='lazy' className='rounded-xl my-auto h-[80vh] object-cover   ' />
                        </section>
                        <section className='col-lg-7 ' >
                            <img src={data && data.img6 ? data.img6 : require('../assesst/Merida_Logo_page_Billboard.webp')} alt="LayoutImage1"
                                loading='lazy' className='rounded-xl h-[100vh] object-cover   ' />
                        </section>
                    </main>

                </> : <OtherProjectsWeb data={data} /> : ''
            }
            {/* Other  */}
            {/* <button className={` ${data && data.clr} mx-auto p-3 rounded px-4 text-slate-50 text-xl flex my-3 `} >
                Visit Now
            </button> */}
            {/* <p className='container mx-auto px-3 text-center ' >
                {data && data.content2 ? data.content2 : ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit saepe ad libero iusto.
                Sunt obcaecati et esse nesciunt ratione at debitis odio, atque voluptatum tempora aperiam! Id ut vero, accusamus,
                fuga sequi maxime tempora aliquid ab, accusantium sapiente eaque excepturi?`}
            </p> */}
            {/* Other cards */}
            <main className='row container mx-auto m-0 p-0 text-center my-3' >
                <h4 className=' sans my-4 text-2xl fw-semibold' >Other projects  </h4>
                {/* Card 1 */}
                {
                    data && data.others.map((obj, index) => (


                        <section className='col-sm-6 ' >
                            <article className=' ' >
                                <div className={` ${index % 2 == 0 ? 'bg-violet-300' : "bg-pink-300 "} bg-opacity-80 rounded-[30px] overflow-hidden `} >
                                    <img src={obj.img ? obj.img : require('../assesst/laptoplayout.webp')}
                                        className=' h-[40vh] my-4 object-contain flex ms-auto ' alt="LaptopImage" />
                                </div>
                                <h5 className=' my-3 cursor-pointer ' onClick={() => navigate(`/website-designing-company${obj.slug}`)} > {obj.website} </h5>
                            </article>
                        </section>))
                }
            </main>
                <ReadyProject color={data && data.clr}
                    ecpclr='text-slate-100' />
        </div >
    )
}

export default BloomIndoorPage