import React, { useContext, useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { PorfolioStore } from '../context/PortFolioContext'
import LogoDesignNavIcon from '../Icons/LogoDesignNavIcon'
import WebAppNavIcon from '../Icons/WebAppNavIcon'
import AppNavIcon from '../Icons/AppNavIcon'
import WebsiteNavIcon from '../Icons/WebsiteNavIcon'
import GraphicDesignIcon from '../Icons/GraphicDesignIcon'
import BurgerIcon from '../Icons/BurgerIcon'

const NavBarCom = ({ theme, burger, logo }) => {
    let { activePage } = useContext(PorfolioStore)
    let expand = 'lg'
    let [openSide, setOpenSide] = useState(false)
    let [hover, setHover] = useState()
    let navigate = useNavigate()
    let navbuttons = [
        {
            label: 'Logo Designing',
            path: '/logo-designing-company',
            active: 'logo',
            logo: <LogoDesignNavIcon size={26} />
        },
        {
            label: 'Web App Development',
            path: '/web-application-development',
            active: 'webapp',
            logo: <WebAppNavIcon size={26} />
        },
        {
            label: 'Mobile App Development',
            path: '/mobile-application-designing-company',
            active: 'mobile',
            logo: <AppNavIcon size={26} />
        },
        {
            label: 'Website Development',
            path: '/website-designing-company',
            active: 'web',
            logo: <WebsiteNavIcon size={26} />
        },
        {
            label: 'Graphic Design',
            path: '/graphic-designing-company',
            active: 'graphic',
            logo: <GraphicDesignIcon size={26} />
        },
    ]

    return (
        <div className={`w-full poppins relative z-20 ${theme ? theme : "bg-white"} `} >
            {/* Side bar */}
            <main className='fixed top-1/2 d-none d-lg-block min-h-[40vh] z-100 w-[180px] -translate-y-1/2 ' >
                {/* button 1*/}
                {
                    navbuttons.map((obj, index) => (
                        <div onMouseEnter={() => { setHover(index) }}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => { navigate(`${obj.path}`) }}

                            className={` relative pe-2 my-3 cursor-pointer text-sm `} >
                            {/* BOrder one */}
                            <span className={` h-full w-full border-y-2 border-e-2 border-s-0 rounded-e-lg absolute 
                                duration-500 ${hover == index ? "orgbr " : activePage == obj.active ? 'activebr ' : theme ? 'greybr ' : 'border-slate-950'}
                                 top-2 `} >
                            </span>
                            {/* Content one */}
                            <div className={`b-2 rounded-e-lg relative gap-3  text-slate-50 p-2 duration-500 
                           ${hover == index ? "orgbg  fw-semibold " : activePage == obj.active ? 'activebg' : theme ? 'greybg ' : 'bg-slate-950 '}
                          flex items-center justify-around `} >

                                {obj.logo}
                                <span className='w-[60%] cursor-pointer ' >
                                    {obj.label}
                                </span>

                            </div>
                        </div>
                    ))
                }
            </main>

            <Offcanvas placement='end' show={openSide}
                className=' bg-transparent w-full '
                onHide={() => setOpenSide(false)}>
                <Offcanvas.Body className='p-0  ' >
                    <main className=' min-h-[40vh] h-full bg-slate-950 bg-opacity-60
                     m-0 p-0 flex flex-col items-end  z-20 w-full ' >
                        {/* button 1*/}
                        {
                            navbuttons.map((obj, index) => (
                                <div onMouseEnter={() => { setHover(index) }}
                                    onMouseLeave={() => setHover(-1)}
                                    onClick={() => { navigate(`${obj.path}`) }}

                                    className={` relative w-[220px] my-3 cursor-pointer text-sm `} >
                                    {/* BOrder one */}
                                    <span className={`ms-1 h-full w-full border-y-2 border-e-0 border-s-2 rounded-s-lg rounded-e-0 absolute 
                                duration-500 ${hover == index ? "orgbr " : activePage == obj.active ? 'activebr ' : theme ? 'greybr ' : 'border-slate-950'}
                                 top-2 `} >
                                    </span>
                                    {/* Content one */}
                                    <div className={`b-2 rounded-s-lg relative gap-3 text-slate-50 p-2 duration-500 
                         ${hover == index ? "orgbg  fw-semibold " : activePage == obj.active ? 'activebg' : theme ? 'greybg ' : 'bg-slate-950 '}
                          flex items-center justify-center `} >

                                        {obj.logo}
                                        <span className='w-[40%] cursor-pointer ' >
                                            {obj.label}
                                        </span>

                                    </div>
                                </div>
                            ))
                        }
                    </main>
                </Offcanvas.Body>

            </Offcanvas>


            <Navbar key={expand} expand={expand} className="px-4 z-10 w-full mb-3">
                <Container fluid>
                    <Navbar.Brand
                        href="/">
                        <img src={logo ? require('../assesst/merida_logo_white_1.webp') :
                            require('../assesst/Merida_Tech_Minds_logo.png')} loading='lazy'
                            className='cursor-pointer w-[120px] sm:w-[150px]  ' alt="MeridaLogo" />
                    </Navbar.Brand>
                    <button onClick={() => setOpenSide(true)} className={` ${burger ? 'text-slate-50' : 'text-slate-900'} d-lg-none `} >
                        <BurgerIcon />
                    </button>

                </Container>
            </Navbar>
        </div>
    )
}

export default NavBarCom