import React from 'react'

const TouchIcon = () => {
    return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_7289)">
                <path d="M14.5783 18.5786V12.7089C14.5783 10.5431 16.3361 8.78536 18.5019 8.78536C20.6677 8.78536 22.4255 10.5431 22.4255 12.7089V18.5786C24.3245 17.3074 25.5644 15.1573 25.5644 12.7089C25.5644 8.80105 22.4098 5.64648 18.5019 5.64648C14.594 5.64648 11.4394 8.80105 11.4394 12.7089C11.4394 15.1573 12.6793 17.3074 14.5783 18.5786ZM30.0215 25.8451L22.8963 22.2982C22.6295 22.1883 22.347 22.1256 22.0488 22.1256H20.856V12.7089C20.856 11.4063 19.8045 10.3548 18.5019 10.3548C17.1993 10.3548 16.1477 11.4063 16.1477 12.7089V29.5647C10.4978 28.3719 10.5919 28.3876 10.3879 28.3876C9.90139 28.3876 9.46194 28.5916 9.14806 28.9055L7.9082 30.1611L15.6612 37.9141C16.085 38.3378 16.6813 38.6046 17.3248 38.6046H27.9813C29.1584 38.6046 30.0686 37.7414 30.2413 36.5958L31.4183 28.3248C31.434 28.215 31.4497 28.1051 31.4497 28.0109C31.4497 27.0379 30.8533 26.1904 30.0215 25.8451Z" 
                fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_1_7289">
                    <rect width="37.6665" height="37.6665" fill="white" transform="translate(0.451172 0.9375)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TouchIcon