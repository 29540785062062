import React from 'react'

const DoubleArrow = ({ size }) => {
    return (
        <svg width={size ? size : "18"} height={size ? size : "18"} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.21286 0.878906L12.8172 8.94089L5.21286 17.0029H9.88803L17.4924 8.94089L9.88803 0.878906H5.21286Z" fill="currentColor" />
            <path d="M0.230469 3.95015L4.94053 8.9409L0.230469 13.9317H3.71981L8.42987 8.9409L3.71981 3.95015H0.230469Z" fill="currentColor" />
        </svg>

    )
}

export default DoubleArrow