import React from 'react'

const BannerLayout = ({data}) => {
    return (
        <div>
            <main className='row container-fluid p-0 my-3 ' >
                <section className="col-lg-6 ">
                    <div className='h-[30vh] my-1 mb-3 w-full bg-slate-200 rounded ' >
                    <img src={data && data.img1 ? data.img1 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[30vh] object-cover shadow'
                                loading='lazy' />
                    </div>
                    <div className='h-[30vh] my-1 mt-3 w-full bg-slate-200 rounded ' >
                    <img src={data && data.img2 ? data.img2 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[30vh] object-cover shadow'
                                loading='lazy' />
                    </div>
                    <div className='h-[30vh] my-1 mt-3 w-full bg-slate-200 rounded ' >
                    <img src={data && data.img3 ? data.img3 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[30vh] object-cover shadow'
                                loading='lazy' />
                    </div>
                </section>
                <section className="col-lg-6">
                    <div className='h-[46vh] my-1 mb-3 w-full bg-slate-200 rounded ' >
                    <img src={data && data.img4 ? data.img4 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[46vh] object-cover shadow'
                                loading='lazy' />
                    </div>
                    <div className='h-[46vh] my-1 mt-3 w-full bg-slate-200 rounded ' >
                    <img src={data && data.img5 ? data.img5 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[46vh] object-cover shadow'
                                loading='lazy' />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default BannerLayout