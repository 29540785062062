import React from 'react'

const OtherProjectsWeb = ({ data }) => {
    return (
        <div>
            {/* theme 2 */}
            {
                data && data.theme == 'theme2' && <>
                    <main className='container mx-auto row ' >
                        <section className='col-12 my-3 ' >
                            <img src={data && data.img1 ? data.img1 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] rounded-xl object-cover' />
                        </section>
                        <section className='col-lg-6 my-3 ' >
                            <img src={data && data.img2 ? data.img2 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] rounded-xl object-cover' />
                        </section>
                        <section className='col-lg-6 my-3 ' >
                            <img src={data && data.img3 ? data.img3 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] rounded-xl object-cover' />
                        </section>
                    </main>
                    <main className='container mx-auto row ' >
                        <section className=' col-lg-6 my-3' >
                            <img src={data && data.img4 ? data.img4 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[90vh] my-3 rounded-xl object-cover' />
                            <img src={data && data.img5 ? data.img5 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] my-3 rounded-xl object-cover' />

                        </section>
                        <section className=' col-lg-6 my-3' >
                            <img src={data && data.img6 ? data.img6 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[60vh] my-3 rounded-xl object-cover' />
                            <img src={data && data.img7 ? data.img7 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[80vh] my-3 rounded-xl object-cover' />
                        </section>
                    </main>

                </>
            }
            {/* theme 3 */}
            {
                data && data.theme == 'theme3' && <>
                    <main className='container mx-auto row ' >
                        <section className='col-12 my-3 ' >
                            <img src={data && data.img1 ? data.img1 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[120vh] rounded-xl object-cover' />
                        </section>
                    </main>
                    <main className='container mx-auto row ' >

                        <section className='col-lg-6 my-3 ' >
                            <img src={data && data.img2 ? data.img2 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] rounded-xl object-cover' />
                        </section>
                        <section className='col-lg-6 my-3 ' >
                            <img src={data && data.img3 ? data.img3 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] rounded-xl object-cover' />
                        </section>
                        <section className='col-12 my-3 ' >
                            <img src={data && data.img1 ? data.img1 : require('../../assesst/Maskimg.webp')} alt="Image"
                                className='w-full h-[50vh] rounded-xl object-cover' />
                        </section>
                    </main>
                </>
            }


        </div>
    )
}

export default OtherProjectsWeb