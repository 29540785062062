import React from 'react'

const RightUpVioletCurve = ({ w, h }) => {
    return (
        <svg width={w ? w : "747"} height={h ? h : "1001"} viewBox="0 0 747 1001" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_150_1082)">
                <path d="M1387.34 -264.02C1625.71 40.0193 1569.98 482.274 1262.06 723.688C954.137 965.102 511.361 913.677 272.992 609.637C34.6232 305.598 90.3509 -136.658 398.275 -378.071C706.198 -619.485 1148.97 -568.06 1387.34 -264.02Z" stroke="#7DA1D6" stroke-width="50" />
            </g>
            <defs>
                <filter id="filter0_f_150_1082" x="0.167969" y="-655.276" width="1660" height="1656.17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_150_1082" />
                </filter>
            </defs>
        </svg>

    )
}

export default RightUpVioletCurve