import React from 'react'

const BurgerIcon = () => {
    return (
        <svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L31 2" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
            <path d="M11 10H31" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
            <path d="M18 18L31 18" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
        </svg>

    )
}

export default BurgerIcon