import React, { useContext, useEffect, useState } from 'react'
import NavBarCom from '../component/NavBarCom'
import PosterCard from '../component/PosterCard'
import { useParams } from 'react-router'
import { logoData } from '../context/Data'
import { PorfolioStore } from '../context/PortFolioContext'

const MeridaLogoPage = () => {
    let { slug } = useParams()
    let [data, setData] = useState()
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('logo')
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
        if (slug) {
            let getData = logoData.find((obj) => obj.slug == slug)
            setData(getData)
        }
    }, [slug])
    return (
        <div className=' overflow-hidden ' >
            {/* Banner */}
            <main className={`min-h-[100vh] ${data && data.bannerbg ? data.bannerbg : "meridabannerbg"} relative flex `} >
                <div className='absolute top-0 w-full ' >
                    {data && data.navbar && data.navbar == 'dark' ?
                        <NavBarCom theme='dark' /> : < NavBarCom logo theme />}
                </div>
                <div className={` ${data && data.navbar && data.navbar == 'dark' ? 'border-slate-800' : ""} border-2 rounded-2xl sm:w-1/2 p-4 min-h-[50vh] flex m-auto `} >
                    <img src={data && data.mainBanner ? data.mainBanner :
                        require('../assesst/Merida_White2.png')} alt="Merida Logo"
                        className='mx-auto w-4/5 m-auto' />
                </div>
            </main>
            {/* align */}
            <main className=' ms-auto  ' >

                {/* linements section */}
                <main className='min-h-[80vh] flex col-lg-10 ms-auto' >
                    <img src={data && data.alignImg ? data.alignImg :
                        require('../assesst/meridaLogoAlign.png')} alt="LogoAlignment"
                        className='container mx-auto  m-auto  ' />
                </main>
                {/* Only Logo  */}
                <main className='min-h-[80vh] flex ' >
                    <img src={data && data.belowAlign ? data.belowAlign : require('../assesst/Logo_Blue2.webp')} alt="LogoAlignment"
                        className=' sm:w-2/5 mx-auto  m-auto  ' />
                </main>
                {/* App Icon */}
                <section className='bg-slate-500 bg-opacity-20  ' >
                    <main className='min-h-[80vh] flex col-lg-10 ms-auto flex-col py-5 ' >
                        <h4 className='text-slate-800  py-3
                 border-slate-800 container text-2xl sm:text-4xl fw-semibold' >App Icon </h4>
                        <article className='flex flex-wrap gap-5 items-center
                container justify-around m-auto ' >
                            {
                                data && data.logoIcons && data.logoIcons.map((obj) => (
                                    <div className='w-1/4 flex ' >
                                        <div className={` ${obj.css ? obj.css : "bg-white m-auto h-[8rem] w-[8rem] flex p-2 rounded-xl "}`} >
                                            <img src={obj.img ? obj.img : require('../assesst/Merida_logoPage_Icon4.webp')} alt="Icon_page" loading='lazy'
                                                className='w-full h-fit m-auto ' width={120} />
                                        </div>
                                    </div>
                                ))
                            }
                        </article>
                    </main>
                </section>
                {/* Color palette */}
                <main className='min-h-[80vh] col-lg-10 ms-auto flex flex-col py-5 bg-opacity-20 inter ' >
                    <h4 className='text-slate-800 border-b- py-3
                 border-slate-800 container text-2xl sm:text-4xl fw-semibold' > Primary Color Palette </h4>
                    <div className='m-auto flex flex-wrap container items-center h-100 ' >
                        {
                            data && data.clrptrn && [...data.clrptrn].map((obj) => (
                                <article className='flex items-center justify-center my-3 sm:w-[50%] m-auto gap-2 ' >
                                    <div style={{ backgroundColor: `${obj.clr}` }} className=' h-[8rem] w-[8rem] ' ></div>
                                    <div className='flex flex-col justify-center ' >
                                        <p className='text-xl' >HSB: {obj.hsb ? obj.hsb : "240,68,24"} </p>
                                        <p className='text-xl' >Hex: {obj.hex ? obj.hex : "13133C"} </p>
                                        <p className='text-xl' >RGB: {obj.rgb ? obj.rgb : "19, 19,60 "} </p>
                                    </div>
                                </article>
                            ))
                        }

                    </div>
                </main>
                {/* Typeface */}
                <section className=' meridabannerbg' >

                    <main className='min-h-[80vh] col-lg-10 ms-auto flex flex-col py-5 inter ' >
                        <h4 className='text-slate-50 border-b- py-3
                 border-slate-50 container text-2xl sm:text-4xl fw-semibold' > Primary Typeface </h4>
                        <section className='row container mx-auto my-5 text-slate-50 min-h-[60vh] ' >
                            <article className='col-6 border-r-4 border-slate-50 flex ' >
                                <p className='text-4xl sm:text-8xl lg:text-[17rem] fw-bold m-auto ' > Aa </p>
                            </article>
                            <article className='col-6 flex ' >
                                <p className={`text-2xl m-auto ${data && data.fontstyle}`} > Neometric Extra Bold <br />
                                    ABCDEFGHIJKLMNOPQRSTWXYZ <br />
                                    abcdefghijklmnopqrstwxyz <br />
                                    1234567890!@%& </p>
                            </article>
                        </section>
                    </main>
                </section>
                {/* Small Variation */}
                <section className='container ' >

                    <main className='row col-lg-10 ms-auto overflow-hidden py-5 poppins' >
                        <section className='col-sm-6 px-3 ' >
                            <h4 className='text-3xl ' >Small Variation  </h4>
                            <p className='my-4 sm:text-xl' >
                                {data && data.logo_variation ? data.logo_variation : `The minimum signature size should be used only 25 pexel. If the logo falls below 35 pixels then the logo
                        will fail to reveal its identity. So it is recommended to keep the logo within minimum 25 pixels.`}
                            </p>
                        </section>
                        <section className='col-sm-6 ' >
                            <img src={data && data.alignimg2 ? data.alignimg2 :
                                require('../assesst/meridaLogoPlans.png')} alt="Logo PLans"
                                className='w-4/5 mx-auto  ' loading='lazy' />
                        </section>
                    </main>
                </section>
                {/* Cards */}
                {/* <main className='meridabannerbg min-h-[85vh] flex' >
                <section className='container m-auto flex flex-wrap justify-around  ' >
                    {
                        data && data.blogCard && data.blogCard.map((val) => (
                            <PosterCard img={val} />
                        ))
                    }
                </section>

            </main> */}
                {/* Images */}
                <main className='container-fluid px-0 mx-0 row ' >
                    {
                        data && data.clipimg && data.clipimg.map((obj) => (
                            <img src={obj.img ? obj.img : require('../assesst/Merida_Logo_page_Billboard.webp')} loading='lazy' alt="Billboards"
                                className={` ${obj && obj.css ? obj.css : "rounded col-lg-6 w-full mx-0 px-0"} object-cover `} />))
                    }
                </main>
            </main>
        </div>
    )
}

export default MeridaLogoPage