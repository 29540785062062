import React from 'react'
import Slider from 'react-slick';
import ProcessCard from './ProcessCard';

const OurProcess = () => {
    var settings = {
        // dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        // rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    let cardArry = [
        {
            grd: 'bluegrd',
            img: '../../assesst/logoprocess1-min.png',
            heading: 'Theme Setup',
            content: `By understanding your brand’s core values, we create a style that truly reflects what your business is all about.`,
        },
        {
            grd: 'orggrd',
            img: '../../assesst/logoprocess2-min.png',
            heading: 'Perfect Sizing',
            content: `A personalized, unique theme is crafted to capture your brand’s personality and align perfectly with your market.`
        },
        {
            grd: 'violetgrd',
            heading: 'Icon & Shape Selection',
            content: `Icons and shapes are thoughtfully chosen to ensure your logo stands out, making it memorable and recognizable.`,
            img: '../../assesst/logoprocess3-min.png'

        },
        {
            grd: 'grngrd',
            heading: 'Color & Font Choice',
            content: `Selecting colors and fonts that reflect your brand, ensuring a design that’s both balanced and engaging with modern and elegance.`,
            img: '../../assesst/colorlogobanne-min.png'

        },
    ]
    return (
        <div className=' sm:min-h-[100vh] py-4 flex flex-col container ' >
            <main className=' my-auto ' >

                <div className='relative px-2 w-fit mx-auto ' >
                    <h5 className='uppercase poppins fw-bold 
                text-3xl text-center relative z-0 sm:text-4xl xl:text-6xl '>
                        Our Process
                    </h5>
                    <h5 className='absolute top-0 left-1 text-nowrap strktxt uppercase poppins 
                text-3xl text-center sm:text-4xl xl:text-6xl '>
                        Our Process
                    </h5>

                </div>
                <p className='text-center w-10/12 mx-auto text-slate-800 my-2 ' >
                    Creating a logo involves a thoughtful and strategic process to ensure the final design aligns with the brand's
                    identity, values, and goals. Here's a step-by-step guide for the logo design process
                </p>
                <section className='py-sm-5 mt-5 ' >
                    <Slider {...settings} className='container mx-auto '  >
                        {
                            cardArry.map((obj, index) => (
                                <ProcessCard data={obj} />
                            ))
                        }
                    </Slider>
                </section>
            </main>
        </div>
    )
}

export default OurProcess