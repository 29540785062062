import React, { useEffect } from 'react'
import NavBarCom from '../component/NavBarCom'
import ReadyToColbrate from '../component/ReadyToColbrate'

const OurClients = () => {
    let logos = [
        '../assesst/GoogleLogo.png', '../assesst/OYOlogo.png', '../assesst/OLAlogo.png', '../assesst/AirbnbLogo.png', '../assesst/WalmartLogo.png', '../assesst/Group.png'
    ]
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <div>
            {/* Banner */}
            <main className={` ourclientbanner min-h-[100vh] relative `}>
                <NavBarCom logo='white' />
                <main className='flex container ' >
                    <article className='m-auto row p-4 w-full py-5' >
                        <section className='col-lg-8 flex' >
                            <article className='m-auto ' >
                                <h4 className='poppins text-4xl sm:text-7xl fw-bold text-slate-50 ' >Your
                                    <span className='txtclr ' > Digital </span> Dreams </h4>
                                <div className='sm:flex gap-4 my-3 ' >
                                    <h4 className='poppins text-4xl sm:text-7xl fw-bold text-slate-50 ' >Delivered </h4>
                                    <p className='text-slate-50 ' >Fusce consectetur ligula molestie nunc maximus, ac egestas risus placerat. Cras accumsan varius placerat.
                                        Vestibulum varius sodales ultrices.
                                        Integer congue cursus risus, at vehicula ex aliquet in. Utsit amet molestie risused hendrerit.
                                    </p>
                                </div>
                                <div className='flex gap-5 flex-wrap text-slate-50 my-3 mt-5 poppins'>
                                    <h5 className='text-3xl sm:text-6xl ' >45K
                                        <span className='block text-sm fw-normal ' >
                                            Happy Customer </span> </h5>
                                    <h5 className='text-3xl sm:text-6xl ' >100+
                                        <span className='block text-sm fw-normal ' >
                                            Projects Complications </span> </h5>
                                </div>
                            </article>
                        </section>
                        {/* Image */}
                        <section className='col-lg-4 ' >
                            <img src={require('../assesst/imagecompressor4/ourclientBannerContent.png')} alt="Content Image"
                                loading='lazy' className=' w-3/5 mx-auto ' />

                        </section>
                    </article>
                </main>
            </main>
            {/* Our client logos */}
            <div className='ourclienttilesbg min-h-[50vh] py-5 ' >
                <main className='container mx-auto flex flex-wrap justify-between gap-4 ' >
                    {
                        [...logos, ...logos, ...logos, ...logos].map((val, index) => (
                            <div className='w-44 h-24 flex bg-slate-50 my-2 rounded-2xl shadow ' >
                                <img src={val ? val : require('../assesst/GoogleLogo.png')} alt="googleLogo"
                                    className=' h-fit m-auto w-4/5 ' />
                            </div>
                        ))
                    }
                </main>
            </div>
            <ReadyToColbrate/>
        </div>
    )
}

export default OurClients