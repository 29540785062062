import React from 'react'

const AppNavIcon = ({ size }) => {
    return (
        <svg width={size ? size : "30"} height={size ? size : "30"} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99998 0H8.74998C11.5114 0 13.75 2.23857 13.75 4.99998V8.74998C13.75 11.5114 11.5114 13.75 8.74998 13.75H4.99998C2.23857 13.75 0 11.5114 0 8.74998V4.99998C0 2.23857 2.23857 0 4.99998 0Z" fill="currentColor" />
            <path d="M21.25 0H25C27.7614 0 30 2.23857 30 4.99998V8.74998C30 11.5114 27.7614 13.75 25 13.75H21.25C18.4886 13.75 16.25 11.5114 16.25 8.74998V4.99998C16.2499 2.23857 18.4885 0 21.25 0Z" fill="currentColor" />
            <path d="M4.99998 16.25H8.74998C11.5114 16.25 13.75 18.4886 13.75 21.25V25C13.75 27.7614 11.5114 30 8.74998 30H4.99998C2.23857 30 0 27.7614 0 25V21.25C0 18.4886 2.23857 16.25 4.99998 16.25Z" fill="currentColor" />
            <path d="M21.25 16.25H25C27.7614 16.25 30 18.4886 30 21.25V25C30 27.7614 27.7614 30 25 30H21.25C18.4886 30 16.25 27.7614 16.25 25V21.25C16.2499 18.4886 18.4885 16.25 21.25 16.25Z" fill="currentColor" />
        </svg>
    )
}

export default AppNavIcon