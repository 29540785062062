import React from 'react'

const LeftOpenCurve = ({ w, h }) => {
    return (
        <svg width={w ? w : "693"} height={h ? h : "1024"} viewBox="0 0 693 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_150_1103)">
                <path d="M568 268C568 654.763 256.675 968 -127 968C-510.675 968 -822 654.763 -822 268C-822 -118.763 -510.675 -432 -127 -432C256.675 -432 568 -118.763 568 268Z" stroke="#C0A6AF" stroke-width="50" />
            </g>
            <defs>
                <filter id="filter0_f_150_1103" x="-947" y="-557" width="1640" height="1650" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_150_1103" />
                </filter>
            </defs>
        </svg>

    )
}

export default LeftOpenCurve