import React from 'react'
import NavBarCom from '../NavBarCom'

const BannerSection = () => {
    return (
        <div className=' relative ' >


            <main className='container-fluid p-0 overflow-hidden 
            m-0 flex bg-black relative mx-auto h-[60vh] sm:h-[100vh]  ' >
                <div className='absolute top-0 w-full z-10' >
                    <NavBarCom theme='white' logo="white" />
                </div>
                {/* Ring Shape */}
                <div className='absolute right-0 -z-0 translate-x-1/2 -translate-y-1/2
                      w-[22rem] h-[22rem] border-[6rem] text-slate-50 rounded-full top-0 border-slate-100 border-opacity-20 ' >

                </div>
                <section className='relative w-[25%] sm:w-[14.2%] border-r-2 border-slate-400 border-opacity-80' >
                    {/* Balll */}
                    <div className='bg-blue-600 border-3 absolute 
                    top-[69%] sm:top-1/2 right-0 translate-x-1/2
                     border-slate-50 w-[25px] h-[25px] rounded-full ' >

                    </div>
                </section>
                <section className='relative  w-[25%] sm:w-[14.2%] border-r-2 border-slate-400 border-opacity-20 ' >

                </section>
                <section className='relative d-none d-sm-block w-[25%] sm:w-[14.2%] border-r-2 border-slate-400 border-opacity-20' >

                </section>
                <section className='relative d-none d-sm-block w-[25%] sm:w-[14.2%] border-r-2 border-slate-400 border-opacity-20' >

                </section>
                <section className='relative d-none d-sm-block w-[25%] sm:w-[14.2%] border-r-2 border-slate-400 border-opacity-20' >
                    {/* Balll */}
                    <div className='bg-green-600  border-3 absolute top-[55%] right-0 translate-x-1/2
                     border-slate-50 w-[20px] h-[20px] rounded-full ' >

                    </div>
                </section>
                <section className='relative w-[25%] sm:w-[14.2%] border-r-2 border-slate-400 border-opacity-80' >
                    {/* Balll */}
                    <div className='bg-slate-50 border-3 absolute top-[20%] right-0 translate-x-1/2
                     border-slate-50 w-[25px] h-[25px] rounded-full ' >

                    </div>
                </section>
                {/* Content */}
                <section className='absolute top-[8%] left-1/2 text-center z-10 w-full text-slate-50 text-opacity-90 inter -translate-x-1/2 ' >
                    <h3 className='inter text-4xl sm:text-7xl fw-bold  ' >
                        A New Approach to <br /> Testing for All Candidates
                    </h3>
                    <p className='my-4 px-3 ' >Conduct secure, efficient online exams for applicants. Designed for coding, <br />
                        MCQs, practical skill tests and much more with instant results and selection insights.</p>
                    {/* <main className='container bg-red-300 h-fit ' > */}
                    <img src={require('../../assesst/onlinetestMA/onlnieExamBanner-min.png')} alt="ONline Test banne" loading='lazy'
                        className='w-[30rem] mx-auto' />
                    {/* </main> */}
                </section>

            </main>

        </div>
    )
}

export default BannerSection