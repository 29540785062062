import './index.css'
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Page/HomePage';
import LogoPage from './Page/LogoPage';
import 'bootstrap/dist/css/bootstrap.min.css';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/LogoPage.css'
import './css/websitepage.css'
import WebsitePage from './Page/WebsitePage';
import AppPage from './Page/AppPage';
import OnlineTestPage from './Page/OnlineTestPage';
import MeridaLogoPage from './Page/MeridaLogoPage';
import GraphicDesigingPage from './Page/GraphicDesigingPage';
import OurClients from './Page/OurClients';
import BloomIndoorPage from './Page/BloomIndoorPage';
import HrmsAppPage from './Page/HrmsAppPage';
import CRMAppPage from './Page/CRMAppPage';
import WebAppPage from './Page/WebAppPage';
import GebaWebAppPage from './Page/GebaWebAppPage';


export const port='https://backendapi.meridatechminds.com'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<HomePage />} />
          <Route path='/logo-designing-company' element={<LogoPage />} />

          <Route path='/logo-designing-company/:slug' element={<MeridaLogoPage />} />


          <Route path='/website-designing-company' element={<WebsitePage />} />
          <Route path='/website-designing-company/:slug' element={<BloomIndoorPage />} />

          <Route path='/mobile-application-designing-company' element={<AppPage />} />
          <Route path='/mobile-application-designing-company/hrm' element={<HrmsAppPage />} />
          <Route path='/mobile-application-designing-company/crm' element={<CRMAppPage />} />

          <Route path='/ourclient' element={<OurClients />} />
          
          <Route path='/web-application-development/*' element={<WebAppPage />} />
          <Route path='/web-application-development/:slug' element={<GebaWebAppPage />} />

          <Route path='/graphic-designing-company' element={<GraphicDesigingPage />} />
          <Route path='/mobile-application-designing-company/online-test' element={<OnlineTestPage />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
