import React from 'react'

const Ecpilise = ({ w, h, color }) => {
    return (
        <svg width={w ? w : "658"} height={h ? h : "480"} viewBox="0 0 658 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M644.272 57.6875C668.926 100.39 656.177 159.456 615.331 220.546C574.504 281.605 505.694 344.536 418.567 394.839C331.44 445.142 242.535 473.268 169.242 478.095C95.9139 482.924 38.3862 464.432 13.7322 421.73C-10.9219 379.028 1.8269 319.961 42.6734 258.872C83.5 197.812 152.31 134.881 239.437 84.5779C326.564 34.275 415.469 6.14925 488.762 1.32222C562.09 -3.5072 619.618 14.9855 644.272 57.6875Z"
                stroke={"url(#paint0_linear_1264_11468)"} />
            <defs>
                <linearGradient id="paint0_linear_1264_11468" x1="240.218" y1="491.838" x2="519.055" y2="-129.207"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="currentColor" />
                    {/* <stop stop-color="#D3D1D1" /> */}
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Ecpilise