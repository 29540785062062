import React from 'react'
import RightArrow from '../Icons/RightArrow'
import { useNavigate } from 'react-router'

const WebAppCard = ({ data }) => {
    let navigate = useNavigate()
    console.log(data, 'data');

    return (
        <div className='sticky px-3 rounded-xl overflow-hidden col-lg-10 ms-auto top-24 py-4 bg-black pt-0' >
            <main className={` ${data && data.cardbg ? data.cardbg : "gebagrdbg"} 
             p-0 row min-h-[85vh] lg:min-h-[70vh] container mx-auto shadow rounded-xl `} >
                <section className={` ${data && data.theme == 'theme1' ? 'order-2 order-lg-0 ' : "order-2  "} col-lg-6 flex p-4 m-0 `} >
                    <article className='my-auto text-slate-200 h-full flex flex-col justify-around py-4 ' >
                        <div>
                            <h4 className={` ${data && data.theme == 'theme1' ? ' ' : "text-end "} 
                            poppins text-2xl fw-semibold `} >{data && data.appName ? data.appName : "Geba Cables "}</h4>
                            <p className={`${data && data.theme == 'theme1' ? ' ' : "text-end ms-auto "}
                             poppins my-3 text-sm sm:text-[1rem] sm:w-[81%] `} >
                                {data && data.cardcontent ? data.cardcontent : ` A new way to make payments easy, reliable and secure. You can manage all your 
                            transactions from your mobile phone.`}
                            </p>
                        </div>
                        <div className={` ${data && data.theme == 'theme1' ? ' ' : "text-end ms-auto "}
                         relative w-fit h-fit my-sm-5 `} >

                            <button onClick={() => navigate(`/web-application-development/${data && data.slug}`)}
                                className={`  flex bg-white text-slate-900 poppins gap-2 mx-auto 
                                 border- rounded p-2 px-3 items-center z-1 relative uppercase `} >
                                Case Study <RightArrow />
                            </button>
                            <button className='w-full h-full z-0 rounded border-[1px] absolute top-1 left-1 ' >

                            </button>
                        </div>
                    </article>
                </section>
                <section className={` ${data && data.theme == 'theme1' ? '' : "order-0 order-sm-1"}  col-lg-6 p-0 m-0 flex `} >
                    <img src={data && data.cardimg ? data.cardimg :
                        require('../assesst/WEBapp/gebawebappcard.png')}
                        className={` my-auto   ${data && data.imgcss && data.imgcss}
                     object-cover rounded-e-xl `} alt="Image" />
                </section>

            </main>

        </div>
    )
}

export default WebAppCard