import React, { useContext, useEffect } from 'react'
import DoubleArrow from '../Icons/DoubleArrow'
import { useNavigate } from 'react-router'
import NavBarCom from '../component/NavBarCom'
import RightUpOpenCurve from '../Icons/RightUpOpenCurve'
import LeftOpenCurve from '../Icons/LeftOpenCurve'
import RightUpVioletCurve from '../Icons/RightUpVioletCurve'
import { PorfolioStore } from '../context/PortFolioContext'

const AppPage = () => {
    let navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('mobile')
    }, [])
    return (
        <div className=' overflow-hidden relative bg-slate-950' >
            <div className='fixed w-full z-10 top-0 my-0' >
                <NavBarCom logo="light" theme=' bg-slate-950 my-0 ' burger />
            </div>
            <p className='h-[9vh] m-0 ' > </p>
            {/* Card 1 */}
            <div className='absolute top-0 z-20 -right-10  ' >
                <RightUpOpenCurve />
            </div>
            <main className='min-h-[80vh]  bg-slate-950  relative ' >
                <h5 className='strktxt uppercase text-6xl sm:text-8xl lg:text-[8rem] left-1/2 sm:left-0 -translate-x-1/2 sm:-translate-x-0
                fw-bold bottom-0 translate-y-[20%] absolute   text-nowrap' >
                    online test </h5>


                <div className='col-lg-10 ms-auto ' >

                    <section className='container relative mx-auto py-3 items-center row min-h-[80vh]' >
                        <article className='col-sm-6 order-2 order-sm-0 items-center flex' >
                            <div className='my-auto text-center sm:!text-start ' >
                                <h4 className='text-3xl sm:text-5xl poppins 
                             fw-semibold text-slate-50 ' >Change the Way Exams Are Conducted with Our

                                </h4>
                                <div className=' text-4xl w-fit h-fit  relative poppins 
                             fw-bold text-slate-50 my-2 mx-auto mx-sm-0 ' >
                                    <div className='bg-blue-600 relative rounded px-2  p-1 ' >
                                        Online Test App
                                    </div>
                                    <div className='rounded h-full absolute top-1 left-1 w-full border-[1.4px] border-blue-600 ' >

                                    </div>
                                </div>
                                <p className='text-slate-300 my-4 poppins fw-lighter    ' >
                                    A modern app that allows you to <br /> create, take, and track tests from anywhere, anytime.
                                </p>
                                <div className=' relative w-fit h-fit ' >
                                    <button onClick={() => navigate('/mobile-application-designing-company/online-test')}
                                        className='relative flex items-center gap-2 greybg2 mx-auto mx-sm-0
                                     text-slate-50 p-3 py-2 my-3 rounded z-1' >
                                        View Case Study <DoubleArrow />
                                    </button>
                                    <div className='h-full w-full absolute top-1 z-0 border-slate-600 left-1 border-2 rounded ' >

                                    </div>
                                </div>
                            </div>
                        </article>
                        <article className='col-sm-6 ' >
                            <img loading='lazy' src={require('../assesst/mobileApp/OnlineTestAppCard.png')}
                                className=' m-auto  ' alt="Mobile_Background" />
                        </article>
                    </section>
                </div>
            </main>
            {/* Card 2 */}
            <main style={{ backgroundColor: 'rgb(6 4 8)' }} className='min-h-[80vh] w-full relative ' >
                <div className=' absolute top-0 z-0 left-0 ' >
                    <LeftOpenCurve />
                </div>
                <h5 className='strktxt uppercase text-6xl sm:text-8xl lg:text-[9rem]
                right-1/2 sm:right-3 translate-x-1/2 sm:translate-x-0 fw-bold bottom-0 translate-y-[25%] absolute ' >
                    HRMS </h5>
                <section className='container-fluid relative  mx-auto row min-h-[100vh]' >
                    <article className='col-sm-6 flex ' >
                        <img src={require('../assesst/mobileApp/HRMAppCard.png')} alt="Hrm Feature"
                            className='mt-auto w-full ms-auto d-none d-sm-block ' />
                        <img src={require('../assesst/mobileApp/hrmappCardmobile-min.png')} alt="Hrm Feature"
                            className='mt-auto w-full ms-auto d-sm-none ' />
                    </article>
                    <article className='col-sm-6 my-auto text-center ' >
                        <div className='m-auto  ' >
                            <div className='relative my-2 w-fit h-fit mx-auto mx-sm-0 ms-sm-auto ' >
                                <div className='text-4xl sm:text-5xl lg:text-[3.4rem] poppins 
                             fw-semibold text-slate-950 relative z-2 p-2 rounded pinkbg w-fit ' >
                                    HRMS System
                                </div>
                                <div className='rounded absolute top-1 z-0 pinkbr right-1 h-full w-full border-[1.5px] 
                                ' >

                                </div>
                            </div>
                            <h4 className='text-4xl sm:text-6xl poppins
                              text-slate-50  text-center sm:!text-end' >

                                for Simple Workforce  Management
                            </h4>
                            <p className='text-slate-300 my-4 poppins text-center sm:w-[70%]  mx-auto mx-sm-0
                                 ms-sm-auto sm:!text-end fw-lighter    ' >
                                A clear and simple system to manage everything
                                from payroll to employee records in one place.
                            </p>

                            <div className=' relative w-fit h-fit mx-auto mx-sm-0
                                 ms-sm-auto ' >
                                <button onClick={() => navigate('/mobile-application-designing-company/hrm')}
                                    className='relative flex items-center gap-2 mx-auto mx-sm-0
                                 ms-sm-auto greybg2 text-slate-50 p-3 py-2 my-3 rounded z-1' >
                                    View Case Study <DoubleArrow />
                                </button>
                                <div className='h-full w-full absolute top-1 z-0 border-slate-600 left-1 border-2 rounded ' >

                                </div>
                            </div>
                        </div>
                    </article>

                </section>

            </main>
            {/* Card 3 */}
            <main className='min-h-[100vh] py-5 w-full bg-black  relative ' >
                <div className=' absolute top-0 right-0 ' >
                    <RightUpVioletCurve />
                </div>
                <h5 className='strktxt uppercase text-6xl sm:text-8xl lg:text-[9rem] 
                left-1/2 sm:left-4 -translate-x-1/2 sm:-translate-x-0 fw-bold bottom-0 translate-y-[25%]  absolute ' >
                    CRM </h5>
                <div className='col-lg-10  ms-auto ' >

                    <section className='container mx-auto relative row min-h-[90vh]' >
                        <article className='col-sm-6 order-2 order-sm-0 my-auto text-start ' >
                            <div className='m-auto  ' >
                                <div className=' text-4xl w-fit h-fit mx-auto mx-sm-0 relative poppins 
                             fw-bold text-slate-50 my-2' >
                                    <div className='bg-sky-600 relative rounded px-2  p-1 ' >
                                        CRM App
                                    </div>
                                    <div className='rounded h-full absolute top-1 left-1 w-full border-[1.4px] border-sky-600 ' >
                                    </div>
                                </div>
                                <h4 className='text-4xl sm:text-5xl poppins
                              text-slate-50 text-center sm:!text-start ' >
                                    Built For Better <br />  Client Relationships   </h4>
                                <p className='text-slate-300 my-4 poppins fw-lighter  text-center sm:!text-start  ' >
                                    An easy-to-use CRM to manage customer data
                                    and build stronger relationships with every interaction.
                                </p>


                                <div className=' relative w-fit h-fit ' >
                                    <button onClick={() => navigate('/mobile-application-designing-company/crm')}
                                        className='relative flex items-center gap-2 mx-auto mx-sm-0 greybg2
                                         text-slate-50 p-3 py-2 my-3 rounded z-1' >
                                        View Case Study <DoubleArrow />
                                    </button>
                                    <div className='h-full w-full absolute top-1 z-0 border-slate-600 left-1 border-2 rounded ' >

                                    </div>
                                </div>
                            </div>
                        </article>
                        <article className='col-sm-6  flex ' >
                            <img src={require('../assesst/mobileApp/OnlineTestAppCard.png')}
                                alt="Hrm Feature" className=' m-auto' />
                        </article>
                    </section>
                </div>

            </main>

        </div>
    )
}

export default AppPage