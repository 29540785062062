import React from 'react'
import Share2Icon from '../../Icons/Share2Icon'
import ContentType1 from './ContentType1'

const WhyChooseSection = ({ data }) => {
    return (
        <div className='container ' >
            <main className='col-lg-10 mx-lg-0 mx-auto ms-lg-auto py-4 row poppins ' >
                <section className='col-md-6  ' >
                    <p className='bg-blue-50 p-2 rounded-full fw-semibold my-3 text-blue-600 w-fit uppercase poppins ' >WHY us? </p>
                    <h4 className={` ${data && data.hcss ? data.hcss : 'text-slate-900'} text-3xl sm:text-6xl  fw-semibold my-2 `} >
                        {data && data.heading ? data.heading : "Exceptional Control Over Your Orders"}
                    </h4>
                    <p className={`${data && data.pcss ? data.pcss : 'text-slate-600'}  w-4/5 my-2 `} >
                        {data && data.content ? data.content : " printing system is built to fit your needs with easy-to-use features for everyone"}
                    </p>
                    <img src={data && data.card ? data.card : require('../../assesst/WEBapp/blackcardwebapp.png')} alt="ContentCard"
                        className='my-2 mx-auto ' />

                </section>
                <section className='col-md-6 flex ' >
                    <article className='my-auto ' >
                        {
                            data && data.leftContent.map((obj, index) => (
                                <div className='my-3 bg-white flex rounded-xl items-start border-2 p-4 ' >
                                    <span className='p-2 h-fit rounded-full bg-blue-50 w-fit  text-blue-600 ' >
                                        <Share2Icon />
                                    </span>
                                    <div className='px-3 ' >
                                        <h5 className='fw-semibold text-xl' >{obj.heading} </h5>
                                        <p className='text-slate-600 my-2 ' >
                                            {obj.content}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </article>
                </section>

            </main>

        </div>
    )
}

export default WhyChooseSection