import React from 'react'

const FeatureIcon = ({ size }) => {
    return (
        <svg width={size ? size : "33"} height={size ? size : "33"} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.4767 11.1664L26.53 8.83303L28.8633 7.7797C29.3833 7.5397 29.3833 6.80637 28.8633 6.56637L26.53 5.51303L25.4767 3.16637C25.2367 2.64637 24.5033 2.64637 24.2633 3.16637L23.21 5.4997L20.8633 6.55303C20.3433 6.79303 20.3433 7.52637 20.8633 7.76637L23.1967 8.8197L24.25 11.1664C24.49 11.6864 25.2367 11.6864 25.4767 11.1664ZM14.8633 13.1664L12.7433 8.4997C12.2767 7.4597 10.7833 7.4597 10.3167 8.4997L8.19667 13.1664L3.53 15.2864C2.49 15.7664 2.49 17.2464 3.53 17.713L8.19667 19.833L10.3167 24.4997C10.7967 25.5397 12.2767 25.5397 12.7433 24.4997L14.8633 19.833L19.53 17.713C20.57 17.233 20.57 15.753 19.53 15.2864L14.8633 13.1664ZM24.25 21.833L23.1967 24.1664L20.8633 25.2197C20.3433 25.4597 20.3433 26.193 20.8633 26.433L23.1967 27.4864L24.25 29.833C24.49 30.353 25.2233 30.353 25.4633 29.833L26.5167 27.4997L28.8633 26.4464C29.3833 26.2064 29.3833 25.473 28.8633 25.233L26.53 24.1797L25.4767 21.833C25.2367 21.313 24.49 21.313 24.25 21.833Z"
                fill="currentColor" />
        </svg>

    )
}

export default FeatureIcon