import React, { useContext, useEffect } from 'react'
import BannerSection from '../component/OnlineTestCom/BannerSection'
import StudyFlow from '../component/OnlineTestCom/StudyFlow'
import NavBarCom from '../component/NavBarCom'
import BrandingSection from '../component/OnlineTestCom/BrandingSection'
import { PorfolioStore } from '../context/PortFolioContext'

const OnlineTestPage = () => {
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        window.scrollTo(0,0)
        setActivePage('mobile')
    }, [])
    return (
        <div className='relative' >
            <BannerSection />
            <StudyFlow />
            <BrandingSection />

        </div>
    )
}

export default OnlineTestPage