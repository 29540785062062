import React from 'react'

const ProcessCard = ({ data }) => {
    return (
        <div className='my-3 ' >
            <section className={`poppins rounded-xl bg-white p-2 shadow-sm min-h-[10rem] relative `} >

                <img src={data && data.img ? data.img : require('../../assesst/logolap.webp')}
                    className='h-[14rem] rounded-xl w-full object-cover ' alt="Logo" />
                {/* Content */}
                <div className=' sm:border-[1px]  rounded p-2 px-2 mt-2 ' >
                    <h6 className=' text-lg min-h-[3.2rem]  text-blue-600' >
                        {data && data.heading ? data.heading : " Rarch & Concepts"}
                    </h6>
                    <p className='poppins line-clamp-4 my-2' >
                        {data && data.content ? data.content : ` At the outset, we explore your goals, analyze competitors,
                            and study how customers interact with similar products.`}
                    </p>
                </div>
                {/* <div className={`bg-opacity-35 bg-slate-900 w-full h-full top-0 rounded-xl absolute p-4 sm:p-8 py-12`} >
                    <article className='h-full text-left w-full text-slate-50 jakarta flex flex-col justify-between  ' >
                        <h6 className='fntbox text-xl sm:text-2xl fw-semibold' style={{ fontWeight: '600' }} >
                            {data && data.heading ? data.heading : " Rarch & Concepts"}
                        </h6>
                        <p className='poppins ' >
                            {data && data.content ? data.content : ` At the outset, we explore your goals, analyze competitors,
                            and study how customers interact with similar products.`}
                        </p>

                    </article>

                </div> */}

            </section >

        </div >
    )
}

export default ProcessCard