import React, { createContext, useState } from 'react'

export const PorfolioStore = createContext()

const PortFolioContext = ({ children }) => {
    let [activePage, setActivePage] = useState()
    let value = { activePage, setActivePage }
    return (
        <PorfolioStore.Provider value={value} >
            {children}
        </PorfolioStore.Provider>
    )
}

export default PortFolioContext
