import React from 'react'

const ContentType1 = ({ data, clr, bullet }) => {
    return (
        <div className={` ${clr && clr} `} >
            <p className={` ${data && data.scss ? data.scss : 'text-slate-600'} roboto     `} > {data && data.benifittitle ? data.benifittitle : "DOLOR SIT AMET"} </p>

            <h4 className={` ${data && data.hcss ? data.hcss : 'text-slate-900'} text-3xl sm:text-5xl my-2 fw-semibold  `}>
                {data && data.benifitHeading ? data.benifitHeading : "  Make your Life Easy & Smooth with CRM"}
            </h4>
            <p className={`  ${data && data.pcss ? data.pcss : 'text-slate-500'} w-4/5 my-3 `}>
                {data && data.benifitContent ? data.benifitContent :
                    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et lectus quam. In
    hac habitasse platea dictumst. Curabitur sit amet neque tincidunt purus eleifend consequat vel ut nib.`}
            </p>
            {/* Points */}  
            <ul className='list-inside ' >
                {
                    data && data.benifitpoints && data.benifitpoints.map((obj, index) => (
                        <li className={` flex my-2 items-center gap-2 ${data && data.hcss ? data.hcss : 'text-slate-900'} `} >
                            {bullet && <span className='w-2 h-2 border-2 border-violet-600 p-1 rounded-full ' >
                            </span>}
                            <span>
                                {obj} </span>
                        </li>))
                }

            </ul>

        </div>
    )
}

export default ContentType1