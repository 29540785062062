import React from 'react'

const BrowcherLayout = ({ data }) => {
    return (
        <div>
            <main className='row container-fluid p-0 ' >
                <article className='col-lg-4 ' >
                    <section className="my-[10px]  ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img1 ? data.img1 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>
                    <section className="my-[10px] mt-4 ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img2 ? data.img2 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>
                </article>
                <article className='col-lg-4 mt-5 ' >
                    <section className="my-[10px] ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img3 ? data.img3 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>
                    <section className="my-[20px] ">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img4 ? data.img4 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>
                </article>
                <article className='col-lg-4 ' >
                    <section className="my-[10px]">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.img5 ? data.img5 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>
                    <section className="my-[20px]">
                        <div className='rounded h-full w-full bg-slate-200 ' >
                            <img src={data && data.image ? data.image : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full shadow  object-cover'
                                loading='lazy' />
                        </div>
                    </section>
                </article>
            </main>
        </div>
    )
}

export default BrowcherLayout