import React from 'react'
import Slider from 'react-slick'

const ScrollingComponent = () => {
    let settings = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToScroll: 1,
        // verticalSwiping: true,
        initialSlide: 0,
        infinite: true,
        vertical: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    let settings2 = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        vertical: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    let photos = [
        '../../assesst/websiteScroller1.webp',
        '../../assesst/websiteScroller2.webp',
        '../../assesst/websiteScroller3.webp',
        '../../assesst/websiteScroller4.webp',
        '../../assesst/websiteScroller5.webp',
    ]

    return (
        <div>
            <main className='container-fluid flex relative mx-auto overflow-hidden h-full'>
                {/* Left Section */}
                <section className=' relative h-[80vh] me-2'> {/* Fixed width for left slider */}
                    <Slider {...settings} className='h-full' rtl={true}>
                        {photos.map((obj, index) => (
                            <div key={index} className='my-3 rounded bg-slate-200 p-4 ' >
                                <img
                                    src={obj ? obj : require('../../assesst/websiteScroller1.webp')}
                                    alt="WebSite"
                                    className='rounded-xl h-[50vh] object-cover '
                                />
                            </div>
                        ))}
                    </Slider>
                </section>

                {/* Center Section */}
                <section className=' relative'> {/* Allowing center section to grow */}
                    <Slider {...settings2} className='h-full'>
                        {photos.reverse().map((obj, index) => (
                            <div key={index} className='my-3 rounded bg-slate-200 p-4 ' >
                                <img
                                    src={obj ? obj : require('../../assesst/websiteScroller1.webp')}
                                    alt="WebSite"
                                    className='rounded-xl h-[50vh] object-cover '
                                />
                            </div>
                        ))}
                    </Slider>
                </section>


                {/* <section className=' bg-yellow-300 overflow-hidden relative h-[80vh'>
                    <Slider {...settings} className='h-full' rtl={true}>
                        {[1, 2, 3, 4, 5, 6].map((obj, index) => (
                            <div key={index}>
                                <img
                                    src={require('../../assesst/websiteScroller1.webp')}
                                    alt="WebSite"
                                    className='rounded-xl'
                                />
                            </div>
                        ))}
                    </Slider>
                </section> */}
            </main>


        </div>
    )
}

export default ScrollingComponent