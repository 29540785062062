import React from 'react'
import RightArrow from '../../Icons/RightArrow'
import { useNavigate } from 'react-router'
import { logoData } from '../../context/Data'

const CaseStudyCards = ({ data, index, changeFun }) => {
    let navigate = useNavigate()
    return (
        <div id={data.slug} className=' sticky top-24 z-0 col-lg-10 ms-auto bg-white lg:min-h-[100vh] ' >
            <main className='relative ' >

                <main className={`${data.card1css ? data.card1css : "bg-slate-100"}
            min-h-[70vh] shadow-sm my-5 rounded-xl mx-auto overflow-hidden container p-3  row  `} >
                    {/* COntent */}
                    <section className={` col-md-6 col-lg-5 p-4 flex justify-around flex-col  `}>
                        <article>
                            <div className='flex flex-col ' >
                                <div>
                                    <p className={`${index % 2 == 0 ? 'text-blue-600 ' : ''}  pacifico `} >{data && data.heading ? data.heading : "Case Study"} </p>
                                    <h5 className='poppins fw-bold text-3xl my-2 my-sm-3 sm:text-5xl  ' > {data ? data.title : "Merida Tech Minds "}</h5>
                                    {/* Tags */}
                                    <div className='flex flex-wrap gap-3 ' >
                                        {
                                            data && data.tags && data.tags.map((val) => (
                                                <p className={`${index % 2 != 0 ? 'text-blue-600 ' : ''} bg-white rounded p-1 px-2 text-sm mb-0 `}> {val} </p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='flex gap-3 my-3 my-sm-4 flex-wrap ' >
                                    {
                                        data && data.clrptrn && data.clrptrn.map((val, index) => (
                                            <div className='relative ' >
                                                <div style={{ backgroundColor: `${val.clr}` }} className={` w-8 h-8 sm:w-12 sm:h-12 rounded `} >
                                                </div>
                                                <div style={{ borderColor: `${val.clr}` }} className={`top-1 w-8 h-8 sm:w-12 sm:h-12 rounded border-[1.5px] absolute left-1 `} >
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <p className='text-sm my-2 sm:text-lg ' >
                                {data && data.content ? data.content : ` Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et voluptate debitis dolores nostrum explicabo vel repellendus, porro voluptates veniam? Dolores amet illum nisi tempore odio
                                commodi iure consectetur quis. Itaque, iusto pariatur officiis corrupti a quisquam vitae aut quas!`}
                            </p>
                        </article>
                        <article className='flex justify-between items-start gap-3' >
                            <div className='relative  ' >

                                <button onClick={() => navigate(`/logo-designing-company/${data && data.slug}`)}
                                    className={`relative z-10 rounded poppins p-2 
                            px-3 w-full flex justify-center items-center gap-2 ${index % 2 != 0 ? 'bg-white text-blue-600 ' : 'bg-blue-600 text-white '} `} >
                                    View Case Study  <span className='-rotate-45 ' >
                                        <RightArrow />
                                    </span>
                                </button>
                                <div className={` h-full ${index % 2 != 0 ? '' : ' border-blue-600 '} z-0 border-[1.5px] absolute top-1 left-1 rounded  w-full  `} >

                                </div>
                            </div>
                            {/* COlors */}


                        </article>

                    </section>
                    <section className={`${data.card2css ? data.card2css : ""}  col-md-6 col-lg-7 py-2 px-0 rounded flex bg-white `} >
                        <img src={data && data.logoBanner ? data.logoBanner : require('../../assesst/Maskimg.webp')}
                            className=' h-[28vh] sm:h-[35vh] lg:h-[50vh] m-auto object-contain ' alt="Mash Image" />
                    </section>

                </main>
                {index + 1 < logoData.length && < button onClick={() => changeFun()} className={`${data.card1css ? data.card1css : 'bg-slate-100'} 
                         absolute right-0 translate-y-1/2 rotate-90 bottom-0 z-10 rounded-full p-[20px]  `} >
                    <RightArrow size={30} />
                </button>}
            </main>
        </div >
    )
}

export default CaseStudyCards