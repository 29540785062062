import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { port } from '../App'
import BrowcherLayout from './GalaryLayouts/BrowcherLayout'
import PosterLayout from './GalaryLayouts/PosterLayout'
import BannerLayout from './GalaryLayouts/BannerLayout'
import VisitingCardLayout from './GalaryLayouts/VisitingCardLayout'
import MockupsLayouts from './GalaryLayouts/MockupsLayouts'
import { Spinner } from 'react-bootstrap'

const GraphicGalary = () => {
    let [selectedTopic, setSelectedTopic] = useState('all')
    let [allData, setData] = useState()
    let [selectedData, setSelectedData] = useState()
    let [category, setCategory] = useState()
    let [loading, setLoading] = useState(false)
    let getAllData = async (val) => {
        setLoading(true)
        if (val) {
            await axios.get(`${port}/api/graphicdesignportfolio?category_name=${val}`).then((response) => {
                setSelectedData([...response.data].reverse())
                console.log([...response.data].reverse());
            }).catch((error) => {
                console.log(error);
            })
        }
        else
            await axios.get(`${port}/api/graphicdesignportfolio`).then((response) => {
                setData([...response.data].reverse())
                console.log([...response.data].reverse());
            }).catch((error) => {
                console.log(error);
            })
        setLoading(false)
    }
    useEffect(() => {
        getAllData()
    }, [])
    useEffect(() => {
        if (allData) {
            let category = [...new Set(allData.map((obj) => obj.category.category_name))].reverse()
            console.log(category, 'cat');
            setSelectedTopic(category[0])
            getAllData(category[0])
            setCategory(category)
        }
    }, [allData])
    return (
        <div>
            <main className='flex gap-3 items-center my-3 inter' >
                {
                    category && category.map((obj) => (
                        <button onClick={() => { setSelectedTopic(obj); getAllData(obj) }}
                            className={`duration-500 me-4 ${selectedTopic == obj ? 'fw-semibold ' : 'fw-normal '} `} >
                            {obj}
                        </button>
                    ))
                }
            </main>
            <main className='' >
                {
                    loading ? <div className='min-h-[40vh] flex ' >
                        <Spinner className='m-auto ' />
                    </div> : selectedData && selectedData.map((obj) => (
                        <div className={` `}>
                            {obj.layout == 'layout1' && <BrowcherLayout data={obj} />}
                            {obj.layout == 'layout2' && <PosterLayout data={obj} />}
                            {obj.layout == 'layout3' && <BannerLayout data={obj} />}
                            {obj.layout == 'layout4' && <VisitingCardLayout data={obj} />}
                            {obj.layout == 'layout5' && <MockupsLayouts data={obj} />}

                        </div>
                    ))
                }
            </main>
        </div>
    )
}

export default GraphicGalary