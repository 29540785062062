import React, { useContext, useEffect } from 'react'
import NavBarCom from '../component/NavBarCom'
import MobileIcon from '../Icons/MobileIcon'
import LaunchIcon from '../Icons/LaunchIcon'
import ThumbIcon from '../Icons/ThumbIcon'
import TouchIcon from '../Icons/TouchIcon'
import TabIcon from '../Icons/TabIcon'
import RightArrow from '../Icons/RightArrow'
import { PorfolioStore } from '../context/PortFolioContext'

const HrmsAppPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('mobile')
    }, [])
    return (
        <div>
            {/* Banner */}
            <main className='min-h-[70vh] xl:min-h-[100vh] hrmpagebanner ' >
                <NavBarCom theme='dark' />
                <section className='poppins text-center ' >
                    <h4 className='text-4xl sm:text-6xl text-slate-700 ' >
                        Manage Your <br />Workforce
                        with Our <br />
                        <span className='fw-bold text-slate-900' >
                            HRMS App </span>
                    </h4>
                    <p className='text-slate-700  my-4 ' >
                        A user-friendly HR management app designed to keep <br /> your team organized, connected, and productive.
                    </p>

                    <button className='my-3 px-3 p-2 rounded-xl border-2 border-green-300 bg-slate-50 ' >
                        Ask for demo
                    </button>
                </section>
                <img src={require('../assesst/hrmapp/hrmappbanner-min.png')} alt="HrmBanner" className='w-4/5  sm:w-2/5 mx-auto  ' />
            </main>
            {/* Banner end */}
            {/* Mobile version */}
            <div className='bg-slate-200 bg-opacity-30 min-h-[60vh] xl:min-h-[100vh] ' >
                <main className='container mx-auto row py-4 poppins' >
                    <section className='text-center poppins ' >
                        <button className='rounded-full p-2 flex items-center gap-3
                        mx-auto px-3 border-2 border-teal-500 bg-green-50 text-teal-500 ' >
                            <MobileIcon />  Mobile Version
                        </button>
                        <h4 className='text-4xl sm:text-5xl my-3 text-slate-700  ' >   Your HRMS, Now on
                            <span className='fw-bold text-slate-900 ' > Mobile </span> </h4>
                        <p className='my-3 text-slate-700 ' >
                            Access everything you need, anytime, anywhere, to support  <br />your team right from your smartphone.
                        </p>
                    </section>
                    {/* Card 1 col */}
                    <section className='col-lg-4 justify-around flex flex-col my-3' >
                        <article className='flex flex-col my-3 items-end w-[20rem] ' >
                            <span className='text-teal-500 ' >
                                <TouchIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' >Select an HR Service </p>
                            <p className='text-slate-700 w-4/5 text-end ' >
                                Pick the service that best fits your business needs and team size. </p>
                        </article>
                        <article className='flex my-3 flex-col items-end w-[20rem] ' >

                            <span className='text-teal-500 ' >
                                <ThumbIcon />  </span>
                            <p className='fw-bold text-xl my-3 ' > Define Your Requirements</p>
                            <p className='text-slate-700 w-4/5 text-end ' >
                                Share your requirements so we can provide the best solutions for your team. </p>
                        </article>
                    </section>
                    {/* Mobile Image */}
                    <section className='col-lg-4 my-3 ' >
                        <img src={require('../assesst/hrmapp/hrmappcenterpiece-min.png')} alt="Mobile"
                            className='  ' />
                    </section>
                    {/* Card 2 col */}
                    <section className='col-lg-4 justify-around flex flex-col my-3' >
                        <article className='flex flex-col my-3 ms-auto w-[20rem] ' >
                            <span className='text-teal-500 ' >
                                <TabIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' >Schedule a Consultation</p>
                            <p className='text-slate-700 w-4/5 ' >
                                Set up a quick meeting to walk through any specific needs or goals.
                            </p>
                        </article>
                        <article className='flex my-3 flex-col ms-auto w-[20rem] ' >
                            <span className='text-teal-500 ' >
                                <LaunchIcon />   </span>
                            <p className='fw-bold text-xl my-3 ' > Get Your Custom Solution </p>
                            <p className='text-slate-700 w-4/5 ' >
                                Receive an HR solution tailored specifically for your business.
                            </p>
                        </article>
                    </section>
                </main>
            </div>
            {/* Features */}
            <main className='container mx-auto py-4 min-h-[70vh] xl:min-h-[100vh] ' >
                <button className='rounded-full p-2 flex items-center gap-3
                        mx-auto px-3 border-2 border-teal-500 bg-green-50 text-teal-500 ' >
                    Features
                </button>
                <h4 className='text-4xl fw-light poppins my-3 text-slate-700 text-center ' >
                    Features That Simplify <br />
                    Every
                    <span className='fw-bold text-slate-900 ' > HR Task </span> </h4>

                <p className='my-3 text-slate-700  text-center' >
                    Take control of recruitment, payroll, and attendance <br />
                    with features that simplify every HR task.
                </p>
                {/* Content 1 */}
                <section className='rounded-xl bg-slate-950 min-h-[40vh] p-4 pb-0 row my-4 poppins text-slate-50 ' >
                    <article className='col-lg-6 pb-4 justify-between flex flex-col ' >
                        <div>
                            <h4 className='my-3 text-2xl fw-semibold ' > Recruitment Management </h4>
                            <p className='text-slate-400 fw-normal ' >
                                Organize and manage hiring steps, from job postings to candidate interviews.
                                Track every applicant, assign tasks to hiring managers, and speed up the onboarding process.
                            </p>
                        </div>
                        <button className='p-2 px-3 w-fit rounded-xl border-2 text-sm mt-auto flex ' >
                            Learn More
                        </button>
                    </article>
                    <article className='col-lg-6 p-0 ' >
                        <img src={require('../assesst/hrmapp/hrmappfeature1-min.png')} className='w-4/5 mx-auto sm:w-[80%] ' alt="Dummy image" />
                    </article>
                </section>
                {/* Content 2 */}
                <section className='rounded-xl bg-slate-950 min-h-[40vh] p-4 pb-0 row my-4 poppins text-slate-50 ' >
                    <article className='col-lg-6 pb-4 justify-between flex flex-col ' >
                        <div>
                            <h4 className='my-3 text-2xl fw-semibold ' >Payroll Processing                            </h4>
                            <p className='text-slate-400 fw-normal ' >
                                Automatically calculate salaries, deductions, and taxes, reducing manual work. Generate pay slips,
                                track expenses, and ensure accurate payouts, all in a few clicks.
                            </p>
                        </div>
                        <button className='p-2 px-3 w-fit rounded-xl border-2 text-sm mt-auto flex ' >
                            Learn More
                        </button>
                    </article>
                    <article className='col-lg-6 p-0 ' >
                        <img src={require('../assesst/hrmapp/hrmappfeature2-min.png')} className='w-4/5 mx-auto sm:w-[70%] ' alt="Dummy image" />
                    </article>
                </section>
                {/* Content 3*/}
                <section className='rounded-xl bg-slate-950 min-h-[40vh] p-4 pb-0 row my-4 poppins text-slate-50 ' >
                    <article className='col-lg-6 pb-4 justify-between flex flex-col ' >
                        <div>
                            <h4 className='my-3 text-2xl fw-semibold ' >Attendance Tracking </h4>
                            <p className='text-slate-400 fw-normal ' >
                                Log work hours accurately with an easy check-in/out system. Monitor attendance patterns,
                                handle leave requests, and view reports to manage team productivity.
                            </p>
                        </div>
                        <button className='p-2 px-3 w-fit rounded-xl border-2 text-sm mt-auto flex ' >
                            Learn More
                        </button>
                    </article>
                    <article className='col-lg-6 p-0 ' >
                        <img src={require('../assesst/hrmapp/hrmappfeature3-min.png')} alt="Dummy image" />
                    </article>
                </section>
            </main>
            {/* Why Choose us */}
            <main className='container mx-auto py-4 min-h-[70vh] xl:min-h-[100vh] ' >
                {/* <button className='rounded-full p-2 flex items-center gap-3
                        mx-auto px-3 border-2 border-teal-500 bg-green-50 text-teal-500 ' >
                    Why Choose Us
                </button> */}
                <h4 className='text-4xl fw-light poppins my-3 text-slate-700 text-center ' >
                    Why

                    <span className='fw-bold text-slate-900 ' > Merida's HRMS </span> <br /> is the Ideal Choice for Your <br /> Organization</h4>

                {/* <p className='my-3 text-slate-700  text-center' >
                    We're here to support you every step of the way. Our team is dedicated to <br />
                    providing you with the best smart home experience possible
                </p> */}
                <section className='row my-4 poppins  ' >
                    <article className=' col-lg-4 text-center' >
                        <div className='border-2 rounded-2xl my-3 pt-3 text-center ' >
                            <h6 className='text-teal-600 fw-semibold ' >Merida Hr </h6>
                            <p className='text-slate-600 ' >Integrates with all your <br /> smart devices </p>
                            <img src={require('../assesst/smallGalaxy.png')} alt="SmallGalaxy"
                                className='w-full ' />
                        </div>
                        <div className='border-2 rounded-2xl my-3 pt-3 text-center ' >
                            <h6 className='text-slate-800 fw-semibold ' > Affordable Plans </h6>
                            <p className='text-slate-600 ' >Made to fit your budget. </p>
                            <img src={require('../assesst/hrmapp/hrmappdiscovery3-min.png')} alt="SmallGalaxy"
                                className='w-full ' />
                        </div>
                    </article>
                    {/* Col 2 */}
                    <article className=' col-lg-4 flex flex-col text-center' >
                        <div className='border-2 flex-grow-1 rounded-2xl my-3 py-2 text-center ' >
                            <h6 className='text-slate-800 fw-semibold ' >
                                Instant Access Anytime, Anywhere
                            </h6>
                            <p className='text-slate-600 px-2 text-sm mt-3' >
                                With Merida HRMS, all your HR tasks are just a tap away on your mobile device, giving you full control
                                over your workforce.

                            </p>
                        </div>
                        <div className='border-2  flex-grow-1 rounded-2xl my-3 pb-3 text-center ' >
                            <img src={require('../assesst/hrmapp/hrmappdiscovery2-min.png')} alt="SmallGalaxy"
                                className='w-[90%] mx-auto ' />
                            <h6 className='text-slate-800 fw-semibold my-2 ' >Customizable Features to Fit Your Needs                            </h6>
                            <p className='text-slate-600 px-3 ' >
                                Adapt Merida HRMS to your organization's specific needs, regardless of size.
                            </p>

                        </div>
                    </article>
                    {/* Col 3 */}
                    <article className=' col-lg-4 flex flex-col text-center' >
                        <div className='border-2 rounded-2xl my-3 pt-3 text-center ' >
                            <h6 className='text-slate-800 fw-semibold ' >User-Friendly Interface </h6>
                            <p className='text-slate-600 px-3' >
                                Designed with simplicity in mind, our app ensures that you can manage HR tasks
                                without complicated training or setups.</p>
                            <img src={require('../assesst/hrmapp/hrmappdiscovery1-min.png')} alt="SmallGalaxy"
                                className='w-4/5 sm:w-[80%]  mx-auto' />
                        </div>
                        <div className='border-2 overflow-hidden flex-grow-1 relative rounded-2xl my-3 pt-3 text-center ' >
                            <h6 className='text-slate-800 fw-semibold relative' >Data Security</h6>
                            <p className='text-slate-600 px-3 relative' >
                                Your data is always safe here with strong, reliable protection.
                            </p>
                            <img src={require('../assesst/securityLock.png')} alt="SecurityLock_Image" className='
                           w-full  absolute top-1/2 -translate-y-1/2 z-0' />

                        </div>
                    </article>
                </section>
            </main>
            {/* Contact us */}
            <main className='rounded-xl container mx-auto flex flex-col justify-around bg-green-50 p-4 min-h-[50vh] my-4 ' >
                <h4 className='text-3xl sm:text-6xl text-teal-500 text-center poppins  ' >
                    See How Our HR App <br /> Works for You!
                </h4>
                <button className='w-fit flex gap-3 items-center rounded-full mx-auto bg-teal-500 p-2 px-3 text-white ' >
                    Contact <span className='text-teal-500 p-[5px] rounded-full bg-white ' >
                        <RightArrow />
                    </span>
                </button>
            </main>
        </div>
    )
}

export default HrmsAppPage