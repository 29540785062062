import React from 'react'
import TrochIcon from '../../Icons/TrochIcon'
import DownloadIcon from '../../Icons/DownloadIcon'
import FeatureIcon from '../../Icons/FeatureIcon'

const OurFeatures = ({ data }) => {

    return (
        <div>
            <main className=' mx-auto py-4 ' >
                <p className='uppercase bg-blue-50 text-blue-600 p-2 px-3 rounded-full w-fit mx-auto flex ' >
                    Our Features
                </p>
                <h4 className={`  ${data && data.hcss ? data.hcss : 'text-slate-900'} text-center fw-semibold poppins text-4xl
                 sm:text-6xl my-3 w-4/5 mx-auto sm:w-3/5 `} >
                    {data && data.heading ? data.heading : " Powerful  for Order Traceability and Control"}
                </h4>
                <p className={` text-center w-4/5 sm:h-3/5 my-4  mx-auto ${data && data.pcss ? data.pcss : 'text-slate-500'} `} >
                    {data && data.content ? data.content :
                        " Our system offers advanced tracking to keep you informed at every stage"}
                </p>
                {data && data.card1 && data.card2 &&
                    < main className='row container-fluid m-0 p-0 poppins ' >
                        <section className={`col-lg-6 ps-0 min-h-[50vh]  `} >
                            <article className={` ${data.card1 && data.card1.bg ? data.card1.bg : 'shadowbg'} p-3 h-full rounded-e-3xl `} >
                                <h5 className=' text-slate-200 flex items-center gap-2 ' >
                                    <span className='text-blue-600 ' >
                                        <DownloadIcon /> </span>
                                    {data && data.card1 && data.card1.heading ? data.card1.heading : " Lorem ipsum dolor sit amet."}
                                </h5>
                                <p className={` w-5/6 text-slate-400  `} >
                                    {data && data.card1 && data.card1.content ? data.card1.content : ` Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    officia explicabo. Exercitationem tempore laborum sunt inventore doloribus libero enim numquam?`}
                                </p>
                                <img src={data && data.card1 && data.card1.img ? data.card1.img : require('../../assesst/WEBapp/onlinetestwebapppfeature1.webp')} alt="Feature 1"
                                    className={`  ${data && data.card1 && data.card1.imgcss ? data.card1.imgcss : " w-[80%] "} `} />

                            </article>
                        </section>
                        <section className={`col-lg-6 pe-0 min-h-[50vh] ${data && data.card2 && data.card2.cardcss} `} >
                            <article className={` ${data.card2 && data.card2.bg ? data.card2.bg : 'shadowbg'} p-3 h-full rounded-s-3xl `} >
                                <h5 className=' text-slate-200 flex items-center gap-2 ' >
                                    <span className='text-blue-600 ' >
                                        <FeatureIcon /> </span>  {data && data.card2 && data.card2.heading ? data.card2.heading : " Lorem ipsum dolor sit amet."}
                                </h5>
                                <p className={` w-5/6 text-slate-400  `} >
                                    {data && data.card2 && data.card2.content ? data.card2.content : ` Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    officia explicabo. Exercitationem tempore laborum sunt inventore doloribus libero enim numquam?`}
                                </p>
                                <img src={data && data.card2 && data.card2.img ? data.card2.img : require('../../assesst/WEBapp/onlinetestwebappfeature2.webp')} alt="Feature 1"
                                    className={`${data && data.card2 && data.card2.imgcss ? data.card2.imgcss : " w-[60%]"} mx-auto flex `} />
                            </article>
                        </section>
                    </main>
                }
                {/* Card 5 */}
                {data && data.card5 && data.card6 &&
                    < main className={` ${data && data.card5css} container-fluid row mx-auto `}>

                        <section className={` ps-0 min-h-[40vh]  `} >
                            <article className={`col-lg-5 py-4 ms-auto ${data.card5 && data.card5.bg ? data.card5.bg : ''} p-3 h-full rounded-e-3xl `} >
                                <h5 className=' text-slate-200  items-center gap-2 ' >
                                    <span className='text-blue-600 ' >
                                        <DownloadIcon /> </span>
                                    {data && data.card5 && data.card5.heading ? data.card5.heading : " Lorem ipsum dolor sit amet."}
                                </h5>
                                <p className={` w-4/6 text-slate-400  `} >
                                    {data && data.card5 && data.card5.content ? data.card5.content : ` Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    officia explicabo. Exercitationem tempore laborum sunt inventore doloribus libero enim numquam?`}
                                </p>

                            </article>
                        </section>
                        <section className={` col-lg-5 pe-0 min-h-[40vh] ${data && data.card6 && data.card6.cardcss} `} >
                            <article className={` ${data.card6 && data.card6.bg ? data.card6.bg : 'shadowbg'} p-3 h-full rounded-s-3xl `} >
                                <h5 className=' text-slate-200  items-center gap-2 ' >
                                    <span className='text-blue-600 ' >
                                        <FeatureIcon /> </span>  {data && data.card6 && data.card6.heading ? data.card6.heading : " Lorem ipsum dolor sit amet."}
                                </h5>
                                <p className={` w-4/6 text-slate-400  `} >
                                    {data && data.card6 && data.card6.content ? data.card6.content : ` Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    officia explicabo. Exercitationem tempore laborum sunt inventore doloribus libero enim numquam?`}
                                </p>
                            </article>
                        </section>
                    </main>

                }
                {/* feature 3 */}
                {data && data.card3 && <section className={` container ${data.card3.bg ? data.card3.bg : "shadowbg2"} min-h-[60vh] my-4 row mx-auto  `} >
                    <article className={` col-md-6 flex py-3 ${data.card3.swap ? 'order-2' : ''}`} >
                        <div className={` my-auto `} >
                            <span className='text-blue-600 ' >
                                <TrochIcon />
                            </span>
                            <h4 className={` ${data.card3.hcss ? data.card3.hcss : "text-slate-200"} my-3 text-2xl sm:text-4xl w-4/5 `} >
                                {data.card3.heading ? data.card3.heading : "Customizable Tracking Dashboard"}
                            </h4>
                            <p className={`${data.card3.pcss ? data.card3.pcss : "text-slate-400"}  `} >
                                {data.card3.content ? data.card3.content :
                                    "Fully adjustable to your specific business tracking needs"}
                            </p>
                        </div>

                    </article>
                    <article className={`${data.card3.swap ? 'order-1' : ''} flex col-md-6 py-3 `} >
                        <img src={data.card3.img ? data.card3.img :
                            require('../../assesst/WEBapp/gebafeature1-min.png')} alt="Feature3"
                            className=' w-4/5 mx-auto my-auto ' />

                    </article>
                </section>}
                {/* feature 4 */}
                {data && data.card4 && <section className={` container ${data.card4.bg ? data.card4.bg : "shadowbg2"} min-h-[60vh] my-4 row mx-auto  `} >
                    <article className={` col-md-6 flex py-3 ${data.card4.swap ? 'order-2' : ''}`} >
                        <div className={` my-auto `} >
                            <span className='text-blue-600 ' >
                                <DownloadIcon />
                            </span>
                            <h4 className={` ${data.card4.hcss ? data.card4.hcss : "text-slate-200"} my-3 text-2xl sm:text-4xl w-4/5 `} >
                                {data.card4.heading ? data.card4.heading : "Customizable Tracking Dashboard"}
                            </h4>
                            <p className={`${data.card4.pcss ? data.card4.pcss : "text-slate-400"}  `} >
                                {data.card4.content ? data.card4.content :
                                    "Fully adjustable to your specific business tracking needs"}
                            </p>
                        </div>

                    </article>
                    <article className={`${data.card4.swap ? 'order-1' : ''} flex col-md-6 py-3 `} >
                        <img src={data.card4.img ? data.card4.img :
                            require('../../assesst/WEBapp/gebafeature1-min.png')} alt="Feature3"
                            className=' w-4/5 mx-auto my-auto ' />

                    </article>
                </section>}
                {/* Footer */}
                {data && data.footer &&
                    < section className={` row container pe-0 mx-auto min-h-[60vh] my-3 
                ${data.footer.bg ? data.footer.bg : "bg-black"} `} >
                        <article className='col-md-6 flex '>
                            <div className='my-auto ' >
                                <h4 className=' text-slate-200 my-3 fw-semibold text-3xl sm:text-5xl ' >
                                    {data.footer.heading ? data.footer.heading :
                                        "Enhance Your Order Tracking Experience Merida’s Traceability Solutions"}
                                </h4>
                                <p className='text-slate-200 w-4/5 ' >
                                    Get in touch with us for more details!
                                </p>
                            </div>

                        </article>
                        {data.footer.img && <article className='col-md-6 p-0 mx-0 flex ' >
                            <img src={data.footer.img ? data.footer.img : require('../../assesst/WEBapp/gebawebappfooter.webp')}
                                alt="FooterImage"
                                className={` ${data.footer.imgcss ? data.footer.imgcss : "w-full h-full object-cover"}  `} />
                        </article>}
                    </section>}
            </main>

        </div >
    )
}

export default OurFeatures