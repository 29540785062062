import React from 'react'

const WebsiteNavIcon = ({ size }) => {
    return (
        <svg width={size ? size : "30"} height={size ? size - 2 : "28"} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 27.5H30V11.25H0V27.5ZM20.6775 16.25L20.9888 20.1975L22.5 17.2887L24.0112 20.1975L24.32 16.25H25.625L25.1712 21.5263C25.1325 22.0763 24.75 22.5 24.2887 22.5C23.9837 22.5 23.7 22.3113 23.5375 22L22.4988 20L21.46 22C21.2987 22.3113 21.015 22.5 20.7087 22.5C20.2487 22.5 19.865 22.0763 19.8263 21.5263L19.3725 16.25H20.675H20.6775ZM13.1775 16.25L13.4888 20.1975L15 17.2887L16.5112 20.1975L16.82 16.25H18.125L17.6712 21.5263C17.6325 22.0763 17.25 22.5 16.7887 22.5C16.4837 22.5 16.2 22.3113 16.0375 22L14.9987 20L13.96 22C13.7987 22.3113 13.515 22.5 13.2088 22.5C12.7488 22.5 12.365 22.0763 12.3263 21.5263L11.8725 16.25H13.175H13.1775ZM5.6775 16.25L5.98875 20.1975L7.5 17.2887L9.01125 20.1975L9.32 16.25H10.625L10.1713 21.5263C10.1325 22.0763 9.75 22.5 9.28875 22.5C8.98375 22.5 8.7 22.3113 8.5375 22L7.49875 20L6.46 22C6.29875 22.3113 6.015 22.5 5.70875 22.5C5.24875 22.5 4.865 22.0763 4.82625 21.5263L4.3725 16.25H5.675H5.6775ZM26.25 0H3.75C1.6825 0 0 1.6825 0 3.75V8.75H30V3.75C30 1.6825 28.3175 0 26.25 0ZM4.375 6.25C3.34 6.25 2.5 5.41 2.5 4.375C2.5 3.34 3.34 2.5 4.375 2.5C5.41 2.5 6.25 3.34 6.25 4.375C6.25 5.41 5.41 6.25 4.375 6.25ZM9.375 6.25C8.34 6.25 7.5 5.41 7.5 4.375C7.5 3.34 8.34 2.5 9.375 2.5C10.41 2.5 11.25 3.34 11.25 4.375C11.25 5.41 10.41 6.25 9.375 6.25Z" fill="currentColor" />
        </svg>
    )
}

export default WebsiteNavIcon