import React from 'react'

const RightUpOpenCurve = ({ w, h }) => {
    return (
        <svg width={w ? w : "1097"} height={h ? h : "926"} viewBox="0 0 1097 926" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75" filter="url(#filter0_f_150_1116)">
                <path d="M1515 101C1515 487.763 1203.68 801 820 801C436.325 801 125 487.763 125 101C125 -285.763 436.325 -599 820 -599C1203.68 -599 1515 -285.763 1515 101Z" stroke="#AA96F1" stroke-width="50" />
            </g>
            <defs>
                <filter id="filter0_f_150_1116" x="0" y="-724" width="1640" height="1650" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_150_1116" />
                </filter>
            </defs>
        </svg>

    )
}

export default RightUpOpenCurve