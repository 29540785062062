import React from 'react'

const PosterLayout = ({ data }) => {
    return (
        <div>
            <main className='row container-fluid p-0 my-3 ' >
                <section className="col-lg-8 flex flex-wrap justify-between h-[80vh] ">
                    <div className='col-lg-6 h[40vh] p-2 ps-0 pt-0' >
                        <div className='h-full w-full bg-slate-200 rounded ' >
                            <img src={data && data.img1 ? data.img1 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[40vh] object-cover shadow'
                                loading='lazy' />
                        </div>
                    </div>
                    <div className='col-lg-6 h-[40vh] p-2 pe-0 pt-0' >
                        <div className='h-full w-full bg-slate-200 rounded' >
                            <img src={data && data.img2 ? data.img2 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[40vh] object-cover shadow'
                                loading='lazy' />
                        </div>
                    </div>
                    <div className='w-full h-[40vh] p-2 px-0 pb-0 ' >
                        <div className='h-full w-full bg-slate-200 rounded' >
                            <img src={data && data.img3 ? data.img3 : require('../../assesst/Image-not-found.png')}
                                alt="Image" className='rounded w-full h-[40vh] object-cover shadow'
                                loading='lazy' />
                        </div>
                    </div>
                </section>
                <section className="col-lg-4">
                    <div className='h-full w-full bg-slate-200 rounded ' >
                        <img src={data && data.img4 ? data.img4 : require('../../assesst/Image-not-found.png')}
                            alt="Image" className='rounded w-full h-[82vh] object-cover shadow'
                            loading='lazy' />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default PosterLayout