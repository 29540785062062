import React, { useContext, useEffect } from 'react'
import Banner from '../component/LogoCom/Banner'
import OurProcess from '../component/LogoCom/OurProcess'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyCards from '../component/LogoCom/CaseStudyCards';
import MakeYourBestLogo from '../component/LogoCom/MakeYourBestLogo';
import OurOtherWorks from '../component/LogoCom/OurOtherWorks';
import NavBarCom from '../component/NavBarCom';
import { logoData } from '../context/Data';
import { PorfolioStore } from '../context/PortFolioContext';
import MouseIcon from '../Icons/MouseIcon';
const LogoPage = () => {
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('logo')
        window.scrollTo(0,0)
    }, [])
    let logoDesign = [
        {
            img: '../assesst/MeridaLogopageDisccusionRoom.webp',
            css: 'w-[21rem] '
        },
        {
            img: '../assesst/imagecompressor5/learnhubcard5.png',
            css: 'w-[15rem] '
        },
        {
            img: '../assesst/imagecompressor5/AdithiSkillsLetterPad2-min.png',
            css: ' w-[20rem] h-[15rem] object-cover '
        },
        {
            img: '../assesst/MeridaLogopageDisccusionRoom.webp',
            css: 'w-[15rem] '
        },
        {
            img: '../assesst/imagecompressor5/learnhubcard5.png',
            css: 'w-[15rem] '
        },
        {
            img: '../assesst/CEOCardMeridaLogo.webp',
            css: ' w-[20rem] h-[18rem] object-cover  '
        },
        {
            img: '../assesst/MeridaLogopageDisccusionRoom.webp',
            css: 'w-[21rem] '
        },
        {
            img: '../assesst/imagecompressor5/learnhubcard5.png',
            css: 'w-[15rem] '
        },
        {
            img: '../assesst/meridaLogoPageFooter.webp',
            css: ' w-[20rem] '
        },
    ]
    return (
        <div className=' bg-white relative  ' >
            <div className=' sticky z-10 top-0 w-full ' >
                <NavBarCom />
            </div>

            {/*  */}
            {/* <Banner /> */}
            <div className='col-lg-10 xl:min-h-[88vh] p-4 pt-0 ms-auto ' >
                <img src={require('../assesst/logoPageBanner-min.png')} alt="Logo Banner"
                    className=' sm:w-[90%] mx-auto max-h-[76vh] object-cover object-top  ' />
                <a onClick={() => {
                    document.getElementById('ourprocesslogo').scrollIntoView({ behavior: 'smooth' })
                }} className='w-fit mx-auto cursor-pointer my-2 my-sm-4 flex ' >
                    <span className='d-sm-none ' >
                        <MouseIcon size={35} />
                    </span>
                    <span className='d-none d-sm-block ' >
                        <MouseIcon />
                    </span>
                </a>
            </div>
            <div id='ourprocesslogo' className=' bg-slate-100  ' >

                <div className=' col-lg-10 p-4 ms-auto' >
                    <OurProcess />
                </div>
            </div>

            {/* Case Study cards */} 
            <main className='container text-start   ' >
                {
                    logoData.map((obj, index) => (
                        <CaseStudyCards changeFun={() => {
                            document.getElementById(logoData[index + 1].slug).scrollIntoView({ behavior: 'smooth' })
                        }} data={obj} index={index} />
                    ))
                }
            </main>
            {/* <main className='container text-start   ' >
                {
                    logoData.map((obj, index) => (
                        <CaseStudyCards changeFun={() => {
                            document.getElementById(logoData[index + 1].slug).scrollIntoView({ behavior: 'smooth' })
                        }} data={obj} index={index} />
                    ))
                }
            </main> */}
            {/* <img src={require('../assesst/logoMultipleSymbol.webp')} alt="MultipleLogos"
            className=' my-4 ' /> */}
            {/* Other multiple logos */}
            <div className='bg-slate-100  ' >

                <main className='col-lg-10 p-4 overflow-hidden flex flex-wrap my-4 py-4 ms-auto ' >
                    <section className='col-md-4 p-3 flex flex-col justify-between ' >
                        <img src={require('../assesst/goldenBridgeLogo/GoldenBridge2.jpg')} alt="CenterManageMerida"
                            className='rounded-xl h-[30vh] flex-grow-1 object-cover my-2 w-full ' />
                        <div className=' flex w-fit items-center px-3 justify-between overflow-hidden ' >
                            <img src={require('../assesst/imagecompressor6/bts360vertical.png')} alt="Vertical" />
                            <img src={require('../assesst/imagecompressor6/bts360phone.png')} alt="Phonecase" />
                        </div>
                        <img src={require('../assesst/imagecompressor6/swathisweetscol1.png')} alt="Swathi Sweets"
                            className='rounded-xl h-[27vh] flex-grow-1 object-cover my-2 w-full ' />
                    </section>
                    <section className='col-md-4 p-3 flex flex-col justify-between ' >
                        <div className='flex flex-col justify-center ' >
                            <h4 style={{ color: '#2E75B6' }} className='sans text-center spacing text-7xl xl:text-9xl fw-bold  ' >
                                Logo
                            </h4>
                            <span style={{ color: '#2E75B6' }} className='sans text-center mx-auto fw-bold my-3 text-4xl xl:text-7xl ' >
                                Mockups
                            </span>
                        </div>
                        <img src={require('../assesst/imagecompressor5/AdithiSkillsLetterPad2-min.png')} alt="CenterManageMerida"
                            className='rounded-xl flex-grow-1 h-[27vh] object-cover my-2 w-full ' />
                        <img src={require('../assesst/MeridaLogopageDisccusionRoom.webp')} alt="CenterManageMerida"
                            className='rounded-xl flex-grow-1 h-[22vh] my-2 object-cover w-full ' />
                    </section>
                    <section className='col-md-4 p-3 flex flex-col justify-between ' >
                        <img src={require('../assesst/imagecompressor6/learnhubfooter.png')} alt="CenterManageMerida"
                            className='rounded-xl  h-[45vh] object-cover my-2 ' />
                        <img src={require('../assesst/imagecompressor6/swathisweetscol2.png')} alt="CenterManageMerida"
                            className='rounded-xl h-[20vh] object-cover flex-grow-1 my-2 ' />
                    </section>


                </main>
            </div>
            <MakeYourBestLogo />
            <OurOtherWorks />
        </div>
    )
}

export default LogoPage