import React, { useContext, useEffect, useState } from 'react'
import { Navbar } from 'react-bootstrap'
import NavBarCom from '../component/NavBarCom'
import WhyChooseSection from '../component/WebAppComponents/WhyChooseSection'
import ContentType1 from '../component/WebAppComponents/ContentType1'
import OurFeatures from '../component/WebAppComponents/OurFeatures'
import { useParams } from 'react-router'
import { webappData } from '../context/Data'
import { PorfolioStore } from '../context/PortFolioContext'

const GebaWebAppPage = () => {
    let { slug } = useParams()
    let [data, setData] = useState()
    let { setActivePage } = useContext(PorfolioStore)
    useEffect(() => {
        setActivePage('webapp')
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
        if (slug) {
            let foundobj = webappData.find((obj) => obj.slug == slug)
            setData(foundobj)
            console.log(foundobj);

        }
    }, [slug])
    return (
        <div>
            {/* Banner */}

            <main className={` ${data && data.bannerbg ? data.bannerbg : "gebabannerbg"} container-fluid ps-0`} >
                {data && data.navbar == 'dark' ? <NavBarCom theme='dark' /> : < NavBarCom logo='white' theme />}
                <article className={`  ${data && data.bannertheme && "col-lg-10 mx-auto mx-lg-0  ms-lg-auto row"} ${data && data.contentcss} `} >
                    <section className={` ${data && data.bannertheme == 'theme2' ? ' flex col-lg-6' : ''} my-auto  `} >

                        <div className='my-auto ' >

                            <h5 className={` text-slate-50 text-4xl w-4/5 mx-auto ${data && data.bheadcss ?
                                data.bheadcss : "sm:w-[45%]"} text-center poppins sm:text-7xl `}>
                                {data && data.bannerHeading ? data.bannerHeading : " Keeping of  Every Order"}
                            </h5>
                            <p className='text-center text-slate-400 text-lg my-3 ' >
                                {data && data.bannercontent ? data.bannercontent :
                                    " Order tracking made simple with clear, transparent, order tracking system"}
                            </p>
                        </div>
                    </section>
                    <section className={` ${data && data.bannertheme == 'theme2' ? 'col-lg-6' : ''} `} >
                        {data && data.bannerimg &&
                            <img src={data && data.bannerimg ? data.bannerimg : require('../assesst/WEBapp/blackcardwebapp.png')} alt="GebbaBannerCard"
                                className={` ${data && data.bannerimgcss ? data.bannerimgcss : 'w-full'} mt-3  `} />}
                    </section>
                </article>
            </main>
            {/* Why Choose */}
            <div className={` ${data && data.bodyclr} `} >
                <WhyChooseSection data={data?.whyData} />
                <section className='container ' >

                    <main className=' col-lg-10 mx-lg-0 mx-auto ms-lg-auto
                 row ' >

                        {/* Section3 */}
                        <section className='col-md-6 roboto min-h-[60vh] flex ' >
                            <article className='my-auto ' >
                                <ContentType1 bullet data={data?.benifitObj} />
                            </article>
                        </section>
                        <section className='col-md-6 flex ' >
                            <img src={data && data.benifitObj && data.benifitObj.img ? data.benifitObj.img :
                                require('../assesst/WEBapp/gebacableswebappimg1-min.png')} alt="ContentImage1"
                                className='w-full object-contain mx-auto ' />
                        </section>
                    </main>
                </section>
            </div>
            {/* How it works */}
            {data && data.howitworks && data.howitworks.show &&
                <div className=' relative my-3 overflow-hidden '>
                    <img src={data.howitworks.img ? data.howitworks.img : require('../assesst/WEBapp/gebawebappcontent2.webp')} alt="GebaImage"
                        className='w-full  h-[70vh]  sm:h-[85vh] object-left object-cover ' />
                    <div className='col-md-6 absolute 
                top-4 left-4 sm:top-20 sm:left-20 ' >
                        <ContentType1 clr='text-slate-200' data={data?.howitworks} />
                    </div>
                </div>}
            {/* Features */}
            <section className={` ${data && data.bodyclr} `} >
                <div className={` col-lg-10 ms-auto `}  >
                    <OurFeatures data={data?.features} />
                </div>
            </section>
        </div >
    )
}

export default GebaWebAppPage