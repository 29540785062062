import React from 'react'
import Ecpilise from './LogoCom/Ecpilise'
import RightArrow from '../Icons/RightArrow'

const ReadyToColbrate = () => {
    return (
        <div className='py-5 col-lg-10 mx-auto mx-sm-0 ms-sm-auto px-3' >
            <main style={{ backgroundColor: 'rgb(160,237,97)' }}
                className=' rounded-[50px] my-3 min-h-[80vh] flex relative ' >
                <div className='absolute d-none d-sm-block top-1/2 left-1/2 text-slate-500 -translate-y-1/2 -translate-x-1/2 ' >
                    <Ecpilise color />
                </div>
                <div className='absolute d-sm-none top-1/2 left-1/2 text-slate-500 -translate-y-1/2 -translate-x-1/2 ' >
                    <Ecpilise w={350} color />
                </div>
                <section className='m-auto text-center anton relative   ' >
                    <h4 className='text-4xl sm:text-9xl   ' >Ready to <br /> Collaborate? </h4>
                    <div className='flex flex-wrap gap-3 mx-auto w-fit my-5 ' >
                        <button onClick={() => {
                            window.open('https://www.meridatechminds.com/contact-us', '_blank')
                        }} className='rounded-full flex w-64
                         p-2 px-3 justify-between gap-3 text-slate-50 bg-slate-800 
                           inter  items-center' >
                            Let's Talk
                            <span style={{ backgroundColor: 'rgb(160,237,97)' }}
                                className=' text-slate-800 rounded-full p-2 ' >
                                <RightArrow />
                            </span>
                        </button>
                        {/* <button className='rounded-full flex w-64 
                         p-2 px-3 justify-between gap-3 text-slate-800 bg-slate-50 
                           inter  items-center' >
                            TALK@RAMS.COM
                            <span
                                className=' border-2 border-slate-700 text-slate-800 rounded-full p-2 ' >
                                <RightArrow />
                            </span>
                        </button> */}
                    </div>
                </section>
            </main>
        </div>
    )
}

export default ReadyToColbrate