import React from 'react'

const DownloadIcon = ({ size }) => {
    return (
        <svg width={size ? size : "33"} height={size ? size : "33"} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.668 9.83301H18.668C17.9346 9.83301 17.3346 10.433 17.3346 11.1663C17.3346 11.8997 17.9346 12.4997 18.668 12.4997H22.668C24.868 12.4997 26.668 14.2997 26.668 16.4997C26.668 18.6997 24.868 20.4997 22.668 20.4997H18.668C17.9346 20.4997 17.3346 21.0997 17.3346 21.833C17.3346 22.5663 17.9346 23.1663 18.668 23.1663H22.668C26.348 23.1663 29.3346 20.1797 29.3346 16.4997C29.3346 12.8197 26.348 9.83301 22.668 9.83301ZM10.668 16.4997C10.668 17.233 11.268 17.833 12.0013 17.833H20.0013C20.7346 17.833 21.3346 17.233 21.3346 16.4997C21.3346 15.7663 20.7346 15.1663 20.0013 15.1663H12.0013C11.268 15.1663 10.668 15.7663 10.668 16.4997ZM13.3346 20.4997H9.33464C7.13464 20.4997 5.33464 18.6997 5.33464 16.4997C5.33464 14.2997 7.13464 12.4997 9.33464 12.4997H13.3346C14.068 12.4997 14.668 11.8997 14.668 11.1663C14.668 10.433 14.068 9.83301 13.3346 9.83301H9.33464C5.65464 9.83301 2.66797 12.8197 2.66797 16.4997C2.66797 20.1797 5.65464 23.1663 9.33464 23.1663H13.3346C14.068 23.1663 14.668 22.5663 14.668 21.833C14.668 21.0997 14.068 20.4997 13.3346 20.4997Z"
                fill="currentColor" />
        </svg>

    )
}

export default DownloadIcon