import React from 'react'
import Slider from 'react-slick';

const MakeYourBestLogo = () => {
    var settings = {
        // dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        // rtl: true,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    vertical: false
                }
            }
        ]
    };
    return (
        <div className=' min-h-[60vh] col-lg-10 ms-auto ' >
            <main className=' container min-h-[80vh] row mx-auto h-full ' >
                <section className='col-lg-6 row h-full order-2 ' >
                    <div className='col-sm-6 p-0 max-h-[100vh] overflow-hidden  '  >

                        <Slider {...settings} className=' h-full'  >
                            {['../../assesst/Logo/agasalogo.png', '../../assesst/Logo/bloomindoorlogo.png', '../../assesst/Logo/hiralogo.png',
                                '../../assesst/Logo/kinggenerator.png', '../../assesst/Logo/manikant.png', '../../assesst/Logo/namocomputer.png'
                            ].reverse().map((obj, index) => (
                                <div className='rounded-2xl p-6 flex justify-center my-2 items-center bg-slate-100 py-14 ' >

                                    <img src={obj} alt="Logog" className='w-fit h-fit m-auto ' />
                                </div>
                            ))}

                        </Slider>
                    </div>
                    <div className='col-sm-6 p-0 max-h-[100vh] overflow-hidden  '  >

                        <Slider  {...{ ...settings, rtl: true, reverse: true }} className=' h-full'  >
                            {['../../assesst/Logo/agasalogo.png', '../../assesst/Logo/bloomindoorlogo.png', '../../assesst/Logo/hiralogo.png',
                                '../../assesst/Logo/kinggenerator.png', '../../assesst/Logo/manikant.png', '../../assesst/Logo/namocomputer.png'
                            ].map((obj, index) => (
                                <div className='rounded-2xl p-6 flex justify-center my-2 items-center bg-slate-100 py-14 ' >

                                    <img src={obj} alt="Logog" className='w-fit h-fit m-auto ' />
                                </div>
                            ))}

                        </Slider>
                    </div>
                </section>
                {/* Right side  */}
                <section className='col-lg-6 flex flex-col justify-center ' >
                    <article className='bluetxt poppins text-5xl sm:text-8xl text-left  ' >
                        <h6 className='text-5xl sm:text-7xl my-2 ' > Crafting Your </h6>
                        <div className='flex gap-2  items-center  my-2 ' >
                            {/* Icon */}
                            <div className='relative bluetxt fw-thin ' >
                                <h5 style={{ fontWeight: '100', fontSize: '68px' }} className=' italic pe-2 ' >  Unique </h5>
                                <h5 style={{ fontWeight: '100', fontSize: '68px' }} className=' italic pe-2 strktxt2 absolute fw-light top-[0px]  left-[2px] ' >  Unique </h5>

                            </div>
                            <h5 className=' text-5xl sm:text-7xl' > Logo </h5>
                        </div>

                    </article>
                    <p className='text-normal text-slate-800 text-xl my-5 ' >
                        Build a logo that reflects your brand's unique story <br /> and connects with your audience</p>
                </section>

            </main>


        </div >
    )
}

export default MakeYourBestLogo